export const EtherScanIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <path
        d="M4.1642 9.50615C4.16421 9.39431 4.18633 9.2836 4.22925 9.18032C4.27218 9.07707 4.33508 8.98331 4.41434 8.90442C4.49363 8.82554 4.58768 8.76308 4.69115 8.72065C4.79462 8.67822 4.90545 8.65665 5.01729 8.65717L6.4317 8.66178C6.6572 8.66178 6.87347 8.75138 7.03293 8.91083C7.1924 9.07028 7.28199 9.28656 7.28199 9.51208V14.8602C7.44121 14.8129 7.64572 14.7625 7.86949 14.7098C8.02493 14.6733 8.16345 14.5854 8.26261 14.4602C8.36175 14.3351 8.41569 14.1801 8.41566 14.0204V7.38635C8.41566 7.16081 8.50523 6.94452 8.66469 6.78503C8.82414 6.62554 9.04042 6.53594 9.26595 6.53589H10.6832C10.9087 6.53594 11.125 6.62554 11.2844 6.78503C11.4439 6.94452 11.5335 7.16081 11.5335 7.38635V13.5434C11.5335 13.5434 11.8883 13.3998 12.2339 13.2539C12.3623 13.1996 12.4719 13.1087 12.5489 12.9926C12.626 12.8764 12.6672 12.7401 12.6673 12.6007V5.26061C12.6673 5.03513 12.7568 4.81887 12.9163 4.65942C13.0757 4.49996 13.2919 4.41037 13.5174 4.41032H14.9346C15.1601 4.41032 15.3764 4.49989 15.5359 4.65937C15.6953 4.81882 15.7849 5.0351 15.7849 5.26061V11.305C17.0136 10.4146 18.2587 9.34363 19.2468 8.05584C19.3903 7.86892 19.4851 7.64942 19.523 7.41694C19.5609 7.18446 19.5406 6.9462 19.464 6.72343C19.0066 5.40746 18.2796 4.20144 17.3294 3.18257C16.3792 2.16371 15.2267 1.35443 13.9458 0.806525C12.6648 0.258623 11.2836 -0.0158386 9.89052 0.000705853C8.49741 0.0172502 7.12311 0.324437 5.85556 0.902605C4.58799 1.48077 3.45508 2.31719 2.52932 3.35834C1.60357 4.39947 0.905358 5.62242 0.47937 6.9489C0.0533833 8.27537 -0.0909979 9.67616 0.0554652 11.0616C0.201928 12.4471 0.636012 13.7868 1.32993 14.9949C1.45082 15.2032 1.62866 15.3728 1.84257 15.4837C2.05648 15.5945 2.29757 15.642 2.53754 15.6206C2.8056 15.597 3.13936 15.5636 3.53619 15.517C3.70893 15.4974 3.86842 15.4149 3.98426 15.2853C4.10011 15.1556 4.16421 14.9879 4.16436 14.8141L4.1642 9.50615Z"
        fill="white"
      />
      <path
        d="M4.13318 18.0839C5.627 19.1707 7.39238 19.8231 9.23396 19.9688C11.0756 20.1143 12.9216 19.7478 14.5677 18.9094C16.2139 18.0709 17.5959 16.7935 18.5611 15.2184C19.5262 13.6432 20.0368 11.8317 20.0363 9.98438C20.0363 9.75386 20.0256 9.52581 20.0103 9.29907C16.3518 14.7555 9.59669 17.3064 4.13349 18.0831"
        fill="white"
      />
    </svg>
  );
};
