export const FailedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 11.8375L12.9 14.7375C13.0833 14.9208 13.3167 15.0125 13.6 15.0125C13.8833 15.0125 14.1167 14.9208 14.3 14.7375C14.4833 14.5542 14.575 14.3208 14.575 14.0375C14.575 13.7542 14.4833 13.5208 14.3 13.3375L11.4 10.4375L14.3 7.5375C14.4833 7.35417 14.575 7.12083 14.575 6.8375C14.575 6.55417 14.4833 6.32083 14.3 6.1375C14.1167 5.95417 13.8833 5.8625 13.6 5.8625C13.3167 5.8625 13.0833 5.95417 12.9 6.1375L10 9.0375L7.1 6.1375C6.91667 5.95417 6.68333 5.8625 6.4 5.8625C6.11667 5.8625 5.88333 5.95417 5.7 6.1375C5.51667 6.32083 5.425 6.55417 5.425 6.8375C5.425 7.12083 5.51667 7.35417 5.7 7.5375L8.6 10.4375L5.7 13.3375C5.51667 13.5208 5.425 13.7542 5.425 14.0375C5.425 14.3208 5.51667 14.5542 5.7 14.7375C5.88333 14.9208 6.11667 15.0125 6.4 15.0125C6.68333 15.0125 6.91667 14.9208 7.1 14.7375L10 11.8375ZM10 20.4375C8.61667 20.4375 7.31667 20.1748 6.1 19.6495C4.88333 19.1242 3.825 18.4118 2.925 17.5125C2.025 16.6125 1.31267 15.5542 0.788 14.3375C0.263333 13.1208 0.000666667 11.8208 0 10.4375C0 9.05417 0.262667 7.75417 0.788 6.5375C1.31333 5.32083 2.02567 4.2625 2.925 3.3625C3.825 2.4625 4.88333 1.75017 6.1 1.2255C7.31667 0.700833 8.61667 0.438167 10 0.4375C11.3833 0.4375 12.6833 0.700167 13.9 1.2255C15.1167 1.75083 16.175 2.46317 17.075 3.3625C17.975 4.2625 18.6877 5.32083 19.213 6.5375C19.7383 7.75417 20.0007 9.05417 20 10.4375C20 11.8208 19.7373 13.1208 19.212 14.3375C18.6867 15.5542 17.9743 16.6125 17.075 17.5125C16.175 18.4125 15.1167 19.1252 13.9 19.6505C12.6833 20.1758 11.3833 20.4382 10 20.4375Z"
        fill={`url(#${props.id}_failed_paint0)`}
      />
      <defs>
        <linearGradient
          id={`${props.id}_failed_paint0`}
          x1="18.6434"
          y1="0.437501"
          x2="1.05176"
          y2="20.4761"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C2B3FF" />
          <stop offset="1" stopColor="#E44C4C" />
        </linearGradient>
      </defs>
    </svg>
  );
};
