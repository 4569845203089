import { CrossIcon } from "@aperture/assetkit";
import React, { MouseEvent, forwardRef } from "react";
import styled from "styled-components";
import { compose, layout, space } from "styled-system";
import { ModalProps } from "./types";

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.transparent.black};
  z-index: ${({ theme }) => theme.zIndices.backdrop};
  overflow-y: auto;
  scrollbar-width: none;
`;

const Container = styled.div<{ padding: number }>`
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.grey.white};
  background: ${({ theme }) => theme.colors.global.background.BG2};
  border-radius: 15px;
  border: ${({ theme }) => `2px solid ${theme.colors.global.background.BG2}`};
  margin-top: 16vh;
  margin-bottom: 16vh;
  padding: ${({ padding }) => `${padding}px`};
  position: relative;
  height: fit-content;
  width: fit-content;
  ${compose(layout, space)}
`;

const Icon = styled(CrossIcon)<{ position: number }>`
  cursor: pointer;
  position: absolute;
  top: ${({ position }) => `${position + 5}px`};
  right: ${({ position }) => `${position + 5}px`};
  z-index: 1;
  fill: ${({ theme }) => theme.colors.global.text.T1};
`;

export const Modal = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ModalProps>
>(
  (
    {
      disableBackgroundOnClose = false,
      closeIcon = true,
      padding = 32,
      onClose,
      children,
      ...props
    },
    ref
  ) => {
    const handleBackgroundClick = (e: MouseEvent<HTMLDivElement>) => {
      if (e.target === e.currentTarget && !disableBackgroundOnClose) {
        onClose();
      }
    };

    return (
      <StyledModal ref={ref} onClick={handleBackgroundClick}>
        <Container padding={padding} {...props}>
          {closeIcon && (
            <Icon position={padding} width={14} height={14} onClick={onClose} />
          )}
          {children}
        </Container>
      </StyledModal>
    );
  }
);
