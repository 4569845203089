import React, { ReactNode, createContext, useContext, useReducer } from "react";

export enum E_ModalKey {
  IMPERMANENT_LOSS_MODAL = "IMPERMANENT_LOSS_MODAL",
  SWITCH_NETWORK_MODAL = "SWITCH_NETWORK_MODAL",
  CHANGE_POOL_MODAL = "CHANGE_POOL_MODAL",
  STAKE_POSITION_MODAL = "STAKE_POSITION_MODAL",
  TOKEN_SELECT_MODAL = "TOKEN_SELECT_MODAL",
  TOKEN_WARNING_MODAL = "TOKEN_WARNING_MODAL",
}

interface ModalState {
  isOpen: boolean;
}

type ModalContextState = {
  [K in E_ModalKey]: ModalState;
};

type ModalContextAction<K extends E_ModalKey = E_ModalKey> = {
  type: "SET_MODAL_STATE";
  payload: { key: K; isOpen: boolean };
};

const initialState: ModalContextState = {
  [E_ModalKey.IMPERMANENT_LOSS_MODAL]: { isOpen: false },
  [E_ModalKey.SWITCH_NETWORK_MODAL]: { isOpen: false },
  [E_ModalKey.CHANGE_POOL_MODAL]: { isOpen: false },
  [E_ModalKey.STAKE_POSITION_MODAL]: { isOpen: false },
  [E_ModalKey.TOKEN_SELECT_MODAL]: { isOpen: false },
  [E_ModalKey.TOKEN_WARNING_MODAL]: { isOpen: false },
};

const ModalContext = createContext<
  | {
      state: ModalContextState;
      dispatch: React.Dispatch<ModalContextAction>;
    }
  | undefined
>(undefined);

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }

  const { state, dispatch } = context;

  const setModalState = <K extends E_ModalKey>(key: K, isOpen: boolean) => {
    dispatch({
      type: "SET_MODAL_STATE",
      payload: { key, isOpen },
    });
  };

  return { state, setModalState };
};

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalV2Provider: React.FC<ModalProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      {children}
    </ModalContext.Provider>
  );
};
const reducer = <K extends E_ModalKey>(
  state: ModalContextState,
  action: ModalContextAction<K>
): ModalContextState => {
  switch (action.type) {
    case "SET_MODAL_STATE":
      const { key, isOpen } = action.payload;
      return {
        ...state,
        [key]: { isOpen },
      };
    default:
      return state;
  }
};
