import { BasicInfo, SupportedChainId, getChainInfo } from "@ui/utils";
import { FC, HTMLAttributes } from "react";
import { ButtonTextBox, Container, Zondicons } from "./style";

export interface IChainAMMLabelProps extends HTMLAttributes<HTMLDivElement> {
  chainId: SupportedChainId;
  amm: BasicInfo;
}

export const ChainAMMLabel: FC<
  React.PropsWithChildren<IChainAMMLabelProps>
> = ({ chainId, amm, ...props }) => {
  const { Icon, name } = getChainInfo(chainId);

  return (
    <Container {...props}>
      <Icon width={20} height={20} />
      <ButtonTextBox>{name}</ButtonTextBox>
      <Zondicons width={20} height={20} />
      <amm.Icon width={20} height={20} />
      <ButtonTextBox>{amm.name}</ButtonTextBox>
    </Container>
  );
};
