import { IChain, SupportedChains } from "@ui/components";
import { TEvmChainMap } from "./chainConfig";

const defaultProjectId = "84842078b09946638c03157f83405213";

export const EvmChainMap: TEvmChainMap = {
  1: {
    name: "Ethereum Mainnet",
    chainId: 1,
    shortName: "eth",
    networkId: 1,
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpc: [
      "https://ethereum-rpc.publicnode.com",
      "https://cloudflare-eth.com",
      `https://mainnet.infura.io/v3/${defaultProjectId}`,
    ],
    faucets: [],
    infoURL: "https://etherscan.io/${TYPE}/${ADDRESS}",
    exploreURL: "https://etherscan.io",
    network: "mainnet",
    multicallAddresses: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcPublicNode: "https://ethereum-rpc.publicnode.com",
    analyticsEndpoint:
      "https://api.goldsky.com/api/public/project_clnz7akg41cv72ntv0uhyd3ai/subgraphs/aperture-analytics-ethereum-uniswap/0.3.1/gn",
  },
  42161: {
    name: "Arbitrum",
    chainId: 42161,
    shortName: "arb",
    networkId: 42161,
    nativeCurrency: {
      name: "Arbitrum Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpc: [
      "https://arb1.arbitrum.io/rpc",
      `https://arbitrum-mainnet.infura.io/v3/${defaultProjectId}`,
      "https://arbitrum-one-rpc.publicnode.com",
    ],
    faucets: [],
    infoURL: "https://arbiscan.io/${TYPE}/${ADDRESS}",
    exploreURL: "https://arbiscan.io",
    network: "arbitrum",
    multicallAddresses: "0x842eC2c7D803033Edf55E478F461FC547Bc54EB2",
    rpcPublicNode: "https://arbitrum-one-rpc.publicnode.com",
  },
  169: {
    name: "Manta Pacific Mainnet",
    chainId: 169,
    shortName: "manta",
    networkId: 169,
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpc: ["https://manta-pacific-aperture.calderachain.xyz/http"],
    faucets: [],
    infoURL: "https://pacific-explorer.manta.network/${TYPE}/${ADDRESS}",
    exploreURL: "https://pacific-explorer.manta.network",
    network: "manta-mainnet",
    multicallAddresses: "0xF0a625CCF0C8eb40261E3487DF18B6F8933fCfE1",
  },
  10: {
    name: "OP Mainnet",
    chainId: 10,
    shortName: "op mainnet",
    networkId: 10,
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpc: [
      "https://mainnet.optimism.io",
      `https://optimism-mainnet.infura.io/v3/${defaultProjectId}`,
      "https://optimism-rpc.publicnode.com",
    ],
    faucets: [],
    infoURL: "https://optimistic.etherscan.io/${TYPE}/${ADDRESS}",
    exploreURL: "https://optimistic.etherscan.io",
    network: "optimism",
    multicallAddresses: "",
    rpcPublicNode: "https://optimism-rpc.publicnode.com",
  },
  137: {
    name: "Polygon",
    chainId: 137,
    shortName: "polygon",
    networkId: 137,
    nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
    rpc: [
      "https://polygon-bor-rpc.publicnode.com",
      `https://polygon-mainnet.infura.io/v3/${defaultProjectId}`,
      "https://polygon-rpc.com",
    ],
    faucets: [],
    infoURL: "https://polygonscan.com/${TYPE}/${ADDRESS}",
    exploreURL: "https://polygonscan.com",
    network: "matic",
    multicallAddresses: "",
  },
  8453: {
    name: "Base",
    chainId: 8453,
    shortName: "base",
    networkId: 8453,
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpc: ["https://mainnet.base.org", "https://base-rpc.publicnode.com"],
    faucets: [],
    infoURL: "https://basescan.org/${TYPE}/${ADDRESS}",
    exploreURL: "https://basescan.org",
    network: "base",
    multicallAddresses: "",
    rpcPublicNode: "https://base-rpc.publicnode.com",
  },
  56: {
    name: "BNB Smart Chain",
    chainId: 56,
    shortName: "bnb",
    networkId: 56,
    nativeCurrency: {
      decimals: 18,
      name: "BNB",
      symbol: "BNB",
    },
    rpc: [
      "https://bsc-dataseed.bnbchain.org",
      "https://bsc-rpc.publicnode.com",
    ],
    faucets: [],
    infoURL: "https://bscscan.com/${TYPE}/${ADDRESS}",
    exploreURL: "https://bscscan.com",
    network: "bsc",
    multicallAddresses: "",
    rpcPublicNode: "https://bsc-rpc.publicnode.com",
  },
  43114: {
    name: "Avalanche",
    chainId: 43114,
    shortName: "avalanche",
    networkId: 43114,
    nativeCurrency: {
      decimals: 18,
      name: "Avalanche",
      symbol: "AVAX",
    },
    rpc: [
      "https://api.avax.network/ext/bc/C/rpc",
      `https://avalanche-mainnet.infura.io/v3/${defaultProjectId}`,
      "https://avalanche-c-chain-rpc.publicnode.com",
    ],
    faucets: [],
    infoURL: "https://snowtrace.io/${TYPE}/${ADDRESS}",
    exploreURL: "https://snowtrace.io",
    network: "avalanche",
    multicallAddresses: "",
    rpcPublicNode: "https://avalanche-c-chain-rpc.publicnode.com",
  },
  534352: {
    name: "Scroll",
    chainId: 534352,
    shortName: "Scroll",
    networkId: 534352,
    nativeCurrency: {
      decimals: 18,
      name: "Ether",
      symbol: "ETH",
    },
    rpc: [
      "https://rpc.scroll.io",
      "https://rpc.ankr.com/scroll",
      "https://scroll-mainnet.chainstacklabs.com",
      "https://1rpc.io/scroll",
    ],
    faucets: [],
    infoURL: "https://scrollscan.com/${TYPE}/${ADDRESS}",
    exploreURL: "https://scrollscan.com",
    network: "scroll",
    multicallAddresses: "",
  },
};

export const AllChainsList: IChain[] = ["ALL_CHAINS", ...SupportedChains];
