import { EvmChainMap } from "@/config/chain";
import { mantaMainnet } from "@/config/chain/mantaPacific";
import { RpcSettingsType, useRpcSettings } from "@/config/feature_flags";
import { getWagmiConnectorV2 } from "@binance/w3w-wagmi-connector-v2";
import { useMemo } from "react";
import { FallbackTransport } from "viem";
import {
  arbitrum,
  avalanche,
  base,
  bsc,
  mainnet,
  optimism,
  polygon,
  scroll,
} from "viem/chains";
import {
  createConfig,
  createStorage,
  fallback,
  http,
  noopStorage,
} from "wagmi";
import { coinbaseWallet, walletConnect } from "wagmi/connectors";
import { injected } from "./connectors/injected";

const binanceConnector = getWagmiConnectorV2();

function getChainTransports(
  rpcSetting: RpcSettingsType
): Record<number, FallbackTransport> {
  const supportedNetworkId = [
    mainnet.id,
    arbitrum.id,
    mantaMainnet.id,
    optimism.id,
    polygon.id,
    base.id,
    bsc.id,
    avalanche.id,
    scroll.id,
  ];

  return supportedNetworkId.reduce((accumulator, networkId) => {
    if (!accumulator[networkId]) {
      // override rpc setting by feature flag
      const rpcs =
        rpcSetting?.[networkId]?.length > 0
          ? rpcSetting?.[networkId]
          : EvmChainMap[networkId].rpc;
      accumulator[networkId] = fallback(rpcs.map((url) => http(url)));
    }

    return accumulator;
  }, {});
}

export const useWagmiConfig = () => {
  const rpcs = useRpcSettings();

  return useMemo(() => {
    return createConfig({
      // @ts-ignore
      chains: [
        mainnet,
        arbitrum,
        mantaMainnet,
        optimism,
        polygon,
        base,
        bsc,
        avalanche,
        scroll,
      ],
      ssr: true,
      storage: createStorage({
        storage:
          typeof window !== "undefined" ? window.localStorage : noopStorage,
        key: "wagmi_v2.1",
      }),
      connectors: [
        walletConnect({ projectId: "e542ff314e26ff34de2d4fba98db70bb" }),
        coinbaseWallet({ appName: "aperture" }),
        injected({ target: "kuCoinWallet" }),
        binanceConnector(),
      ],
      transports: getChainTransports(rpcs),
      batch: {
        multicall: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
