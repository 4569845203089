import _ from "lodash";
import React, {
  ComponentType,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { Modal } from "./Modal";
import { ModalProps } from "./types";

type ModalComponentProps = Record<string, unknown> &
  Omit<ModalProps, "onClose"> & {
    onHideModal?: () => void;
  };
interface ModalComponent {
  Component: ComponentType<any>;
  props: ModalComponentProps;
}

interface ModalContextType {
  showModal: (
    Component: ComponentType<any>,
    props?: ModalComponentProps
  ) => void;
  hideModal: () => void;
  modalRef: React.RefObject<HTMLDivElement>;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modalComponent, setModalComponent] = useState<ModalComponent | null>(
    null
  );
  const modalRef = useRef<HTMLDivElement>(null);

  const showModal = useCallback(
    (Component: ComponentType<any>, props: ModalComponentProps = {}) => {
      setModalComponent({ Component, props });
    },
    []
  );

  const hideModal = useCallback(() => {
    if (modalComponent?.props.onHideModal) {
      modalComponent.props.onHideModal();
    }
    setModalComponent(null);
  }, [modalComponent]);

  const modalProps = _.pick(modalComponent?.props, [
    "disableBackgroundOnClose",
    "closeIcon",
    "padding",
  ]);

  return (
    <ModalContext.Provider value={{ showModal, hideModal, modalRef }}>
      {children}
      {modalComponent && (
        <Modal ref={modalRef} onClose={hideModal} {...modalProps}>
          <modalComponent.Component
            {...modalComponent.props}
            onClose={hideModal}
          />
        </Modal>
      )}
    </ModalContext.Provider>
  );
};
