export const ExchangeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={props.fill || "white"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16683 10C4.62707 10 5.00016 10.3731 5.00016 10.8333V12.5C5.00016 13.4205 5.74635 14.1667 6.66683 14.1667H15.0002C15.4604 14.1667 15.8335 14.5398 15.8335 15C15.8335 15.4602 15.4604 15.8333 15.0002 15.8333H6.66683C4.82588 15.8333 3.3335 14.3409 3.3335 12.5V10.8333C3.3335 10.3731 3.70659 10 4.16683 10Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5891 14.4108C15.9145 14.7363 15.9145 15.2639 15.5891 15.5893L13.0891 18.0893C12.7637 18.4148 12.236 18.4148 11.9106 18.0893C11.5851 17.7639 11.5851 17.2363 11.9106 16.9108L13.8213 15.0001L11.9106 13.0893C11.5851 12.7639 11.5851 12.2363 11.9106 11.9108C12.236 11.5854 12.7637 11.5854 13.0891 11.9108L15.5891 14.4108Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1665 5.00008C4.1665 4.53984 4.5396 4.16675 4.99984 4.16675H13.3332C15.1741 4.16675 16.6665 5.65913 16.6665 7.50008V9.16675C16.6665 9.62699 16.2934 10.0001 15.8332 10.0001C15.3729 10.0001 14.9998 9.62699 14.9998 9.16675V7.50008C14.9998 6.57961 14.2536 5.83341 13.3332 5.83341H4.99984C4.5396 5.83341 4.1665 5.46032 4.1665 5.00008Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.41058 5.58934C4.08514 5.2639 4.08514 4.73626 4.41058 4.41083L6.91058 1.91083C7.23602 1.58539 7.76366 1.58539 8.08909 1.91083C8.41453 2.23626 8.41453 2.7639 8.08909 3.08934L6.17835 5.00008L8.08909 6.91083C8.41453 7.23626 8.41453 7.7639 8.08909 8.08934C7.76366 8.41477 7.23602 8.41477 6.91058 8.08934L4.41058 5.58934Z"
      />
    </svg>
  );
};
