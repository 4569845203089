export const ExchangeIcon2: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill || "white"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.68 5.67967L12.0793 8.28039L10.9204 7.12152L14.9204 3.12152L15.4998 2.54208L16.0793 3.12152L20.0793 7.12152L18.9204 8.28039L16.3189 5.67886V13.3009H14.68V5.67967ZM7.68031 18.323L5.07879 15.7215L3.91992 16.8804L7.91992 20.8804L8.49936 21.4598L9.07879 20.8804L13.0788 16.8804L11.9199 15.7215L9.31919 18.3222V10.701H7.68031V18.323Z"
      />
    </svg>
  );
};
