import { Flex, FlexColumn } from "@uiv2/common";
import { T3Regular } from "@uiv2/components/Typography";
import React from "react";
import styled from "styled-components";
import ActiveDaysInput from "./ActiveDaysInput";
import PriceInput from "./PriceInput";

const InputBox = styled(FlexColumn)`
  min-width: 146px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.xl};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
`;
const BoxTitle = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const StyledT3 = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

type IData = Record<
  "Current" | "Future",
  Record<"tokenA" | "tokenB", [string, string]>
>;

export interface IForm {
  activeDays: string;
  tokenACurrentPrice: string;
  tokenBCurrentPrice: string;
  tokenAFuturePrice: string;
  tokenBFuturePrice: string;
}

interface PureImpermanentLossModalProps {
  form: IForm;
  tokenA: { ticker: string };
  tokenB: { ticker: string };
  daysRequired: number;
  handleInputChange: (key: keyof IForm, value: string) => void;
}

export const PureImpermanentLossForm: React.FC<
  PureImpermanentLossModalProps
> = ({ form, tokenA, tokenB, daysRequired, handleInputChange }) => {
  const data: IData = {
    Current: {
      tokenA: [tokenA.ticker, form.tokenACurrentPrice],
      tokenB: [tokenB.ticker, form.tokenBCurrentPrice],
    },
    Future: {
      tokenA: [tokenA.ticker, form.tokenAFuturePrice],
      tokenB: [tokenB.ticker, form.tokenBFuturePrice],
    },
  };

  return (
    <FlexColumn gap="lg">
      <Flex gap="lg">
        {Object.entries(data).map(([title, item]) => (
          <InputBox gap="lg" key={title}>
            <BoxTitle>{title} Price</BoxTitle>
            {Object.values(item).map(([ticker, price], idx) => (
              <PriceInput
                key={`${title}-${ticker}-price`}
                label={ticker}
                value={price}
                onChange={(value) =>
                  handleInputChange(
                    `token${idx < 1 ? "A" : "B"}${
                      title as "Current" | "Future"
                    }Price`,
                    value
                  )
                }
              />
            ))}
          </InputBox>
        ))}
      </Flex>
      <InputBox gap="md">
        <StyledT3>Days of Active Position</StyledT3>
        <ActiveDaysInput
          value={form.activeDays}
          onChange={(value) => handleInputChange("activeDays", value)}
        />
        <StyledT3>{`Your position needs to be active > ${daysRequired.toFixed(
          2
        )}d to cover IL`}</StyledT3>
      </InputBox>
    </FlexColumn>
  );
};
