export const LightningIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_lightning_clip0)`}>
        <path
          d="M15.6799 6.08031H11.8399V0.320312L5.43994 9.92031H9.27994V15.6803L15.6799 6.08031ZM7.83111 8.64025L10.5598 4.54777V7.36038H13.2879L10.5598 11.4527V8.64025H7.83111Z"
          fill="white"
        />
        <path
          d="M0.319824 8.64062H4.15982V9.92069H0.319824V8.64062Z"
          fill="white"
        />
        <path
          d="M1.6001 6.08008H6.08003V7.36014H1.6001V6.08008Z"
          fill="white"
        />
        <path
          d="M4.16016 3.51953H7.36022V4.7996H4.16016V3.51953Z"
          fill="white"
        />
        <path
          d="M1.6001 11.1992H8.00003V12.4791H1.6001V11.1992Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={`${props.id}_lightning_clip0`}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
