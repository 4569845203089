export const CrossCircleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="-1 -1 21 21"
      fill={props.fill || "white"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.5 0C4.24932 0 0 4.24891 0 9.5C0 14.7507 4.24891 19 9.5 19C14.7507 19 19 14.7511 19 9.5C19 4.24928 14.7511 0 9.5 0ZM9.5 17.5156C5.06973 17.5156 1.48438 13.9306 1.48438 9.5C1.48438 5.0697 5.0694 1.48438 9.5 1.48438C13.9303 1.48438 17.5156 5.0694 17.5156 9.5C17.5156 13.9303 13.9306 17.5156 9.5 17.5156Z" />
      <path d="M13.0618 11.9115L10.6502 9.49996L13.0618 7.08838C13.3794 6.77078 13.3794 6.25583 13.0618 5.93818C12.7441 5.62054 12.2292 5.62058 11.9116 5.93818L9.50001 8.34976L7.08841 5.93818C6.77085 5.62054 6.25582 5.62054 5.93822 5.93818C5.62062 6.25583 5.62062 6.77078 5.93826 7.08838L8.34982 9.49996L5.93826 11.9115C5.62062 12.2292 5.62058 12.7441 5.93822 13.0617C6.25594 13.3794 6.77089 13.3793 7.08841 13.0617L9.50001 10.6502L11.9116 13.0617C12.2291 13.3793 12.7442 13.3794 13.0618 13.0617C13.3794 12.7441 13.3794 12.2291 13.0618 11.9115Z" />
    </svg>
  );
};
