import {
  AmmInfo,
  BasicInfo,
  E_AMM,
  SupportedChainId,
  getChainInfo,
} from "@aperture/uikit";
import { ApertureSupportedChainId } from "@aperture_finance/uniswap-v3-automation-sdk";
import { Address } from "viem";

type SupportedNetwork = Record<SupportedChainId, ApertureSupportedChainId>;

export const supportedNetwork: SupportedNetwork = {
  [SupportedChainId.ETHEREUM]:
    ApertureSupportedChainId.ETHEREUM_MAINNET_CHAIN_ID,
  [SupportedChainId.ARBITRUM]:
    ApertureSupportedChainId.ARBITRUM_MAINNET_CHAIN_ID,
  [SupportedChainId.MANTA]:
    ApertureSupportedChainId.MANTA_PACIFIC_MAINNET_CHAIN_ID,
  [SupportedChainId.OPTIMISM]:
    ApertureSupportedChainId.OPTIMISM_MAINNET_CHAIN_ID,
  [SupportedChainId.POLYGON]: ApertureSupportedChainId.POLYGON_MAINNET_CHAIN_ID,
  [SupportedChainId.BASE]: ApertureSupportedChainId.BASE_MAINNET_CHAIN_ID,
  [SupportedChainId.BNB]: ApertureSupportedChainId.BNB_MAINNET_CHAIN_ID,
  [SupportedChainId.AVALANCHE]:
    ApertureSupportedChainId.AVALANCHE_MAINNET_CHAIN_ID,
  [SupportedChainId.SCROLL]: ApertureSupportedChainId.SCROLL_MAINNET_CHAIN_ID,
};

export const DEFAULT_AMM: BasicInfo = AmmInfo.Uniswap;
export const DEFAULT_NETWORK: SupportedChainId = SupportedChainId.ETHEREUM;

export const getNetworkId = (
  chain: SupportedChainId
): ApertureSupportedChainId => {
  return supportedNetwork[chain];
};

export const getNetworkName = (chainId: ApertureSupportedChainId) => {
  switch (chainId) {
    case ApertureSupportedChainId.ETHEREUM_MAINNET_CHAIN_ID:
      return SupportedChainId.ETHEREUM;
    case ApertureSupportedChainId.ARBITRUM_MAINNET_CHAIN_ID:
      return SupportedChainId.ARBITRUM;
    case ApertureSupportedChainId.MANTA_PACIFIC_MAINNET_CHAIN_ID:
      return SupportedChainId.MANTA;
    case ApertureSupportedChainId.OPTIMISM_MAINNET_CHAIN_ID:
      return SupportedChainId.OPTIMISM;
    case ApertureSupportedChainId.POLYGON_MAINNET_CHAIN_ID:
      return SupportedChainId.POLYGON;
    case ApertureSupportedChainId.BASE_MAINNET_CHAIN_ID:
      return SupportedChainId.BASE;
    case ApertureSupportedChainId.AVALANCHE_MAINNET_CHAIN_ID:
      return SupportedChainId.AVALANCHE;
    case ApertureSupportedChainId.BNB_MAINNET_CHAIN_ID:
      return SupportedChainId.BNB;
    case ApertureSupportedChainId.SCROLL_MAINNET_CHAIN_ID:
      return SupportedChainId.SCROLL;
    default:
      return DEFAULT_NETWORK;
  }
};

export const isManta = (chain: SupportedChainId | ApertureSupportedChainId) =>
  chain === SupportedChainId.MANTA ||
  chain === ApertureSupportedChainId.MANTA_PACIFIC_MAINNET_CHAIN_ID;

export const isScroll = (chain: SupportedChainId | ApertureSupportedChainId) =>
  chain === SupportedChainId.SCROLL ||
  chain === ApertureSupportedChainId.SCROLL_MAINNET_CHAIN_ID;

export const CHAIN_USDC_ADDRESS: Record<
  number,
  { id: Address; decimals: number }
> = {
  [ApertureSupportedChainId.ETHEREUM_MAINNET_CHAIN_ID]: {
    id: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    decimals: 6,
  },
  [ApertureSupportedChainId.OPTIMISM_MAINNET_CHAIN_ID]: {
    id: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
    decimals: 6,
  },
  [ApertureSupportedChainId.BNB_MAINNET_CHAIN_ID]: {
    id: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    decimals: 18,
  },

  [ApertureSupportedChainId.POLYGON_MAINNET_CHAIN_ID]: {
    id: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    decimals: 6,
  },

  [ApertureSupportedChainId.MANTA_PACIFIC_MAINNET_CHAIN_ID]: {
    id: "0xb73603C5d87fA094B7314C74ACE2e64D165016fb",
    decimals: 6,
  },

  [ApertureSupportedChainId.BASE_MAINNET_CHAIN_ID]: {
    id: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    decimals: 6,
  },

  [ApertureSupportedChainId.ARBITRUM_MAINNET_CHAIN_ID]: {
    id: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    decimals: 6,
  },

  [ApertureSupportedChainId.AVALANCHE_MAINNET_CHAIN_ID]: {
    id: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    decimals: 6,
  },

  [ApertureSupportedChainId.SCROLL_MAINNET_CHAIN_ID]: {
    id: "0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4",
    decimals: 6,
  },
};

export const isL2NetworkType = (chainId: ApertureSupportedChainId) => {
  switch (chainId) {
    case ApertureSupportedChainId.OPTIMISM_MAINNET_CHAIN_ID:
    case ApertureSupportedChainId.BASE_MAINNET_CHAIN_ID:
    case ApertureSupportedChainId.MANTA_PACIFIC_MAINNET_CHAIN_ID:
    case ApertureSupportedChainId.SCROLL_MAINNET_CHAIN_ID:
      return true;
    default:
      return false;
  }
};

export const getValidChains = () =>
  Object.entries(supportedNetwork).map(([_, chainInfo]) => chainInfo);

export const isChainIdValid = (chainId: string | number) => {
  if (!chainId) {
    return false;
  }
  return Object.values(supportedNetwork).some(
    (network) => network.toString() === `${chainId}`
  );
};

export const getChainDefaultAmm = (chainId: ApertureSupportedChainId) => {
  const chainInfo = getChainInfo(getNetworkName(chainId));
  return chainInfo.amm[0].name as E_AMM;
};

export const isAmmValid = (chainId: ApertureSupportedChainId, amm: E_AMM) => {
  const chainInfo = getChainInfo(getNetworkName(chainId));
  return chainInfo.amm.map((platform) => platform.name).includes(amm);
};

export function getQueryPlatform(chainId: ApertureSupportedChainId) {
  switch (chainId) {
    case ApertureSupportedChainId.ETHEREUM_MAINNET_CHAIN_ID:
      return "uniswap_v3_eth";
    case ApertureSupportedChainId.ARBITRUM_MAINNET_CHAIN_ID:
      return "uniswap_v3_arbitrum";
    case ApertureSupportedChainId.OPTIMISM_MAINNET_CHAIN_ID:
      return "uniswap_v3_optimism";
    case ApertureSupportedChainId.POLYGON_MAINNET_CHAIN_ID:
      return "uniswap_v3_polygon";
    case ApertureSupportedChainId.BASE_MAINNET_CHAIN_ID:
      return "uniswap_v3_base";
    case ApertureSupportedChainId.BNB_MAINNET_CHAIN_ID:
      return "uniswap_v3_bsc";
    case ApertureSupportedChainId.AVALANCHE_MAINNET_CHAIN_ID:
      return "uniswap_v3_avalanche";
    default:
      return undefined;
  }
}

export function getSafeTransactionService(chainId: ApertureSupportedChainId) {
  switch (chainId) {
    case ApertureSupportedChainId.ETHEREUM_MAINNET_CHAIN_ID:
      return "https://safe-transaction-mainnet.safe.global";
    case ApertureSupportedChainId.ARBITRUM_MAINNET_CHAIN_ID:
      return "https://safe-transaction-arbitrum.safe.global";
    case ApertureSupportedChainId.OPTIMISM_MAINNET_CHAIN_ID:
      return "https://safe-transaction-optimism.safe.global";
    case ApertureSupportedChainId.POLYGON_MAINNET_CHAIN_ID:
      return "https://safe-transaction-polygon.safe.global";
    case ApertureSupportedChainId.BASE_MAINNET_CHAIN_ID:
      return "https://safe-transaction-base.safe.global";
    case ApertureSupportedChainId.BNB_MAINNET_CHAIN_ID:
      return "https://safe-transaction-bsc.safe.global";
    case ApertureSupportedChainId.AVALANCHE_MAINNET_CHAIN_ID:
      return "https://safe-transaction-avalanche.safe.global";
    default:
      return undefined;
  }
}

export function isUserPortfolioBasedOnTokenMap(
  chainId: ApertureSupportedChainId
) {
  return isManta(chainId) || isScroll(chainId);
}

export function isFeeTierDistributionSupported(
  chainId: ApertureSupportedChainId
) {
  return isManta(chainId) || isScroll(chainId);
}
