import { Flex } from "@uiv2/common";
import { getChainInfo } from "@uiv2/utils";
import { ReactNode, useState } from "react";
import { ExplorerClosedPositions, IClosedPositions } from "../ClosedPositions";
import { LinearLoader } from "../Loader";
import { PositionAnalytics } from "../PositionAnalytics";
import { IPositionAnalytics } from "../PositionAnalytics/types";
import { IPositionOverview, PositionOverview } from "../PositionOverview";
import { PositionPriceRange } from "../PositionPriceRange";
import { LgPositionCard } from "./LgPositionCard";
import {
  ArrowBtn,
  ArrowDown,
  Content,
  ExplorerContainer,
  Tab,
  TabText,
} from "./style";
import { IClosedPosition, PositionCardProps } from "./types";

const tabs = [
  "Overview",
  "Price Range",
  "Position Analytics",
  "Rebalance History",
] as const;

type ITab = (typeof tabs)[number];

export interface IExplorerPositionCard
  extends Omit<PositionCardProps, "onClick"> {
  overview: IPositionOverview;
  analytics?: IPositionAnalytics;
  rebalanceHistory?: IClosedPositions;
  rebalanceHistoryLoading?: boolean;
  marketPrice: string;
  onClick: (isActive: boolean) => void;
}

export const ExplorerPositionCard: React.FC<IExplorerPositionCard> = ({
  id,
  chainId,
  supportedChainId,
  positionId,
  tokens,
  overview,
  analytics,
  rebalanceHistory,
  rebalanceHistoryLoading,
  marketPrice,
  selected = false,
  atStake,
  checked,
  sm = false,
  onClick,
  ...props
}) => {
  const { name, Icon } = getChainInfo(supportedChainId);
  const [expandable, setExpandable] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [overflow, setOverflow] = useState(false);
  const [logsShown, setLogsShown] = useState(false);
  const [selectedTab, setSelectedTab] = useState<ITab>();
  const isProd = process.env.NEXT_PUBLIC_ENV === "prod";

  const content: Record<ITab, ReactNode> = {
    Overview: <PositionOverview {...overview} />,
    "Price Range": (
      <PositionPriceRange
        {...{
          tokens: [tokens[0].ticker, tokens[1].ticker],
          priceRange: props.priceRange,
          marketPrice,
        }}
      />
    ),
    "Position Analytics": analytics ? (
      <PositionAnalytics {...{ ...analytics, wBorder: false, setLogsShown }} />
    ) : undefined,
    "Rebalance History": rebalanceHistoryLoading ? (
      <LinearLoader size="100%" height="230px" />
    ) : rebalanceHistory ? (
      <ExplorerClosedPositions {...rebalanceHistory} />
    ) : undefined,
  };
  const heights: Record<ITab, number> = {
    Overview: 158,
    "Price Range": 182,
    "Position Analytics": getHeight(
      logsShown,
      analytics?.activityLogs.length ?? 0,
      isProd
    ),
    "Rebalance History": rebalanceHistoryLoading
      ? 230
      : getRebalanceHistoryHeight(rebalanceHistory?.list ?? []),
  };

  const handleDropdownClick = (show: boolean) => {
    if (!expandable) return;
    setIsShow(show);
    onClick(show);

    setExpandable(!show);
    setTimeout(
      function () {
        setOverflow(show);
        setExpandable(true);
      },
      show ? 0 : 300
    );
  };

  return (
    <ExplorerContainer id={id} sm={sm}>
      <div style={{ width: "100%" }}>
        <LgPositionCard
          {...{
            ...props,
            tokens: [
              { ticker: tokens[0].ticker, Icon: tokens[0].Icon },
              { ticker: tokens[1].ticker, Icon: tokens[1].Icon },
            ],
            positionId,
            selected,
            atStake,
            sm,
            name,
            Icon,
            isExplorerCard: true,
          }}
        />
        <Flex
          style={{ marginTop: "12px" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex gap="md">
            {tabs.map(
              (tab) =>
                !!content[tab] && (
                  <Tab
                    key={tab}
                    selected={tab === selectedTab}
                    onClick={() => {
                      setSelectedTab(tab);
                      if (selectedTab === "Position Analytics" && logsShown)
                        setLogsShown(false);
                      handleDropdownClick(true);
                    }}
                  >
                    <TabText>{tab}</TabText>
                  </Tab>
                )
            )}
          </Flex>
          <ArrowBtn
            onClick={() => {
              onClick(!isShow);
              !selectedTab && setSelectedTab("Overview");
              handleDropdownClick(isShow ? false : true);
            }}
          >
            <ArrowDown isShow={isShow} />
          </ArrowBtn>
        </Flex>
        <Content
          isShow={isShow}
          useOverflow={overflow}
          h={selectedTab ? heights[selectedTab] : 0}
        >
          {selectedTab && content[selectedTab]}
        </Content>
      </div>
    </ExplorerContainer>
  );
};

const getHeight = (isShow: boolean, length: number, isProd: boolean) => {
  return (
    (isProd ? 273 : 337) + (isShow ? 74 + 44 * length + 24 * (length - 1) : 0)
  );
};
const getRebalanceHistoryHeight = (list: IClosedPosition[]) => {
  return (
    20 +
    431 * (list.length ?? 0) -
    (list.some((item) => !item.closedTimestamp || !item.closedMarketPrice)
      ? 133
      : 0)
  );
};
