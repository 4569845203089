export const NetErrorIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 14.6324C5 14.6324 5.49609 14.048 6.48884 13.2476C6.6755 13.0972 6.87972 12.939 7.10151 12.7757M35 14.6324C35 14.6324 30.0062 8.75 20 8.75C16.3152 8.75 13.3101 9.54771 10.9838 10.5556M9.6875 20.5147C9.6875 20.5147 10.3978 19.8439 11.6667 19.0571C11.9222 18.8987 12.2004 18.7355 12.5 18.5722M30.3125 20.5147C30.3125 20.5147 26.1603 16.5931 20 16.5931C19.0357 16.5931 18.1206 16.6892 17.263 16.8513M14.375 25.4167C14.375 25.4167 15.2449 24.6889 16.6667 24.1202C16.8559 24.0444 17.0549 23.9716 17.263 23.9036C17.4737 23.8347 17.6937 23.7709 17.9222 23.7143"
        stroke={props.stroke || "white"}
        strokeWidth="2.5"
        strokeLinecap="round"
        fill={props.fill || ""}
      />
      <path
        d="M20.0167 31.25L20.0001 31.25"
        stroke={props.stroke || "white"}
        strokeWidth="3.33333"
        strokeLinecap="round"
        fill={props.fill || ""}
      />
      <path
        d="M5 5L33.3333 33.3333"
        stroke={props.stroke || "white"}
        strokeWidth="2.5"
        strokeLinecap="round"
        fill={props.fill || ""}
      />
    </svg>
  );
};
