import { EvmChainMap, getExplorerURL } from "@/config/chain";
import { useEventCallback } from "@/hooks/useEventCallback";
import { useFetchTokenMap } from "@/hooks/useFetchTokenMap";
import { useFetchUserPortfolio } from "@/hooks/useFetchUserPortfolio";
import { useUserIPRestriction } from "@/hooks/useUserIPRestriction";
import {
  DEFAULT_AMM,
  DEFAULT_NETWORK,
  getNetworkId,
} from "@/utils/networkHelper";
import { formatTokenAmount } from "@/utils/numberFormat";
import {
  AmmInfo,
  AuthStatus,
  BasicInfo,
  ConnectionType,
  SupportedChainId,
  TopBar,
  useModal,
} from "@aperture/uikit";
import { viem } from "@aperture_finance/uniswap-v3-automation-sdk";
import { debounce } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAccount, useBalance, useSwitchChain } from "wagmi";
import { useAOStore, useConnectStore } from "../GlobalStore";
import { AuthModal } from "../Modal/AuthModal";
import { WrongNetworkModal } from "../Modal/WrongNetworkModal";
import { useNetwork } from "../NetworkContext/NetworkContext";
import { useSnackbar } from "../SnackbarContext/SnackbarContext";
import { useShowConnectModal, useToggleIPRestrictModal } from "./hooks";

const Container = styled.div<{ open: Boolean }>`
  width: ${({ open }) => (open ? "calc(100% - 648px)" : "100%")};
  height: 40px;
`;

const TopBarLayout: React.FC<React.PropsWithChildren<{ open: Boolean }>> = ({
  open,
  children,
}) => {
  const router = useRouter();
  const { isConnected, address, chain, connector } = useAccount();
  const { showModal, hideModal } = useModal();
  const { showConnectModal } = useShowConnectModal();
  const { data: walletBalance } = useBalance({
    address,
    query: { refetchInterval: 30_000 },
  });

  const { amm, ammEnum, network, networkId, setNetwork, isChainSupported } =
    useNetwork();
  const { ifAuthorized, isAOStatusLoading } = useAOStore();
  const { connectType, setConnectType, disconnect } = useConnectStore();
  const [authKeepOpen, setAuthKeepOpen] = useState(false);
  const [activities, setActivities] = useState<
    { [hash: string]: viem.Activity } | undefined
  >(undefined);
  const { data: tokenMap } = useFetchTokenMap();
  const {
    switchChain,
    isPending: isSwitchChainLoading,
    isError: isSwitchChainError,
    error: switchChainTxnErrorMsg,
  } = useSwitchChain();
  const { data: portfolioBalances, mutate: portfolioMutate } =
    useFetchUserPortfolio(tokenMap, walletBalance?.formatted);
  const { addSnackbar } = useSnackbar();
  const { data: userIpInfo } = useUserIPRestriction();
  const { toggleIPRestrictionModal } = useToggleIPRestrictModal();

  useEffect(() => {
    const getActivities = async () => {
      if (!address) return;
      const activities = await viem.getWalletActivities(address);
      setActivities(activities);
    };
    getActivities();
  }, [address]);

  useEffect(() => {
    debounce(() => {
      if (tokenMap) portfolioMutate();
    }, 500);
  }, [tokenMap]);

  useEffect(() => {
    if (isSwitchChainError) {
      console.log("Failed to switch network", switchChainTxnErrorMsg);
      addSnackbar("Your switch network request has failed", "failed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSwitchChainError]);

  const isWrongNetworkLoading =
    !isSwitchChainError && isConnected && chain && isChainSupported
      ? false
      : isSwitchChainLoading;

  const handleOpenWrongNetworkModal = () => {
    if (chain?.id !== networkId) {
      switchChain?.({ chainId: networkId });
      return;
    }
    showModal(WrongNetworkModal, {
      onclose: () => {
        hideModal();
        if (ammEnum === "SLIPSTREAM") {
          if (
            router.pathname.startsWith("/limit-orders") ||
            router.pathname.startsWith("/swap")
          ) {
            if (network === SupportedChainId.BASE) {
              setNetwork(AmmInfo.Uniswap, SupportedChainId.BASE);
            } else if (network === SupportedChainId.OPTIMISM) {
              setNetwork(AmmInfo.Uniswap, SupportedChainId.OPTIMISM);
            }
            return;
          }
        } else if (
          router.pathname.startsWith("/limit-orders") ||
          router.pathname.startsWith("/recurring-rebalance")
        ) {
          if (network === SupportedChainId.MANTA) {
            setNetwork(DEFAULT_AMM, DEFAULT_NETWORK);
            switchChain?.({ chainId: getNetworkId(DEFAULT_NETWORK) });
            return;
          }
        }
      },
    });
  };

  const handleOpenAuthModal = () => {
    setAuthKeepOpen(true);
    showModal(AuthModal, {
      onClose: () => {
        setAuthKeepOpen(false);
        hideModal();
      },
      onclose: () => {
        setAuthKeepOpen(false);
        hideModal();
      },
    });
  };

  const timerRef = useRef(null);
  const toggleWrongNetworkModal = useEventCallback((open: boolean) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (open && isConnected) {
      // open modal after 500ms to avoid flickering
      timerRef.current = setTimeout(async () => {
        try {
          const connectorChainId = await connector?.getChainId?.();
          if (connectorChainId) handleOpenWrongNetworkModal();
        } catch (e) {
          disconnect();
          addSnackbar(
            "Error connecting, please retry and confirm in wallet!",
            "failed"
          );
        }
      }, 500);
    } else {
      hideModal();
    }
  });

  useEffect(() => {
    if (isConnected) {
      toggleWrongNetworkModal(!isChainSupported);
    } else setConnectType(ConnectionType.NONE);
  }, [isConnected, chain, isChainSupported, toggleWrongNetworkModal]);

  useEffect(() => {
    if (
      router.pathname.startsWith("/stake") ||
      router.pathname.startsWith("/claim")
    )
      toggleIPRestrictionModal(userIpInfo);
  }, [userIpInfo, router.pathname, toggleIPRestrictionModal]);

  return (
    <Container open={open}>
      <TopBar
        currentAmm={amm}
        currentChainId={network}
        supportedAmmList={AmmInfo}
        chainOnSelect={(amm: BasicInfo, chain: SupportedChainId) =>
          setNetwork(amm, chain)
        }
        walletConnected={isConnected}
        connectType={connectType}
        authUser={{
          address: address ? address : "",
          balance: formatTokenAmount(walletBalance?.formatted ?? "0.0"),
          ticker: walletBalance?.symbol ?? "",
        }}
        authStatus={
          isAOStatusLoading
            ? AuthStatus.LOADING
            : ifAuthorized
            ? AuthStatus.AUTHORIZED
            : AuthStatus.UNAUTHORIZED
        }
        authKeepOpen={authKeepOpen}
        onAuthClick={() => handleOpenAuthModal()}
        portfolioBalances={portfolioBalances}
        activities={activities}
        onDisconnect={() => disconnect()}
        url={
          chain && EvmChainMap[chain.id] ? EvmChainMap[chain.id].exploreURL : ""
        }
        wrongNetwork={isConnected && !isChainSupported}
        wrongNetworkLoading={isWrongNetworkLoading}
        openConnectWalletModal={showConnectModal}
        openWrongNetworkModal={handleOpenWrongNetworkModal}
        explorerURL={getExplorerURL(networkId)}
      />
      {children}
    </Container>
  );
};

export default TopBarLayout;
