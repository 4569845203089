export const ClosedPositionIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10" cy="10" r="10" fill="#363636" />
      <path d="M6 13.4998L13.4998 6" stroke="white" strokeWidth="2" />
      <path d="M5.99992 6L13.4998 13.4998" stroke="white" strokeWidth="2" />
    </svg>
  );
};
