// TODO: remove this file after refactoring
import { useTheme } from "@/Providers";
import { getExplorerURL } from "@/config/chain";
import { useWaitForTransaction } from "@/hooks/useWaitForTransaction";
import { openSnackBar } from "@aperture/uikit";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Address } from "viem";
import { useNetwork } from "../NetworkContext/NetworkContext";

type Transaction = {
  hash?: Address;
  successMsg?: string;
  failureMsg?: string;
};

type IAddSnackbar = (
  info: string,
  status: "completed" | "failed",
  url?: string,
  transaction?: Transaction
) => void;

type ISnackbarContext = {
  addSnackbar: IAddSnackbar;
};

type ISnackbarProvider = {
  children: React.ReactNode;
};

const SnackbarContext = createContext<ISnackbarContext | undefined>(undefined);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

export const SnackbarProvider: React.FC<ISnackbarProvider> = ({ children }) => {
  const [transactionList, setTransactionList] = useState<Transaction[]>([]);
  const [currentTransaction, setTransaction] = useState<Transaction>();
  const { networkId } = useNetwork();
  const { data, isSuccess, isLoading, isError, error } = useWaitForTransaction({
    hash: currentTransaction?.hash,
  });
  const { theme } = useTheme();

  const addSnackbar: IAddSnackbar = (info, status, url, transaction) => {
    openSnackBar({ info, status, url, currentTheme: theme });
    if (transaction) {
      setTransactionList([...transactionList, transaction]);
    }
  };

  useEffect(() => {
    if (!isLoading && data) {
      const url = `${getExplorerURL(networkId)}/tx/${data.transactionHash}`;
      if (isSuccess && !isLoading && data.status === "success") {
        addSnackbar(
          currentTransaction.successMsg ?? "Your transaction is completed.",
          "completed",
          url
        );
      } else {
        addSnackbar(
          currentTransaction.failureMsg ?? "Your transaction has failed.",
          "failed",
          url
        );
      }
      setTransaction(undefined);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (isError) {
      if (String(error).indexOf("TransactionNotFoundError") === -1)
        addSnackbar(
          currentTransaction.failureMsg ?? "Your transaction has failed.",
          "failed",
          currentTransaction?.hash
            ? `${getExplorerURL(networkId)}/tx/${currentTransaction.hash}`
            : undefined
        );
      setTransaction(undefined);
    }
  }, [isError]);

  useEffect(() => {
    if (transactionList.length > 0 && !isLoading) {
      setTransaction(transactionList[0]);
      setTransactionList(transactionList.slice(1));
    }
  }, [transactionList]);

  return (
    <SnackbarContext.Provider value={{ addSnackbar }}>
      {children}
      <div id="snackbarOuterContainer" />
    </SnackbarContext.Provider>
  );
};
