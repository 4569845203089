export const MinusIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="15"
      height="3"
      viewBox="0 0 15 3"
      fill={props.fill || "white"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.785 2.06477H1.465C1.24222 2.06477 1.02856 1.97627 0.87103 1.81873C0.7135 1.6612 0.625 1.44755 0.625 1.22477C0.625 1.00198 0.7135 0.788326 0.87103 0.630796C1.02856 0.473265 1.24222 0.384766 1.465 0.384766H13.785C14.0078 0.384766 14.2214 0.473265 14.379 0.630796C14.5365 0.788326 14.625 1.00198 14.625 1.22477C14.625 1.44755 14.5365 1.6612 14.379 1.81873C14.2214 1.97627 14.0078 2.06477 13.785 2.06477Z" />
    </svg>
  );
};
