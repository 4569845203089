export const TwitterIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24.2539C18.6274 24.2539 24 18.8813 24 12.2539C24 5.62649 18.6274 0.253906 12 0.253906C5.37258 0.253906 0 5.62649 0 12.2539C0 18.8813 5.37258 24.2539 12 24.2539ZM17.6009 6.25311L13.1367 11.3349L17.9898 18.2531H14.4192L11.1499 13.5934L7.05734 18.2531H5.99982L10.6811 12.9248L5.99982 6.25311H9.57044L12.6655 10.6646L16.5434 6.25311H17.6009ZM9.06366 7.03406H7.43902L14.9176 17.5105H16.5426L9.06366 7.03406Z"
        fill="white"
      />
    </svg>
  );
};
