export const EthereumIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_ethereum_clip0)`}>
        <circle cx="10" cy="10" r="10" fill="#628DFE" />
        <path
          d="M10.2215 2.5213V2.5284C10.2286 2.5639 10.2286 2.6065 10.2286 2.6491V7.7681C10.2215 7.7965 10.2003 7.8036 10.1789 7.8178C10.0299 7.88879 9.88786 7.95267 9.73878 8.01658C9.53286 8.10892 9.31986 8.20825 9.11394 8.30058L8.36844 8.64142C8.16258 8.73367 7.95669 8.826 7.75789 8.91825C7.51649 9.03183 7.26799 9.13833 7.0266 9.252C6.8207 9.34425 6.61481 9.44367 6.40181 9.536C6.23142 9.61408 6.06102 9.68508 5.89773 9.76317C5.88353 9.77025 5.86933 9.77733 5.85513 9.77733C5.84803 9.77733 5.84803 9.77733 5.84093 9.77025L6.03262 9.45075C6.40181 8.84017 6.76391 8.23667 7.1331 7.6261C7.52359 6.97292 7.92119 6.31972 8.31168 5.66654C8.67378 5.06305 9.04294 4.45956 9.40503 3.85607C9.66778 3.41588 9.93753 2.97569 10.2003 2.5355C10.2074 2.5213 10.2144 2.5142 10.2144 2.5H10.2215C10.2144 2.5071 10.2215 2.5142 10.2215 2.5213Z"
          fill="white"
        />
        <path
          opacity="0.840588"
          d="M14.5807 9.77042L14.5878 9.7775L13.5442 10.3952L10.264 12.3335C10.2498 12.3406 10.2357 12.3477 10.2286 12.3548C10.2072 12.3548 10.2072 12.3335 10.2072 12.3263V12.2625V7.87475C10.2072 7.85345 10.2072 7.82505 10.2143 7.80375C10.2214 7.77535 10.2427 7.78245 10.264 7.78955C10.3563 7.83215 10.4557 7.87475 10.548 7.91734C10.8249 8.04517 11.1018 8.17292 11.3787 8.29367C11.6201 8.40017 11.8544 8.51375 12.0958 8.62025C12.3372 8.72675 12.5786 8.84034 12.82 8.94684C13.0259 9.03917 13.2389 9.1385 13.4447 9.23084C13.6507 9.32309 13.8637 9.4225 14.0696 9.51484C14.2328 9.58584 14.3962 9.66392 14.5594 9.73492C14.5594 9.75625 14.5666 9.76334 14.5807 9.77042Z"
          fill="white"
        />
        <path
          d="M10.2215 16.7644C10.2215 16.7715 10.2144 16.7786 10.2144 16.7857H10.2072C10.2072 16.7715 10.193 16.7644 10.186 16.7502C9.74579 16.1325 9.30554 15.5077 8.86537 14.89C8.41804 14.2581 7.96369 13.6191 7.5164 12.9873C7.0833 12.3767 6.64311 11.759 6.21002 11.1484C6.09642 10.9851 5.98282 10.8289 5.86922 10.6656C5.86212 10.6514 5.85502 10.6443 5.84082 10.623C5.86212 10.623 5.87632 10.6372 5.88342 10.6443C6.50111 11.0064 7.1117 11.3685 7.72939 11.7306C8.43937 12.1495 9.14229 12.5684 9.85229 12.9873L10.2144 13.2003C10.2285 13.2145 10.2285 13.2287 10.2285 13.2429V16.665C10.2285 16.7005 10.2285 16.736 10.2215 16.7644Z"
          fill="white"
        />
        <path
          opacity="0.840588"
          d="M5.83279 9.7843V9.77713C6.05999 9.6778 6.28009 9.5713 6.50728 9.47188C6.79838 9.33696 7.08947 9.20921 7.38056 9.0743C7.60066 8.97488 7.82785 8.86838 8.04795 8.76896C8.37454 8.61988 8.69404 8.47788 9.02063 8.3288C9.24071 8.22938 9.46079 8.12996 9.68804 8.02346C9.84421 7.95246 10.0075 7.8815 10.1637 7.8105C10.1779 7.8034 10.1992 7.7963 10.2063 7.7821C10.2134 7.7821 10.2134 7.7892 10.2063 7.7963V12.3047C10.2063 12.326 10.1992 12.3473 10.2134 12.3615C10.1992 12.3828 10.185 12.3615 10.1779 12.3544C10.114 12.3189 10.0501 12.2834 9.98621 12.2408C8.61596 11.4315 7.23857 10.615 5.86829 9.80555C5.86119 9.79846 5.84699 9.79138 5.83279 9.7843Z"
          fill="white"
        />
        <path
          d="M14.5661 10.623H14.5732C14.5732 10.6372 14.559 10.6514 14.552 10.6656C13.2455 12.5045 11.9392 14.3505 10.6328 16.1893C10.4979 16.381 10.3559 16.5727 10.221 16.7644C10.2139 16.7573 10.2139 16.7502 10.2139 16.7431V16.7005V13.257V13.1932C10.5121 13.0157 10.8032 12.8453 11.1014 12.6678C12.2515 11.9862 13.4017 11.3117 14.5448 10.6301C14.552 10.6372 14.559 10.6301 14.5661 10.623Z"
          fill="white"
        />
        <path
          d="M10.2135 7.79642V7.78222V7.73962V2.58512C10.2135 2.56382 10.2064 2.54962 10.2206 2.52832C11.6618 4.92097 13.1031 7.30653 14.5373 9.69917C14.5515 9.7205 14.5728 9.74892 14.5799 9.77725C14.4805 9.74175 14.3881 9.69209 14.2959 9.6495C14.1823 9.59975 14.0616 9.543 13.948 9.49325C13.877 9.45775 13.7989 9.42942 13.7279 9.39392C13.6072 9.33709 13.4865 9.28742 13.3658 9.23059C13.2948 9.20217 13.2238 9.16667 13.1528 9.13117L12.6842 8.91817C12.6061 8.88267 12.5281 8.84717 12.4428 8.81167L12.1021 8.66259C12.0311 8.63417 11.9601 8.59867 11.8891 8.56317L11.4205 8.35017C11.3423 8.31467 11.2642 8.27917 11.1791 8.24367L10.8382 8.09459C10.7601 8.05909 10.6891 8.02359 10.6111 7.98809C10.4761 7.92425 10.3412 7.86032 10.1992 7.80352C10.2206 7.79642 10.2135 7.79642 10.2135 7.79642Z"
          fill="white"
        />
        <circle cx="10" cy="10" r="10" fill="#628DFE" />
        <path
          d="M10.2215 2.5213V2.5284C10.2286 2.5639 10.2286 2.6065 10.2286 2.6491V7.7681C10.2215 7.7965 10.2003 7.8036 10.1789 7.8178C10.0299 7.88879 9.88786 7.95267 9.73878 8.01658C9.53286 8.10892 9.31986 8.20825 9.11394 8.30058L8.36844 8.64142C8.16258 8.73367 7.95669 8.826 7.75789 8.91825C7.51649 9.03183 7.26799 9.13833 7.0266 9.252C6.8207 9.34425 6.61481 9.44367 6.40181 9.536C6.23142 9.61408 6.06102 9.68508 5.89773 9.76317C5.88353 9.77025 5.86933 9.77733 5.85513 9.77733C5.84803 9.77733 5.84803 9.77733 5.84093 9.77025L6.03262 9.45075C6.40181 8.84017 6.76391 8.23667 7.1331 7.6261C7.52359 6.97292 7.92119 6.31972 8.31168 5.66654C8.67378 5.06305 9.04294 4.45956 9.40503 3.85607C9.66778 3.41588 9.93753 2.97569 10.2003 2.5355C10.2074 2.5213 10.2144 2.5142 10.2144 2.5H10.2215C10.2144 2.5071 10.2215 2.5142 10.2215 2.5213Z"
          fill="white"
        />
        <path
          opacity="0.840588"
          d="M14.5807 9.77042L14.5878 9.7775L13.5442 10.3952L10.264 12.3335C10.2498 12.3406 10.2357 12.3477 10.2286 12.3548C10.2072 12.3548 10.2072 12.3335 10.2072 12.3263V12.2625V7.87475C10.2072 7.85345 10.2072 7.82505 10.2143 7.80375C10.2214 7.77535 10.2427 7.78245 10.264 7.78955C10.3563 7.83215 10.4557 7.87475 10.548 7.91734C10.8249 8.04517 11.1018 8.17292 11.3787 8.29367C11.6201 8.40017 11.8544 8.51375 12.0958 8.62025C12.3372 8.72675 12.5786 8.84034 12.82 8.94684C13.0259 9.03917 13.2389 9.1385 13.4447 9.23084C13.6507 9.32309 13.8637 9.4225 14.0696 9.51484C14.2328 9.58584 14.3962 9.66392 14.5594 9.73492C14.5594 9.75625 14.5666 9.76334 14.5807 9.77042Z"
          fill="white"
        />
        <path
          d="M10.2215 16.7644C10.2215 16.7715 10.2144 16.7786 10.2144 16.7857H10.2072C10.2072 16.7715 10.193 16.7644 10.186 16.7502C9.74579 16.1325 9.30554 15.5077 8.86537 14.89C8.41804 14.2581 7.96369 13.6191 7.5164 12.9873C7.0833 12.3767 6.64311 11.759 6.21002 11.1484C6.09642 10.9851 5.98282 10.8289 5.86922 10.6656C5.86212 10.6514 5.85502 10.6443 5.84082 10.623C5.86212 10.623 5.87632 10.6372 5.88342 10.6443C6.50111 11.0064 7.1117 11.3685 7.72939 11.7306C8.43937 12.1495 9.14229 12.5684 9.85229 12.9873L10.2144 13.2003C10.2285 13.2145 10.2285 13.2287 10.2285 13.2429V16.665C10.2285 16.7005 10.2285 16.736 10.2215 16.7644Z"
          fill="white"
        />
        <path
          opacity="0.840588"
          d="M5.83279 9.7843V9.77713C6.05999 9.6778 6.28009 9.5713 6.50728 9.47188C6.79838 9.33696 7.08947 9.20921 7.38056 9.0743C7.60066 8.97488 7.82785 8.86838 8.04795 8.76896C8.37454 8.61988 8.69404 8.47788 9.02063 8.3288C9.24071 8.22938 9.46079 8.12996 9.68804 8.02346C9.84421 7.95246 10.0075 7.8815 10.1637 7.8105C10.1779 7.8034 10.1992 7.7963 10.2063 7.7821C10.2134 7.7821 10.2134 7.7892 10.2063 7.7963V12.3047C10.2063 12.326 10.1992 12.3473 10.2134 12.3615C10.1992 12.3828 10.185 12.3615 10.1779 12.3544C10.114 12.3189 10.0501 12.2834 9.98621 12.2408C8.61596 11.4315 7.23857 10.615 5.86829 9.80555C5.86119 9.79846 5.84699 9.79138 5.83279 9.7843Z"
          fill="white"
        />
        <path
          d="M14.5661 10.623H14.5732C14.5732 10.6372 14.559 10.6514 14.552 10.6656C13.2455 12.5045 11.9392 14.3505 10.6328 16.1893C10.4979 16.381 10.3559 16.5727 10.221 16.7644C10.2139 16.7573 10.2139 16.7502 10.2139 16.7431V16.7005V13.257V13.1932C10.5121 13.0157 10.8032 12.8453 11.1014 12.6678C12.2515 11.9862 13.4017 11.3117 14.5448 10.6301C14.552 10.6372 14.559 10.6301 14.5661 10.623Z"
          fill="white"
        />
        <path
          d="M10.2135 7.79642V7.78222V7.73962V2.58512C10.2135 2.56382 10.2064 2.54962 10.2206 2.52832C11.6618 4.92097 13.1031 7.30653 14.5373 9.69917C14.5515 9.7205 14.5728 9.74892 14.5799 9.77725C14.4805 9.74175 14.3881 9.69209 14.2959 9.6495C14.1823 9.59975 14.0616 9.543 13.948 9.49325C13.877 9.45775 13.7989 9.42942 13.7279 9.39392C13.6072 9.33709 13.4865 9.28742 13.3658 9.23059C13.2948 9.20217 13.2238 9.16667 13.1528 9.13117L12.6842 8.91817C12.6061 8.88267 12.5281 8.84717 12.4428 8.81167L12.1021 8.66259C12.0311 8.63417 11.9601 8.59867 11.8891 8.56317L11.4205 8.35017C11.3423 8.31467 11.2642 8.27917 11.1791 8.24367L10.8382 8.09459C10.7601 8.05909 10.6891 8.02359 10.6111 7.98809C10.4761 7.92425 10.3412 7.86032 10.1992 7.80352C10.2206 7.79642 10.2135 7.79642 10.2135 7.79642Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={`${props.id}_ethereum_clip0`}>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
