export const EthIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_eth_clip0)`}>
        <circle cx="20" cy="20" r="20" fill="#628DFE" />
        <path
          d="M20.4431 5.0426V5.0568C20.4572 5.1278 20.4572 5.213 20.4572 5.2982V15.5362C20.4431 15.593 20.4006 15.6072 20.3579 15.6356C20.0597 15.7776 19.7757 15.9053 19.4776 16.0332C19.0657 16.2178 18.6397 16.4165 18.2279 16.6012L16.7369 17.2828C16.3252 17.4673 15.9134 17.652 15.5158 17.8365C15.033 18.0637 14.536 18.2767 14.0532 18.504C13.6414 18.6885 13.2296 18.8873 12.8036 19.072C12.4628 19.2282 12.122 19.3702 11.7955 19.5263C11.7671 19.5405 11.7387 19.5547 11.7103 19.5547C11.6961 19.5547 11.6961 19.5547 11.6819 19.5405L12.0652 18.9015C12.8036 17.6803 13.5278 16.4733 14.2662 15.2522C15.0472 13.9458 15.8424 12.6394 16.6234 11.3331C17.3476 10.1261 18.0859 8.91912 18.8101 7.71215C19.3356 6.83177 19.8751 5.95138 20.4006 5.071C20.4147 5.0426 20.4289 5.0284 20.4289 5H20.4431C20.4289 5.0142 20.4431 5.0284 20.4431 5.0426Z"
          fill="white"
        />
        <path
          opacity="0.840588"
          d="M29.1611 19.5408L29.1752 19.555L27.0879 20.7903L20.5276 24.667C20.4992 24.6812 20.4709 24.6953 20.4567 24.7095C20.4141 24.7095 20.4141 24.667 20.4141 24.6527V24.525V15.7495C20.4141 15.7069 20.4141 15.6501 20.4282 15.6075C20.4424 15.5507 20.4851 15.5649 20.5276 15.5791C20.7122 15.6643 20.9111 15.7495 21.0956 15.8347C21.6494 16.0903 22.2032 16.3458 22.7571 16.5873C23.2397 16.8003 23.7084 17.0275 24.1912 17.2405C24.6739 17.4535 25.1567 17.6807 25.6396 17.8937C26.0514 18.0783 26.4774 18.277 26.8891 18.4617C27.3009 18.6462 27.7269 18.845 28.1387 19.0297C28.4652 19.1717 28.7919 19.3278 29.1184 19.4698C29.1184 19.5125 29.1327 19.5267 29.1611 19.5408Z"
          fill="white"
        />
        <path
          d="M20.4419 33.5288C20.4419 33.5429 20.4278 33.5573 20.4278 33.5714H20.4134C20.4134 33.5429 20.3851 33.5288 20.3709 33.5004C19.4906 32.2651 18.6101 31.0154 17.7298 29.7801C16.8351 28.5163 15.9264 27.2383 15.0318 25.9746C14.1656 24.7534 13.2852 23.5179 12.4191 22.2968C12.1919 21.9703 11.9647 21.6578 11.7375 21.3313C11.7233 21.3028 11.7091 21.2886 11.6807 21.2461C11.7233 21.2461 11.7517 21.2744 11.7659 21.2886C13.0012 22.0128 14.2224 22.7371 15.4578 23.4613C16.8778 24.2989 18.2836 25.1368 19.7036 25.9746L20.4278 26.4006C20.4561 26.4289 20.4561 26.4574 20.4561 26.4858V33.3301C20.4561 33.4009 20.4561 33.4719 20.4419 33.5288Z"
          fill="white"
        />
        <path
          opacity="0.840588"
          d="M11.666 19.5688V19.5545C12.1204 19.3558 12.5606 19.1428 13.015 18.944C13.5972 18.6742 14.1794 18.4187 14.7615 18.1488C15.2017 17.95 15.6561 17.737 16.0963 17.5382C16.7495 17.24 17.3885 16.956 18.0417 16.6578C18.4818 16.459 18.922 16.2602 19.3765 16.0472C19.6888 15.9052 20.0155 15.7632 20.3278 15.6213C20.3562 15.6071 20.3988 15.5929 20.413 15.5645C20.4272 15.5645 20.4272 15.5787 20.413 15.5929V24.6097C20.413 24.6523 20.3988 24.6948 20.4272 24.7233C20.3988 24.7658 20.3705 24.7233 20.3562 24.709C20.2285 24.638 20.1007 24.567 19.9728 24.4818C17.2323 22.8632 14.4776 21.2302 11.737 19.6113C11.7228 19.5972 11.6944 19.583 11.666 19.5688Z"
          fill="white"
        />
        <path
          d="M29.1332 21.2461H29.1474C29.1474 21.2744 29.119 21.3028 29.1049 21.3313C26.492 25.0089 23.8794 28.7009 21.2665 32.3786C20.9967 32.7621 20.7127 33.1454 20.443 33.5288C20.4287 33.5146 20.4287 33.5004 20.4287 33.4863V33.4009V26.5141V26.3864C21.0252 26.0314 21.6074 25.6906 22.2037 25.3356C24.504 23.9724 26.8044 22.6234 29.0905 21.2603C29.1049 21.2744 29.119 21.2603 29.1332 21.2461Z"
          fill="white"
        />
        <path
          d="M20.4278 15.5926V15.5642V15.479V5.17003C20.4278 5.12743 20.4136 5.09903 20.442 5.05643C23.3245 9.84173 26.2071 14.6128 29.0755 19.3981C29.1038 19.4408 29.1465 19.4976 29.1606 19.5543C28.9618 19.4833 28.7771 19.384 28.5926 19.2988C28.3655 19.1993 28.124 19.0858 27.8968 18.9863C27.7548 18.9153 27.5986 18.8586 27.4566 18.7876C27.2153 18.674 26.9738 18.5746 26.7325 18.461C26.5905 18.4041 26.4485 18.3331 26.3065 18.2621L25.3693 17.8361C25.2131 17.7651 25.057 17.6941 24.8865 17.6231L24.205 17.325C24.063 17.2681 23.921 17.1971 23.779 17.1261L22.8418 16.7001C22.6855 16.6291 22.5293 16.5581 22.359 16.4871L21.6773 16.189C21.5211 16.118 21.3791 16.047 21.223 15.976C20.9531 15.8483 20.6833 15.7204 20.3993 15.6068C20.442 15.5926 20.4278 15.5926 20.4278 15.5926Z"
          fill="white"
        />
        <circle cx="20" cy="20" r="20" fill="#628DFE" />
        <path
          d="M20.4431 5.0426V5.0568C20.4572 5.1278 20.4572 5.213 20.4572 5.2982V15.5362C20.4431 15.593 20.4006 15.6072 20.3579 15.6356C20.0597 15.7776 19.7757 15.9053 19.4776 16.0332C19.0657 16.2178 18.6397 16.4165 18.2279 16.6012L16.7369 17.2828C16.3252 17.4673 15.9134 17.652 15.5158 17.8365C15.033 18.0637 14.536 18.2767 14.0532 18.504C13.6414 18.6885 13.2296 18.8873 12.8036 19.072C12.4628 19.2282 12.122 19.3702 11.7955 19.5263C11.7671 19.5405 11.7387 19.5547 11.7103 19.5547C11.6961 19.5547 11.6961 19.5547 11.6819 19.5405L12.0652 18.9015C12.8036 17.6803 13.5278 16.4733 14.2662 15.2522C15.0472 13.9458 15.8424 12.6394 16.6234 11.3331C17.3476 10.1261 18.0859 8.91912 18.8101 7.71215C19.3356 6.83177 19.8751 5.95138 20.4006 5.071C20.4147 5.0426 20.4289 5.0284 20.4289 5H20.4431C20.4289 5.0142 20.4431 5.0284 20.4431 5.0426Z"
          fill="white"
        />
        <path
          opacity="0.840588"
          d="M29.1611 19.5408L29.1752 19.555L27.0879 20.7903L20.5276 24.667C20.4992 24.6812 20.4709 24.6953 20.4567 24.7095C20.4141 24.7095 20.4141 24.667 20.4141 24.6527V24.525V15.7495C20.4141 15.7069 20.4141 15.6501 20.4282 15.6075C20.4424 15.5507 20.4851 15.5649 20.5276 15.5791C20.7122 15.6643 20.9111 15.7495 21.0956 15.8347C21.6494 16.0903 22.2032 16.3458 22.7571 16.5873C23.2397 16.8003 23.7084 17.0275 24.1912 17.2405C24.6739 17.4535 25.1567 17.6807 25.6396 17.8937C26.0514 18.0783 26.4774 18.277 26.8891 18.4617C27.3009 18.6462 27.7269 18.845 28.1387 19.0297C28.4652 19.1717 28.7919 19.3278 29.1184 19.4698C29.1184 19.5125 29.1327 19.5267 29.1611 19.5408Z"
          fill="white"
        />
        <path
          d="M20.4419 33.5288C20.4419 33.5429 20.4278 33.5573 20.4278 33.5714H20.4134C20.4134 33.5429 20.3851 33.5288 20.3709 33.5004C19.4906 32.2651 18.6101 31.0154 17.7298 29.7801C16.8351 28.5163 15.9264 27.2383 15.0318 25.9746C14.1656 24.7534 13.2852 23.5179 12.4191 22.2968C12.1919 21.9703 11.9647 21.6578 11.7375 21.3313C11.7233 21.3028 11.7091 21.2886 11.6807 21.2461C11.7233 21.2461 11.7517 21.2744 11.7659 21.2886C13.0012 22.0128 14.2224 22.7371 15.4578 23.4613C16.8778 24.2989 18.2836 25.1368 19.7036 25.9746L20.4278 26.4006C20.4561 26.4289 20.4561 26.4574 20.4561 26.4858V33.3301C20.4561 33.4009 20.4561 33.4719 20.4419 33.5288Z"
          fill="white"
        />
        <path
          opacity="0.840588"
          d="M11.666 19.5688V19.5545C12.1204 19.3558 12.5606 19.1428 13.015 18.944C13.5972 18.6742 14.1794 18.4187 14.7615 18.1488C15.2017 17.95 15.6561 17.737 16.0963 17.5382C16.7495 17.24 17.3885 16.956 18.0417 16.6578C18.4818 16.459 18.922 16.2602 19.3765 16.0472C19.6888 15.9052 20.0155 15.7632 20.3278 15.6213C20.3562 15.6071 20.3988 15.5929 20.413 15.5645C20.4272 15.5645 20.4272 15.5787 20.413 15.5929V24.6097C20.413 24.6523 20.3988 24.6948 20.4272 24.7233C20.3988 24.7658 20.3705 24.7233 20.3562 24.709C20.2285 24.638 20.1007 24.567 19.9728 24.4818C17.2323 22.8632 14.4776 21.2302 11.737 19.6113C11.7228 19.5972 11.6944 19.583 11.666 19.5688Z"
          fill="white"
        />
        <path
          d="M29.1332 21.2461H29.1474C29.1474 21.2744 29.119 21.3028 29.1049 21.3313C26.492 25.0089 23.8794 28.7009 21.2665 32.3786C20.9967 32.7621 20.7127 33.1454 20.443 33.5288C20.4287 33.5146 20.4287 33.5004 20.4287 33.4863V33.4009V26.5141V26.3864C21.0252 26.0314 21.6074 25.6906 22.2037 25.3356C24.504 23.9724 26.8044 22.6234 29.0905 21.2603C29.1049 21.2744 29.119 21.2603 29.1332 21.2461Z"
          fill="white"
        />
        <path
          d="M20.4278 15.5926V15.5642V15.479V5.17003C20.4278 5.12743 20.4136 5.09903 20.442 5.05643C23.3245 9.84173 26.2071 14.6128 29.0755 19.3981C29.1038 19.4408 29.1465 19.4976 29.1606 19.5543C28.9618 19.4833 28.7771 19.384 28.5926 19.2988C28.3655 19.1993 28.124 19.0858 27.8968 18.9863C27.7548 18.9153 27.5986 18.8586 27.4566 18.7876C27.2153 18.674 26.9738 18.5746 26.7325 18.461C26.5905 18.4041 26.4485 18.3331 26.3065 18.2621L25.3693 17.8361C25.2131 17.7651 25.057 17.6941 24.8865 17.6231L24.205 17.325C24.063 17.2681 23.921 17.1971 23.779 17.1261L22.8418 16.7001C22.6855 16.6291 22.5293 16.5581 22.359 16.4871L21.6773 16.189C21.5211 16.118 21.3791 16.047 21.223 15.976C20.9531 15.8483 20.6833 15.7204 20.3993 15.6068C20.442 15.5926 20.4278 15.5926 20.4278 15.5926Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={`${props.id}_eth_clip0`}>
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
