import {
  ArbitrumIcon,
  AvalancheIcon,
  BscIcon,
  EthereumIcon,
  SolanaIcon,
} from "@aperture/assetkit";
import { Flex } from "@ui/common";
import styled from "styled-components";
import { AllChainsIconProps, ICircle } from "./types";

const Circle = styled.div<ICircle>`
  width: ${({ width }) => (width ? `${width}px` : "26px")};
  height: ${({ height }) => (height ? `${height}px` : "26px")};
  border-radius: ${({ theme }) => theme.radii.circle};
  background: ${({ type }) =>
    type === "light" ? "#CFD3E3" : type === "dark" ? "#A19CB0" : undefined};
  margin-left: ${({ overlap, index }) =>
    overlap && index !== 0 ? `-${overlap}px` : undefined};
  z-index: ${({ overlapRight, index, length }) =>
    overlapRight ? length - 1 - index : undefined};
  svg {
    display: block;
  }
`;

export const AllChainsIcon = ({
  sm = false,
  circles = tokenIconCircles,
  overlapRight = false,
}: AllChainsIconProps) => {
  const size = sm ? 16 : 24;
  const overlap = size / 2;
  return (
    <Flex>
      {circles.map((type, index) => {
        const Icon = type !== "dark" && type !== "light" ? type : undefined;
        return (
          <Circle
            {...{
              index,
              width: size,
              height: size,
              type: !Icon ? type : "dark",
              overlapRight,
              overlap,
              length: circles.length,
            }}
            key={index}
          >
            {Icon && <Icon {...{ width: size, height: size }} />}
          </Circle>
        );
      })}
    </Flex>
  );
};

export const greyCircles: ICircle["type"][] = ["dark", "light"];
export const tokenIconCircles: ICircle["type"][] = [
  EthereumIcon,
  AvalancheIcon,
  ArbitrumIcon,
  SolanaIcon,
  BscIcon,
];
export const allChainsInfo = {
  id: "ALL_CHAINS",
  name: "All Chains",
  Icon: AllChainsIcon,
};
export const allChainIconProps: Record<"sm" | "lg", AllChainsIconProps> = {
  sm: { sm: true, circles: [...tokenIconCircles, "dark"], overlapRight: true },
  lg: { circles: [...tokenIconCircles, "dark"], overlapRight: true },
};

export const isAllChains = (id: string) => id === "ALL_CHAINS";
export const formatChainName = (name: string) =>
  `${name} ${!name.includes("Chain") ? "Chain" : ""}`;
