export const ChangeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="14.5833"
        cy="14.5833"
        r="2.08333"
        stroke="white"
        strokeWidth="1.8"
      />
      <circle
        cx="5.41671"
        cy="5.41671"
        r="2.08333"
        stroke="white"
        strokeWidth="1.8"
      />
      <path
        d="M2.08337 13.3333L4.58337 11.25V16.6667H10.4167"
        stroke="white"
        strokeWidth="1.8"
      />
      <path
        d="M17.9166 6.66667L15.4166 8.75V3.33333H9.58329"
        stroke="white"
        strokeWidth="1.8"
      />
    </svg>
  );
};
