"use client";
import { GlobalStyle } from "@/globalStyle";
import {
  DrawerProvider,
  ModalProvider,
  ResizeProvider,
  UIKitProvider,
} from "@aperture/uikit";
import { ModalV2Provider, defaultTheme, lightTheme } from "@aperture/uikitv2";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "next-themes";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { SWRConfig } from "swr";
import { WagmiProvider } from "wagmi";
import { URLContextProvider } from "./components/Context/URLContext";
import { GlobalStoreProvider } from "./components/GlobalStore";
import { NetworkProvider } from "./components/NetworkContext/NetworkContext";
import { PageProvider } from "./components/PageContext/PageContext";
import { SnackbarProvider } from "./components/SnackbarContext/SnackbarContext";
import { SnackbarV2Provider } from "./components/SnackbarContext/SnackbarContextV2";
import { useWagmiConfig } from "./utils/wagmi";

type ITheme = "light" | "dark";
interface IThemeContext {
  theme: ITheme;
  toggleTheme: () => void;
  setThemeStatic: Dispatch<SetStateAction<boolean>>;
}

const ThemeContext = createContext<IThemeContext>({
  theme: "dark",
  toggleTheme: () => {},
  setThemeStatic: () => {},
});

export const useTheme = () => useContext(ThemeContext);
const StyledUIKitProvider: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  const [theme, setTheme] = useState<ITheme>("dark");
  const [themeStatic, setThemeStatic] = useState<boolean>(false);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
  };

  const currentTheme =
    theme === "dark" || themeStatic ? defaultTheme : lightTheme;

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, setThemeStatic }}>
      <UIKitProvider theme={currentTheme} {...props}>
        <GlobalStyle />
        {children}
      </UIKitProvider>
    </ThemeContext.Provider>
  );
};
const queryClient = new QueryClient();
const Providers: React.FC<
  React.PropsWithChildren<{
    children: React.ReactNode;
  }>
> = ({ children }) => {
  const wagmiConfig = useWagmiConfig();
  return (
    <>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <URLContextProvider>
            <NetworkProvider>
              <ThemeProvider defaultTheme="light">
                <ResizeProvider>
                  <StyledUIKitProvider>
                    <SWRConfig>
                      <SnackbarProvider>
                        <SnackbarV2Provider>
                          <PageProvider>
                            <GlobalStoreProvider>
                              {/* modal / drawer might consume global store; and global store is consuming Snackbar currently */}
                              <ModalProvider>
                                <ModalV2Provider>
                                  <DrawerProvider>{children}</DrawerProvider>
                                </ModalV2Provider>
                              </ModalProvider>
                            </GlobalStoreProvider>
                          </PageProvider>
                        </SnackbarV2Provider>
                      </SnackbarProvider>
                    </SWRConfig>
                  </StyledUIKitProvider>
                </ResizeProvider>
              </ThemeProvider>
            </NetworkProvider>
          </URLContextProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </>
  );
};

export default Providers;
