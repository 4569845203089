export const ChartIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_chart_clip0)`}>
        <path
          d="M7.53125 7.53125V2.33087H6.01125V7.53125H5.07375V0.9375H3.55375V7.53125H2.61625V4.41213H1.09625V7.53125H0V8.46875H8.46875V13.6691H9.98875V8.46875H10.9263V15.0625H12.4462V8.46875H13.3837V11.5879H14.9038V8.46875H16V7.53125H7.53125Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={`${props.id}_chart_clip0`}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
