import { CopyFileIcon, InRangeIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@uiv2/common";
import { shortenAddress } from "@uiv2/utils";
import { useCopyText } from "@uiv2/utils/hooks";
import { formatUSDAmount } from "@uiv2/utils/numberFormat";
import { FC } from "react";
import styled from "styled-components";
import { Avatar } from "../Avatar";
import { LinearLoader } from "../Loader";
import { T1, T3Regular, T4 } from "../Typography";

const Label = styled(T3Regular)`
  line-height: 18px;
  color: ${({ theme }) => theme.colors.global.text.T3};
`;
const Value = styled(T1)`
  font-weight: 400;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.global.success};
`;
const Address = styled(T4)`
  color: ${({ theme }) => theme.colors.global.text.T3};
`;
const ID = styled(T1)`
  font-size: 20px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Wrapper = styled.div`
  cursor: pointer;
  > svg {
    height: 14px;
    width: 14px;
    path {
      fill: ${({ theme }) => theme.colors.global.text.T3};
    }
  }
`;

interface IUserStats {
  assetsValue: string;
  totalPnL: string;
  poolsPnL: string;
  totalFees: string;
  // stakingRewards: string;
}
interface IProps extends IUserStats {
  address: `0x${string}`;
  userID?: string;
  loading: boolean;
}

export const ExplorerHeader: FC<IProps> = ({
  address,
  assetsValue,
  totalPnL,
  poolsPnL,
  totalFees,
  // stakingRewards,
  userID,
  loading,
}) => {
  const { hasCopied, handleCopyAddress } = useCopyText(address);

  const stats = {
    "Assets Value": assetsValue,
    "Total PnL": totalPnL,
    "PnL from Pools": poolsPnL,
    "Total Fees": totalFees,
    // "Staking Rewards": stakingRewards,
  };
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      style={{ width: "100%" }}
    >
      <Flex gap="lg" alignItems="center">
        <Avatar text={address} size={48} />
        <FlexColumn gap="lg">
          {userID && <ID>{userID}</ID>}
          <Flex gap="sm" alignItems="center" style={{ height: 14 }}>
            <Address>{shortenAddress(address, 4, 4)}</Address>
            <Wrapper onClick={handleCopyAddress}>
              {hasCopied ? <InRangeIcon /> : <CopyFileIcon />}
            </Wrapper>
          </Flex>
        </FlexColumn>
      </Flex>
      <Flex gap={40}>
        {Object.entries(stats).map(([label, value], idx) => (
          <FlexColumn key={label} gap="sm">
            <Label>{label}</Label>
            {loading ? (
              <LinearLoader size="100px" height="24px" />
            ) : (
              <Value
                textAlign={
                  idx === Object.keys(stats).length - 1 ? "right" : "left"
                }
              >
                {formatUSDAmount(value)}
              </Value>
            )}
          </FlexColumn>
        ))}
      </Flex>
    </Flex>
  );
};
