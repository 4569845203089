import { Identify, identify, init } from "@amplitude/analytics-node";
import { getLogger } from "@aperture_finance/uniswap-v3-automation-sdk";
import { datadogLogs } from "@datadog/browser-logs";
import {
  ConnectionType,
  WalletClient,
  WalletConnectSubtype,
  walletClientName,
} from "@ui/utils";
import { useFlagsmith } from "flagsmith/react";
import { useEffect, useState } from "react";
import { useAccount, useAccountEffect, useDisconnect } from "wagmi";

export const useConnectStatus = () => {
  // Initialize Amplitude client.
  init("f034732aab9e8351ba1de24608501767");

  const { isConnected, address, chain, connector } = useAccount();
  const { disconnect } = useDisconnect();
  const [connectType, setConnectType] = useState(ConnectionType.NONE);
  const [walletConnectSubtype, setWalletConnectSubtype] =
    useState<WalletConnectSubtype>(undefined);
  const [ready, setReady] = useState(false);
  const flagsmith = useFlagsmith();

  useAccountEffect({
    async onConnect({ connector }) {
      (async () => {
        const provider = await connector.getProvider();
        setReady(!!provider);
      })();
    },
    onDisconnect() {
      setConnectType(ConnectionType.NONE);
      setWalletConnectSubtype(undefined);
    },
  });

  useEffect(() => {
    const getProviderStatus = async () => {
      try {
        if (connector?.getProvider) {
          const provider = await connector.getProvider();
          setReady(!!provider);
        }
      } catch (error) {
        console.log("Error in getProvide", error);
        setReady(false);
      }
    };
    if (connector && connector.id) {
      setConnectType(connector.id as ConnectionType);
      // Update provider status for connector
      getProviderStatus();
      if (connector.id === ConnectionType.WALLET_CONNECT)
        (async () => {
          try {
            const provider = await connector.getProvider();
            if (!provider) return;
            //@ts-ignore
            const walletName = provider.session.peer.metadata.name as string;
            const connectSubtype = getWalletConnectSubtype(walletName);
            setWalletConnectSubtype(connectSubtype);
          } catch (error) {
            console.log("Error in getProvider", error);
          }
        })();
      else setWalletConnectSubtype(undefined);
    } else disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connector]);

  // Identify user with chain id and wallet address on connect/disconnect,
  // chain change or wallet change.
  useEffect(() => {
    // Make sure connectType and walletConnectSubtype has been set correctly
    if (
      connectType === ConnectionType.NONE ||
      (connectType === ConnectionType.WALLET_CONNECT && !walletConnectSubtype)
    )
      return;
    if (isConnected && !!chain?.id) {
      // Identify user here.
      const event = new Identify();
      event.set("chain", chain.id);
      event.set("wallet", address);
      identify(event, { user_id: address });

      datadogLogs.setUser({
        id: address,
        chain: chain.id,
      });

      flagsmith.identify(address).then(() => {
        datadogLogs.setGlobalContextProperty("flags", flagsmith.getAllFlags());
        getLogger().info("User connected", {
          chain: chain.id,
          wallet: address,
          connectType,
          walletConnectSubtype,
        });
      });

      // Send wallet connection event.
      // Doing Amplitude tract here often gets ad blocked, so fix by fetching api to track instead.
      fetch("/api/track/wallet", {
        method: "POST",
        body: JSON.stringify({ chainId: chain.id, address }),
      }).catch((error) => {
        console.log("Error in tracking wallet", error);
      });
    }
  }, [
    chain,
    isConnected,
    address,
    connectType,
    walletConnectSubtype,
    flagsmith,
  ]);

  useEffect(() => {
    if (!address) {
      flagsmith.logout();
      datadogLogs.clearUser();
    }
  }, [address, flagsmith]);

  return {
    connectType,
    setConnectType,
    disconnect,
    walletClient: walletClientName[connectType] as WalletClient,
    walletConnectSubtype,
    isProviderReady: ready,
  };
};

function getWalletConnectSubtype(walletName: string) {
  const wallet = walletName?.toLowerCase();
  if (wallet.indexOf("halo") > -1) return WalletConnectSubtype.HALO;
  else if (wallet.indexOf("metamask") > -1)
    return WalletConnectSubtype.METAMASK;
  else if (wallet.indexOf("safe") > -1) return WalletConnectSubtype.SAFE;
  else if (wallet.indexOf("bybit") > -1) return WalletConnectSubtype.BYBIT;
  else if (wallet.indexOf("binance") > -1) return WalletConnectSubtype.BINANCE;
  return WalletConnectSubtype.UNKNOWN;
}
