export const PolygonIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id={`${props.id}_polygon`}
      x="0px"
      y="0px"
      viewBox="0 0 2500 2214"
      {...props}
    >
      <g id={`${props.id}_polygon_layer`}>
        <linearGradient
          id={`${props.id}_polygon_svg1`}
          gradientUnits="userSpaceOnUse"
          x1="209.7147"
          y1="350.5264"
          x2="2362.7148"
          y2="1912.5264"
          gradientTransform="matrix(1 0 0 -1 0 2211.8899)"
        >
          <stop offset="0" stopColor="#A726C1"></stop>
          <stop offset="0.8784" stopColor="#803BDF"></stop>
          <stop offset="1" stopColor="#7B3FE4"></stop>
        </linearGradient>
        <path
          fill={`url(#${props.id}_polygon_svg1)`}
          d="M1856,1559l593-342c31-18,51-52,51-88V444c0-36-20-70-51-88L1856,14c-31-18-70-18-102,0l-593,342   c-31,18-51,52-51,88v1224l-416,240l-416-240v-480l416-240l274,158V784L745,655c-15-9-33-14-51-14s-35,5-51,14L50,997   c-31,18-51,52-51,88v685c0,36,20,70,51,88l593,342c31,18,70,18,102,0l593-342c31-18,51-52,51-88V546l8-4l408-236l416,240v480   l-416,240l-274-158v322l223,129C1785,1577,1824,1577,1856,1559L1856,1559z"
        ></path>
      </g>
    </svg>
  );
};
