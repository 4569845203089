export const StockMarketIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_stock_market_clip0)`}>
        <path
          d="M8.52271 10.1552C8.52271 9.95775 8.36201 9.78125 8.16452 9.78125H7.47852V10.5291H8.16449C8.36201 10.5291 8.52271 10.3526 8.52271 10.1552Z"
          fill="white"
        />
        <path
          d="M7.47852 11.7544H8.16449C8.36201 11.7544 8.52268 11.6079 8.52268 11.4104C8.52268 11.213 8.36198 11.0664 8.16449 11.0664H7.47852V11.7544Z"
          fill="white"
        />
        <path
          d="M13.903 11.6504L12.9964 10.6082L12.4001 11.1265L12.7391 11.5162L10.8307 11.5177C10.8848 11.291 10.9135 11.0544 10.9135 10.8112C10.9135 9.13322 9.55276 7.773 7.87416 7.773C6.19557 7.773 4.83479 9.13322 4.83479 10.8112C4.83479 11.0567 4.86413 11.2954 4.91915 11.524L1.73723 11.5265L1.73139 1.5471L2.11451 1.95867L2.69303 1.42056L1.62496 0.273162C1.46889 0.105521 1.19739 0.107076 1.04327 0.276602L0 1.42424L0.584829 1.95547L0.941141 1.5635L0.947184 11.9255C0.947333 12.1393 1.12903 12.3237 1.34235 12.3237H1.34271L5.23415 12.3174C5.75783 13.2326 6.7439 13.8493 7.87416 13.8493C9.00834 13.8493 9.99729 13.2282 10.5196 12.3078L12.6933 12.3062L12.3187 12.7069L12.8961 13.2462L13.8936 12.1792C14.0318 12.0314 14.0358 11.8031 13.903 11.6504ZM6.52137 11.0673H5.98291V10.5288H6.52137V11.0673ZM9.08361 11.4325C9.08361 11.8023 8.8547 12.1208 8.55556 12.2611V12.6228H8.01709V12.3237H7.71795V12.6228H7.17949V12.3237H6.73077V11.7553H6.94017V10.801V9.78093H6.73077V9.24247H7.17949V8.97324H7.71795V9.24247H8.01709V8.97324H8.55556V9.341C8.8547 9.48124 9.08361 9.79974 9.08361 10.1695C9.08361 10.415 8.98516 10.6378 8.82586 10.801C8.98519 10.9642 9.08361 11.187 9.08361 11.4325ZM9.90171 11.0673H9.36325V10.5288H9.90171V11.0673Z"
          fill="white"
        />
        <path
          d="M12.7734 2.1238H12.1751V1.04688H11.3674V2.1238H10.7991V4.81611H11.3674V5.89303H12.1751V4.81611H12.7734V2.1238Z"
          fill="white"
        />
        <path
          d="M8.25632 2.00391H7.44863V3.08083H6.85034V5.77314H7.44863V6.87997H8.25632V5.77314H8.8247V3.08083H8.25632V2.00391Z"
          fill="white"
        />
        <path
          d="M4.36741 3.62109H3.55972V4.69802H2.96143V7.39032H3.55972V8.49716H4.36741V7.39032H4.93578V4.69802H4.36741V3.62109Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={`${props.id}_stock_market_clip0`}>
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
