import { useIsExplorerEnabled } from "@/config/feature_flags";
import {
  AmmInfo,
  E_AMM,
  generateNavMap,
  generateSelectorMap,
} from "@aperture/uikit";
import { NavLinkProps } from "@ui/components/Nav/NavTabs/types";

export const useMenu = () => {
  const isExplorerEnabled = useIsExplorerEnabled();

  const items = [
    {
      text: "Stake",
      link: "stake",
    },
    {
      text: "Airdrop Dashboard",
      link: "claim",
    },
  ];
  const moreMenuItem = generateNavMap(
    isExplorerEnabled
      ? [
          ...items,
          {
            text: "Explorer",
            link: "explorer",
          },
        ]
      : items
  );

  const liquiditySupportedAmm = [
    AmmInfo[E_AMM.Uniswap],
    AmmInfo[E_AMM.PancakeSwap],
    AmmInfo[E_AMM.ApertureSwap],
    AmmInfo[E_AMM.Aerodrome],
    AmmInfo[E_AMM.Velodrome],
  ];
  const liquiditySelector = generateSelectorMap(
    liquiditySupportedAmm,
    "liquidity-management"
  );
  const strategySupportedAmm = [
    AmmInfo[E_AMM.Uniswap],
    AmmInfo[E_AMM.PancakeSwap],
    AmmInfo[E_AMM.Aerodrome],
    AmmInfo[E_AMM.Velodrome],
  ];

  const strategySelector = generateSelectorMap(
    strategySupportedAmm,
    "recurring-rebalance"
  );
  const navLinks: NavLinkProps[] = [
    { text: "Home", link: "" },
    {
      text: "IntentsGPT",
      link: "intents-gpt",
      highlight: "true",
    },
    { text: "Stake", link: "stake" },
    { text: "Swap", link: "swap" },
    {
      text: "Liquidity Positions",
      link: "liquidity-management",
      hasSubMenu: true,
      menuItems: liquiditySelector,
    },
    {
      text: "Strategy",
      link: "recurring-rebalance",
      hasSubMenu: true,
      menuItems: strategySelector,
    },
    { text: "Limit Orders", link: "limit-orders" },
    { text: "Airdrop Dashboard", link: "claim" },
    { text: "Calculator", link: "calculator" },
  ];

  const hiddenLinks = ["Home", "Stake", "Airdrop Dashboard"];

  const filteredNavLinks = navLinks.filter(
    (navLink) => !hiddenLinks.includes(navLink.text)
  );

  const menuData = {
    navLinks: filteredNavLinks,
    moreMenuItem: moreMenuItem,
  };

  return {
    navLinks: menuData.navLinks,
    moreMenuItem: menuData.moreMenuItem,
  };
};
