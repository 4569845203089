import { useEventCallback } from "@/hooks/useEventCallback";
import { IPRestriction } from "@/hooks/useUserIPRestriction";
import { getNetworkId } from "@/utils/networkHelper";
import {
  BasicInfo,
  ConnectModal,
  ConnectionType,
  IpRestrictContent,
  SupportedChainId,
  restrictedCountries,
  useModal,
} from "@aperture/uikit";
import { useRouter } from "next/router";
import { useRef } from "react";
import { useConnect } from "wagmi";
import { useConnectStore, useSolverRoutingStore } from "../GlobalStore";
import { OrderRoutingModal } from "../Modal/OrderRoutingModal";
import { useNetwork } from "../NetworkContext/NetworkContext";
import { useSnackbar } from "../SnackbarContext/SnackbarContext";

export const useShowConnectModal = () => {
  const { addSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const { amm, network, setNetwork } = useNetwork();
  const { connectType } = useConnectStore();
  const { connect, connectors } = useConnect();

  const showConnectModal = () => {
    showModal(ConnectModal, {
      defaultAMM: amm,
      defaultChain: network,
      currentConnectType: connectType,
      chainSelect: (amm: BasicInfo, chain: SupportedChainId) =>
        setNetwork(amm, chain),
      walletSelect: (chain: SupportedChainId, walletId: ConnectionType) => {
        const connector = connectors.find(
          (connector) => connector.id === walletId
        );
        if (connector)
          connect(
            { chainId: getNetworkId(chain), connector },
            {
              onError: (error, { chainId }) => {
                console.log(
                  `Failed to connect chain ${chainId}`,
                  error.message
                );
                addSnackbar(
                  "Your connect network request has failed",
                  "failed"
                );
              },
            }
          );
      },
    });
  };

  return {
    showConnectModal,
  };
};

export const useToggleIPRestrictModal = () => {
  const { showModal, hideModal } = useModal();
  const router = useRouter();

  const timerRef = useRef(null);
  const toggleIPRestrictionModal = useEventCallback(
    (userIpInfo: IPRestriction) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (!!userIpInfo && userIpInfo?.isRestricted) {
        // open modal after 500ms to avoid flickering
        timerRef.current = setTimeout(() => {
          showModal(IpRestrictContent, {
            list: Object.keys(restrictedCountries),
            handleClose: () => {
              router.push("/");
              hideModal();
            },
            closeIcon: false,
            disableBackgroundOnClose: true,
          });
        }, 500);
      } else {
        hideModal();
      }
    }
  );

  return {
    toggleIPRestrictionModal,
  };
};

export const useOrderRoutingModal = () => {
  const { showModal, hideModal } = useModal();
  const { setSelectedSolver } = useSolverRoutingStore();

  const handleChangeAPI = () => {
    showModal(OrderRoutingModal, {
      onSelect: (idx: number) => {
        setSelectedSolver(idx);
        hideModal();
      },
      onClose: () => {
        hideModal();
      },
    });
  };

  return {
    handleChangeAPI,
  };
};
