import { TokenPriceMap } from "@/hooks/useFetchAllChainsTokensPrice";
import { CHAIN_USDC_ADDRESS } from "@/utils/networkHelper";
import {
  ApertureSupportedChainId,
  getLogger,
  getTokenPriceListFromCoingeckoWithAddresses,
  viem,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { Token } from "@uniswap/sdk-core";
import bigDecimal from "js-big-decimal";
import _ from "lodash";
import { parseUnits } from "viem";

export const fetchTokenPrices = async (
  chainId: ApertureSupportedChainId,
  tokens: Token[],
  defaultOkxTokens: string[] = []
) => {
  // Get token map and addresses
  const tokenAddresses = tokens.map((token) => token.address);
  // Fetch prices from Coingecko
  const coingeckoPrices = await fetchCoingeckoPrices(chainId, tokenAddresses);

  // Initialize price map and track tokens without prices
  const noPriceTokens = new Map<string, Token>();
  const priceMap: TokenPriceMap = {};

  // Process each token
  for (const token of tokens) {
    const coingeckoPrice =
      coingeckoPrices[token.address.toLowerCase()]?.toString() ?? "";

    priceMap[token.address] = { price: coingeckoPrice };

    // Track tokens that need routing API prices
    if (
      (!coingeckoPrice && Object.keys(coingeckoPrices).length) ||
      !token.address
    ) {
      noPriceTokens.set(token.address, token);
    }
  }

  // Get missing prices from routing API
  if (noPriceTokens.size) {
    const routingApiPrices = await getTokenPriceMapFromRoutingApi(
      chainId,
      noPriceTokens,
      defaultOkxTokens
    );

    // Update price map with routing API prices
    for (const [address, price] of Object.entries(routingApiPrices)) {
      if (price.price) {
        priceMap[address] = price;
      }
    }
  }

  return priceMap;
};

async function fetchCoingeckoPrices(
  chainId: ApertureSupportedChainId,
  tokenAddresses: string[]
) {
  const tokenChunks = _.chunk(tokenAddresses, 150);
  try {
    const prices = await Promise.all(
      tokenChunks.map((chunk) =>
        getTokenPriceListFromCoingeckoWithAddresses(chainId, chunk)
      )
    );

    return Object.assign({}, ...prices);
  } catch {
    return {};
  }
}

async function getTokenPriceMapFromRoutingApi(
  chainId: ApertureSupportedChainId,
  tokenMap: Map<string, Token>,
  defaultOkxTokens: string[] = []
): Promise<TokenPriceMap> {
  // Get prices for all tokens in parallel
  const tokenPrices = await Promise.all(
    Array.from(tokenMap.entries()).map(async ([address, token]) => ({
      address,
      price: await getTokenPriceFromRoutingApi(
        chainId,
        token,
        defaultOkxTokens.includes(address)
      ),
    }))
  );

  // Convert to final format
  return tokenPrices.reduce((priceMap, { address, price }) => {
    priceMap[address] = { price };
    return priceMap;
  }, {} as TokenPriceMap);
}

export async function getTokenPriceFromRoutingApi(
  chainId: ApertureSupportedChainId,
  token: Token,
  defaultOkxQuote: boolean = false
) {
  const usdcAddress = CHAIN_USDC_ADDRESS[chainId].id;
  const usdcDecimals = CHAIN_USDC_ADDRESS[chainId].decimals;
  if (token.address === usdcAddress) {
    return "1";
  }
  let price: string = "";
  const rawAmount = parseUnits("0.01", usdcDecimals);
  let quoteDecimals = "0";
  try {
    if (defaultOkxQuote) throw new Error("Should quote from OKX.");
    // How many token can exact out 0.01 USDC
    const quote = await viem.fetchQuoteFromRoutingApi(
      chainId,
      token.address,
      usdcAddress,
      rawAmount,
      "exactOut"
    );
    quoteDecimals = quote.quoteDecimals;
  } catch (error) {
    // fallback to get price from 1inch
    quoteDecimals = await getOkxQuotePrice(
      chainId,
      token,
      rawAmount,
      usdcAddress
    );
  } finally {
    price =
      bigDecimal.compareTo(quoteDecimals, 0) === 0
        ? ""
        : bigDecimal.divide(
            1,
            bigDecimal.multiply(quoteDecimals, 100),
            token.decimals
          );
    return price;
  }
}

const getOkxQuotePrice = async (
  chainId: ApertureSupportedChainId,
  token: Token,
  rawAmount: bigint,
  usdcAddress: string
) => {
  try {
    const quote = await viem.getOkxQuote(
      chainId,
      usdcAddress,
      token.address,
      rawAmount.toString()
    );

    return bigDecimal.divide(
      quote.toAmount,
      Math.pow(10, token.decimals),
      token.decimals
    );
  } catch (error) {
    getLogger().error("TokenPrice.GetOkxQuote.Error", {
      chainId,
      token: token.address,
      error: (error as Error).message,
    });
    return "0";
  }
};

export async function getTokenPrice(
  chainId: ApertureSupportedChainId,
  token: Token,
  defaultOkxQuote: boolean = false
): Promise<TokenPriceMap> {
  let price = "";
  const priceMapKey = token.address;
  if (priceMapKey) {
    const priceMap = await getTokenPriceListFromCoingeckoWithAddresses(
      chainId,
      [priceMapKey]
    );
    price = priceMap?.[priceMapKey.toLowerCase()]?.toString();
  }
  if (!price) {
    price = await getTokenPriceFromRoutingApi(chainId, token, defaultOkxQuote);
  }
  return {
    [token.address]: {
      price: price,
    },
  };
}
