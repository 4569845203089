export const ThemeIcon: React.FC<
  React.SVGProps<SVGSVGElement> & { theme?: string }
> = (props) => {
  if (props.theme === "dark") {
    return (
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.66683 10.5853C7.34873 10.5853 10.3335 7.60053 10.3335 3.91863C10.3335 3.35555 10.2637 2.80876 10.1323 2.28647C12.5811 3.16179 14.3335 5.50224 14.3335 8.25197C14.3335 11.7498 11.498 14.5853 8.00017 14.5853C5.25044 14.5853 2.90999 12.8329 2.03467 10.3841C2.55696 10.5155 3.10375 10.5853 3.66683 10.5853Z"
          fill={props.fill || "#FFFFFF"}
        />
      </svg>
    );
  }
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.74992 0.333344V2.33334H6.24992V0.333344H7.74992ZM7.74992 11.6667V13.6667H6.24992V11.6667H7.74992ZM2.81622 1.75561L4.23043 3.16982L3.16977 4.23048L1.75556 2.81627L2.81622 1.75561ZM10.8301 9.76948L12.2443 11.1837L11.1836 12.2444L9.76939 10.8301L10.8301 9.76948ZM2.33325 6.24997H0.333252V7.74997H2.33325V6.24997ZM13.6666 6.24997H11.6666V7.74997H13.6666V6.24997ZM1.75546 11.1837L3.16967 9.7695L4.23033 10.8302L2.81612 12.2444L1.75546 11.1837ZM9.76937 3.16984L11.1836 1.75562L12.2442 2.81628L10.83 4.2305L9.76937 3.16984ZM4.4999 6.99999C4.4999 8.3807 5.61919 9.49999 6.9999 9.49999C8.38061 9.49999 9.4999 8.3807 9.4999 6.99999C9.4999 5.61928 8.38061 4.49999 6.9999 4.49999C5.61919 4.49999 4.4999 5.61928 4.4999 6.99999ZM2.9999 6.99999C2.9999 9.20913 4.79076 11 6.9999 11C9.20904 11 10.9999 9.20913 10.9999 6.99999C10.9999 4.79085 9.20904 2.99999 6.9999 2.99999C4.79076 2.99999 2.9999 4.79085 2.9999 6.99999Z"
        fill={props.fill || "#FFFFFF"}
      />
    </svg>
  );
};
