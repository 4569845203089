import {
  AvalancheIcon,
  BscIcon,
  EthereumIcon,
  MaticIcon,
} from "@aperture/assetkit";
import { TokenIcon } from "@aperture/uikit";
import { ApertureSupportedChainId } from "@aperture_finance/uniswap-v3-automation-sdk";
import { tokenIcons } from "./tokenIconMap";

export type TokenIconMap = {
  [chainId: number]: IconSrcType;
};

export interface IconSrcType {
  [address: string]: string;
}

export const getTokenIcon = (
  address: string,
  ticker: string,
  chainId: number,
  defaultSrc?: string
) => {
  return (
    <TokenIcon
      src={tokenIcons?.[chainId][address.toLowerCase()] ?? defaultSrc}
      alt={ticker ?? "Unknown"}
    />
  );
};

export const getNativeIcon = (chainId: number) => {
  switch (chainId) {
    case ApertureSupportedChainId.POLYGON_MAINNET_CHAIN_ID:
      return (
        <div>
          <MaticIcon height="100%" width="100%" />
        </div>
      );
    case ApertureSupportedChainId.AVALANCHE_MAINNET_CHAIN_ID:
      return (
        <div>
          <AvalancheIcon height="100%" width="100%" />
        </div>
      );
    case ApertureSupportedChainId.BNB_MAINNET_CHAIN_ID:
      return (
        <div>
          <BscIcon height="100%" width="100%" />
        </div>
      );
    default:
      return (
        <div>
          <EthereumIcon height="100%" width="100%" />
        </div>
      );
  }
};
