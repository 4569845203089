export const InfoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill={props.fill || "white"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.66667 1.33333C5.25218 1.33333 3.89562 1.89524 2.89543 2.89543C1.89524 3.89562 1.33333 5.25218 1.33333 6.66667C1.33333 8.08115 1.89524 9.43771 2.89543 10.4379C3.89562 11.4381 5.25218 12 6.66667 12C8.08115 12 9.43771 11.4381 10.4379 10.4379C11.4381 9.43771 12 8.08115 12 6.66667C12 5.25218 11.4381 3.89562 10.4379 2.89543C9.43771 1.89524 8.08115 1.33333 6.66667 1.33333ZM0 6.66667C0 2.98467 2.98467 0 6.66667 0C10.3487 0 13.3333 2.98467 13.3333 6.66667C13.3333 10.3487 10.3487 13.3333 6.66667 13.3333C2.98467 13.3333 0 10.3487 0 6.66667Z" />
      <path d="M6.6665 5.33268C6.84331 5.33268 7.01288 5.40292 7.13791 5.52794C7.26293 5.65297 7.33317 5.82254 7.33317 5.99935V9.99935C7.33317 10.1762 7.26293 10.3457 7.13791 10.4708C7.01288 10.5958 6.84331 10.666 6.6665 10.666C6.48969 10.666 6.32012 10.5958 6.1951 10.4708C6.07008 10.3457 5.99984 10.1762 5.99984 9.99935V5.99935C5.99984 5.82254 6.07008 5.65297 6.1951 5.52794C6.32012 5.40292 6.48969 5.33268 6.6665 5.33268ZM7.6665 3.66602C7.6665 3.93123 7.56115 4.18559 7.37361 4.37312C7.18607 4.56066 6.93172 4.66602 6.6665 4.66602C6.40129 4.66602 6.14693 4.56066 5.9594 4.37312C5.77186 4.18559 5.6665 3.93123 5.6665 3.66602C5.6665 3.4008 5.77186 3.14645 5.9594 2.95891C6.14693 2.77137 6.40129 2.66602 6.6665 2.66602C6.93172 2.66602 7.18607 2.77137 7.37361 2.95891C7.56115 3.14645 7.6665 3.4008 7.6665 3.66602Z" />
    </svg>
  );
};
