import {
  AerodromeIcon,
  ApertureLogo2,
  ArbitrumIcon,
  AvalancheIcon,
  BaseIcon,
  BscIcon,
  EthereumIcon,
  MantaIcon,
  OptimismIcon,
  PancakeIcon,
  PolygonIcon,
  ScrollIcon,
  UniswapIcon,
  VelodromeIcon,
} from "@aperture/assetkit";
import { FeeAmount } from "@aperture_finance/uniswap-v3-sdk";

export enum SupportedChainId {
  ETHEREUM = "ETHEREUM",
  ARBITRUM = "ARBITRUM",
  MANTA = "MANTA PACIFIC",
  OPTIMISM = "OPTIMISM",
  POLYGON = "POLYGON",
  BASE = "BASE",
  BNB = "BNB",
  AVALANCHE = "AVALANCHE",
  SCROLL = "SCROLL",
}
export interface BasicInfo {
  Icon: any;
  name: string;
}

export interface NetworkInfo extends BasicInfo {
  id: SupportedChainId;
  amm: BasicInfo[];
}

export type AmmEnum = "UNISWAP_V3" | "PANCAKESWAP_V3" | "SLIPSTREAM";
export enum E_AMM {
  Uniswap = "Uniswap",
  PancakeSwap = "PancakeSwap",
  ApertureSwap = "ApertureSwap",
  Aerodrome = "Aerodrome",
  Velodrome = "Velodrome",
}
export type Calculator_AMM = Exclude<
  Exclude<E_AMM, E_AMM.Aerodrome>,
  E_AMM.Velodrome
>;

export const AmmInfo: Record<E_AMM, BasicInfo> = {
  [E_AMM.Uniswap]: { name: E_AMM.Uniswap, Icon: UniswapIcon },
  [E_AMM.PancakeSwap]: { name: E_AMM.PancakeSwap, Icon: PancakeIcon },
  [E_AMM.ApertureSwap]: { name: E_AMM.ApertureSwap, Icon: ApertureLogo2 },
  [E_AMM.Aerodrome]: { name: E_AMM.Aerodrome, Icon: AerodromeIcon },
  [E_AMM.Velodrome]: { name: E_AMM.Velodrome, Icon: VelodromeIcon },
};

export const CalculatorAmmInfo: Record<Calculator_AMM, BasicInfo> = {
  [E_AMM.Uniswap]: AmmInfo[E_AMM.Uniswap],
  [E_AMM.PancakeSwap]: AmmInfo[E_AMM.PancakeSwap],
  [E_AMM.ApertureSwap]: AmmInfo[E_AMM.ApertureSwap],
};
export const ChainList: NetworkInfo[] = [
  {
    id: SupportedChainId.ETHEREUM,
    name: "Ethereum",
    Icon: EthereumIcon,
    amm: [AmmInfo.Uniswap, AmmInfo.PancakeSwap],
  },
  {
    id: SupportedChainId.ARBITRUM,
    name: "Arbitrum",
    Icon: ArbitrumIcon,
    amm: [AmmInfo.Uniswap],
  },
  {
    id: SupportedChainId.OPTIMISM,
    name: "Optimism",
    Icon: OptimismIcon,
    amm: [AmmInfo.Uniswap, AmmInfo.Velodrome],
  },
  {
    id: SupportedChainId.POLYGON,
    name: "Polygon",
    Icon: PolygonIcon,
    amm: [AmmInfo.Uniswap],
  },
  {
    id: SupportedChainId.BASE,
    name: "Base",
    Icon: BaseIcon,
    amm: [AmmInfo.Uniswap, AmmInfo.Aerodrome],
  },
  {
    id: SupportedChainId.BNB,
    name: "BNB Chain",
    Icon: BscIcon,
    amm: [AmmInfo.Uniswap, AmmInfo.PancakeSwap],
  },
  {
    id: SupportedChainId.AVALANCHE,
    name: "Avalanche",
    Icon: AvalancheIcon,
    amm: [AmmInfo.Uniswap],
  },
  {
    id: SupportedChainId.SCROLL,
    name: "Scroll",
    Icon: ScrollIcon,
    amm: [AmmInfo.Uniswap],
  },
  {
    id: SupportedChainId.MANTA,
    name: "Manta Pacific",
    Icon: MantaIcon,
    amm: [AmmInfo.ApertureSwap],
  },
];

export const SupportedFeeAmount: Record<AmmEnum, FeeAmount[]> = {
  UNISWAP_V3: [
    FeeAmount.LOWEST,
    FeeAmount.LOW,
    FeeAmount.MEDIUM,
    FeeAmount.HIGH,
  ],
  PANCAKESWAP_V3: [
    FeeAmount.LOWEST,
    FeeAmount.LOW,
    FeeAmount.PCS_V3_MEDIUM,
    FeeAmount.HIGH,
  ],
  SLIPSTREAM: [],
};
