export const QuestionMarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill={props.fill || "white"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM6.45078 9.525C6.30078 9.675 6.15078 9.75 5.92578 9.75C5.70078 9.75 5.55078 9.675 5.40078 9.525C5.25078 9.375 5.17578 9.225 5.17578 9C5.17578 8.775 5.25078 8.625 5.40078 8.475C5.55078 8.325 5.70078 8.25 5.92578 8.25C6.15078 8.25 6.30078 8.325 6.45078 8.475C6.60078 8.625 6.67578 8.775 6.67578 9C6.67578 9.225 6.60078 9.375 6.45078 9.525ZM7.50705 5.93909C7.70129 5.75951 7.82952 5.64096 7.875 5.55C8.1 5.175 8.25 4.8 8.25 4.275C8.25 3.6 8.025 3.15 7.65 2.775C7.275 2.4 6.75 2.25 6.075 2.25C5.4 2.25 4.8 2.475 4.425 2.925C3.975 3.375 3.75 3.975 3.75 4.725H4.875C4.875 4.275 4.95 3.9 5.175 3.675C5.325 3.375 5.625 3.225 6.075 3.225C6.375 3.225 6.675 3.375 6.9 3.525C7.125 3.75 7.2 4.05 7.2 4.35C7.2 4.65 7.05 4.875 6.9 5.1L6.75 5.25C6.075 5.85 5.7 6.225 5.625 6.6C5.475 6.825 5.4 7.2 5.4 7.575V7.725H6.525V7.575C6.525 7.35 6.6 7.125 6.675 6.9C6.75 6.675 6.9 6.525 7.05 6.375C7.22711 6.19789 7.38098 6.05564 7.50705 5.93909Z"
      />
    </svg>
  );
};
