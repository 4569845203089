export const EditIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={props.width || "20"}
      height={props.height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4262 1.86362L12.7894 1.22656L12.1529 1.86386L3.52991 10.4972L3.26669 10.7607V11.1332V14.1665V15.0665H4.16669H7.21552H7.58843L7.85206 14.8028L16.4699 6.18117L17.1059 5.54492L16.4699 4.90866L13.4262 1.86362ZM5.06669 11.5056L12.7899 3.77319L14.5608 5.54492L6.84261 13.2665H5.06669V11.5056ZM2.5 18.3999H17.5V16.5999H2.5V18.3999Z"
        fill={props.fill || "white"}
      />
    </svg>
  );
};
