export const ChevronIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path d="M6.00002 4L10 8L6.00002 12" stroke="white" strokeWidth="1.5" />
    </svg>
  );
};
