export const ArrowReturnLeftIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width={props.width || "20"}
      height={props.height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.02941 3.33317L2.6352 6.83024C2.55989 6.90783 2.55989 7.03124 2.6352 7.10883L6.02941 10.6059M6.47059 16.6665L12.2059 16.6665C15.1297 16.6665 17.5 14.4958 17.5 11.818C17.5 9.14027 15.1297 6.96953 12.2059 6.96953L2.67615 6.96953"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
