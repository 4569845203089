export const TransparentLogo: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.1891 112.113C67.7104 112.324 68.0253 112.861 67.9272 113.418L67.8962 113.599C67.7982 114.162 67.3079 114.575 66.735 114.575H8.59348C8.13413 114.575 7.71607 114.311 7.51994 113.898C2.69417 103.602 0 92.3812 0 79.9942C0 67.6072 2.81804 55.8809 7.83994 45.4138C20.7379 18.5444 48.2009 -0.00515914 79.9994 1.07635e-06C82.6316 1.07635e-06 85.2329 0.134195 87.8032 0.381935C89.4393 0.541933 91.0651 0.748383 92.6754 1.00128C93.3257 1.10451 93.7593 1.72902 93.6457 2.37418L86.2496 44.4435C86.1516 45.0061 85.6613 45.419 85.0884 45.419H79.9994H79.8858C60.8408 45.4809 45.419 60.9337 45.419 79.9942C45.419 94.5644 54.4357 107.024 67.1891 112.113ZM92.7406 45.4201H151.398H151.403C151.863 45.4201 152.281 45.6834 152.477 46.0963C157.303 56.393 159.997 67.6135 159.997 80.0005C159.997 92.3875 157.184 104.114 152.157 114.581C139.259 141.45 111.796 160 79.9975 160C77.1898 160 74.413 159.85 71.6827 159.566C70.217 159.412 68.7615 159.221 67.3163 158.993C66.666 158.89 66.2325 158.271 66.346 157.626L73.7421 115.556C73.8402 114.994 74.3305 114.581 74.9034 114.581H79.5898V114.571C79.724 114.576 79.8582 114.581 79.9924 114.581C99.089 114.581 114.573 99.0971 114.573 80.0005C114.573 65.2548 105.334 52.6769 92.3329 47.7066C91.8013 47.5001 91.4813 46.9582 91.5794 46.3956C91.6774 45.833 92.1677 45.4201 92.7406 45.4201Z"
        fill={props.fill || "white"}
      />
    </svg>
  );
};
