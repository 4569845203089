export const CheckMarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="8" cy="8" r="8" fill={props.fill ?? "#24FF00"} />
      <path
        d="M4.7998 7.73334L6.93314 9.86667L11.1998 5.60001"
        stroke={props.stroke ?? "#161616"}
        strokeWidth="2"
      />
    </svg>
  );
};
