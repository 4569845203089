import { SettingsIcon } from "@aperture/assetkit";
import { QuestionPopover } from "@aperture/uikit";
import { Flex } from "@ui/common";
import React, { useState } from "react";
import { Dropdown } from "../Dropdown";
import { Slippage } from "../Slippage";
import { RightButton, StyledInput } from "../Slippage/style";
import { Subtitle0 } from "../Typography";
import {
  Content,
  IconButton,
  IconWrapper,
  StyledButton,
  TransactionContainer,
} from "./style";
import { ITransactionSettingsProps } from "./types";

export const TransactionSettings: React.FC<ITransactionSettingsProps> = ({
  buttonSize = "large",
  settingsForm = { slippage: 0.005, deadline: "" },
  defaultSlippage = 0.005,
  globalSlippage,
  warningSlippage,
  timePlaceholder = "30",
  dropdownPosition,
  onFormChange,
  setGlobalSlippage,
  iconWidth = 40,
  iconHeight = 40,
  isBorderless = false,
  ...props
}) => {
  const [applyGlobally, setApplyGlobally] = useState(false);

  const dropdownBtn =
    buttonSize === "large" ? (
      <StyledButton variant="outlined" size="md" color="secondary">
        Change Settings
      </StyledButton>
    ) : (
      <IconButton
        isBorderless={isBorderless}
        iconWidth={iconWidth}
        iconHeight={iconHeight}
      >
        <IconWrapper iconWidth={iconWidth} iconHeight={iconHeight}>
          <SettingsIcon width={iconWidth / 2} height={iconHeight / 2} />
        </IconWrapper>
      </IconButton>
    );
  return (
    <Dropdown button={dropdownBtn} position={dropdownPosition} {...props}>
      <Content>
        <Slippage
          defaultValue={defaultSlippage}
          globalValue={globalSlippage}
          warningValue={warningSlippage}
          value={settingsForm.slippage}
          onSlippageChange={(percentage: number) =>
            onFormChange({ ...settingsForm, slippage: percentage })
          }
          applyGlobally={applyGlobally}
          onApplyGlobally={
            setGlobalSlippage
              ? (checked) => {
                  setApplyGlobally(checked);
                  checked && setGlobalSlippage?.(settingsForm.slippage);
                }
              : undefined
          }
        />
        <TransactionContainer>
          <Flex alignItems={"center"} gap="sm">
            <Subtitle0>Transaction Deadline</Subtitle0>
            <QuestionPopover content="Your transaction will revert if it is pending for more than this period of time." />
          </Flex>
          <Flex
            alignItems={"center"}
            gap={"md"}
            style={{ position: "relative" }}
          >
            <StyledInput
              align="left"
              type="number"
              placeholder={timePlaceholder}
              defaultValue={settingsForm.deadline}
              onChange={(value: string) =>
                onFormChange({ ...settingsForm, deadline: value })
              }
            />
            <RightButton isPrimary={true}>Minutes</RightButton>
          </Flex>
        </TransactionContainer>
      </Content>
    </Dropdown>
  );
};
