export const MoreIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="4"
      height="16"
      viewBox="0 0 4 16"
      fill={props.fill || "white"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1.99995 14.6109C1.51106 14.6109 1.09269 14.437 0.744835 14.0891C0.39639 13.7407 0.222168 13.322 0.222168 12.8331C0.222168 12.3442 0.39639 11.9256 0.744835 11.5771C1.09269 11.2293 1.51106 11.0553 1.99995 11.0553C2.48883 11.0553 2.9075 11.2293 3.25595 11.5771C3.6038 11.9256 3.77772 12.3442 3.77772 12.8331C3.77772 13.322 3.6038 13.7407 3.25595 14.0891C2.9075 14.437 2.48883 14.6109 1.99995 14.6109ZM1.99995 9.27756C1.51106 9.27756 1.09269 9.10334 0.744835 8.75489C0.39639 8.40704 0.222168 7.98867 0.222168 7.49978C0.222168 7.01089 0.39639 6.59223 0.744835 6.24378C1.09269 5.89593 1.51106 5.72201 1.99995 5.72201C2.48883 5.72201 2.9075 5.89593 3.25595 6.24378C3.6038 6.59223 3.77772 7.01089 3.77772 7.49978C3.77772 7.98867 3.6038 8.40704 3.25595 8.75489C2.9075 9.10334 2.48883 9.27756 1.99995 9.27756ZM1.99995 3.94423C1.51106 3.94423 1.09269 3.77001 0.744835 3.42156C0.39639 3.07371 0.222168 2.65534 0.222168 2.16645C0.222168 1.67756 0.39639 1.25919 0.744835 0.911338C1.09269 0.562894 1.51106 0.388672 1.99995 0.388672C2.48883 0.388672 2.9075 0.562894 3.25595 0.911338C3.6038 1.25919 3.77772 1.67756 3.77772 2.16645C3.77772 2.65534 3.6038 3.07371 3.25595 3.42156C2.9075 3.77001 2.48883 3.94423 1.99995 3.94423Z" />
    </svg>
  );
};
