import { useNetwork } from "@/components/NetworkContext/NetworkContext";
import { getTokenMap } from "@/utils/helper";
import { formatTokenAmount } from "@/utils/numberFormat";
import {
  ApertureSupportedChainId,
  IERC20__factory,
  viem,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import useSWR from "swr/immutable";
import { Address, formatUnits, PublicClient } from "viem";
import { useAccount, useBalance } from "wagmi";

export interface TokenBalance {
  balanceBN: bigint;
  balance: string;
}

export interface TokenBalanceMap {
  [address: string]: TokenBalance;
}

export function useFetchUserTokensBalance() {
  const { address, isConnected } = useAccount();
  const { isChainSupported, publicClient, networkId } = useNetwork();
  const { data: nativeBalance } = useBalance({
    address,
    query: { refetchInterval: 30_000 },
  });

  const showFetch = isConnected && address && isChainSupported;
  return useSWR(
    showFetch
      ? `user-tokens-balance-${networkId}-${address}-${formatTokenAmount(
          nativeBalance?.formatted ?? "0.0"
        )}`
      : null,
    () => getTokensBalance(networkId, publicClient, address!, nativeBalance)
  );
}

async function getTokensBalance(
  chainId: ApertureSupportedChainId,
  client: PublicClient,
  walletAddress: Address,
  nativeBalance: any
) {
  const tokenMap = getTokenMap(chainId);
  const balanceList = await Promise.all(
    Array.from(tokenMap.keys()).map(async (address) => {
      return BigInt(
        await client.readContract({
          address: address as Address,
          abi: IERC20__factory.abi,
          functionName: "balanceOf",
          args: [walletAddress],
        })
      );
    })
  );

  const balanceMap = Array.from(tokenMap.values()).reduce(
    (obj: TokenBalanceMap, token, index) => {
      obj[`${token.address}-${false}`] = {
        balanceBN: balanceList[index],
        balance: formatUnits(balanceList[index], token.decimals),
      };
      return obj;
    },
    {}
  );
  const native = viem.getNativeCurrency(chainId).wrapped;
  balanceMap[`${native.address}-${true}`] = {
    balanceBN: nativeBalance?.value ?? 0n,
    balance: nativeBalance?.formatted ?? "0.0",
  };
  return balanceMap;
}

export async function getTokenBalance(
  client: PublicClient,
  walletAddress: Address,
  token: {
    address: string;
    decimals: number;
  }
) {
  const balance = BigInt(
    await client.readContract({
      address: token.address as Address,
      abi: IERC20__factory.abi,
      functionName: "balanceOf",
      args: [walletAddress],
    })
  );

  return {
    [`${token.address}-${false}`]: {
      balanceBN: balance,
      balance: formatUnits(balance, token.decimals),
    },
  };
}
