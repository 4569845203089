export const CompletedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.6 12.2375L6.45 10.0875C6.26667 9.90417 6.03333 9.8125 5.75 9.8125C5.46667 9.8125 5.23333 9.90417 5.05 10.0875C4.86667 10.2708 4.775 10.5042 4.775 10.7875C4.775 11.0708 4.86667 11.3042 5.05 11.4875L7.9 14.3375C8.1 14.5375 8.33333 14.6375 8.6 14.6375C8.86667 14.6375 9.1 14.5375 9.3 14.3375L14.95 8.6875C15.1333 8.50417 15.225 8.27083 15.225 7.9875C15.225 7.70417 15.1333 7.47083 14.95 7.2875C14.7667 7.10417 14.5333 7.0125 14.25 7.0125C13.9667 7.0125 13.7333 7.10417 13.55 7.2875L8.6 12.2375ZM10 20.4375C8.61667 20.4375 7.31667 20.1748 6.1 19.6495C4.88333 19.1242 3.825 18.4118 2.925 17.5125C2.025 16.6125 1.31267 15.5542 0.788 14.3375C0.263333 13.1208 0.000666667 11.8208 0 10.4375C0 9.05417 0.262667 7.75417 0.788 6.5375C1.31333 5.32083 2.02567 4.2625 2.925 3.3625C3.825 2.4625 4.88333 1.75017 6.1 1.2255C7.31667 0.700833 8.61667 0.438167 10 0.4375C11.3833 0.4375 12.6833 0.700167 13.9 1.2255C15.1167 1.75083 16.175 2.46317 17.075 3.3625C17.975 4.2625 18.6877 5.32083 19.213 6.5375C19.7383 7.75417 20.0007 9.05417 20 10.4375C20 11.8208 19.7373 13.1208 19.212 14.3375C18.6867 15.5542 17.9743 16.6125 17.075 17.5125C16.175 18.4125 15.1167 19.1252 13.9 19.6505C12.6833 20.1758 11.3833 20.4382 10 20.4375Z"
        fill={props.fill || "#24FF00"}
      />
    </svg>
  );
};
