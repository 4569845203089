import { useOkxQuoteTokens, useRpcSettings } from "@/config/feature_flags";
import { useOnLogout } from "@/hooks/globalState/useOnLogout";
import { useEventCallback } from "@/hooks/useEventCallback";
import { AmmEnum } from "@aperture/uikit";
import { ApertureSupportedChainId } from "@aperture_finance/uniswap-v3-automation-sdk";
import { Token } from "@uniswap/sdk-core";
import { useLocalStorageState } from "ahooks";
import { keyBy } from "lodash";
import { useEffect, useReducer, useRef } from "react";
import { Address } from "viem";
import {
  initAllTokenMapAction,
  updatePositionMapAction,
  updateTokenMapAction,
  updateTokenPriceMapAction,
} from "./actions";
import { reducer } from "./reducer";
import { AllChainState } from "./types";

const initialState: AllChainState = {
  rawAllTokenMap: undefined,
  rawAllTokenPriceMap: {},
  rawAllPositionMap: undefined,
};

export const useAllChainState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [_, setAllChainsWarningTokens] = useLocalStorageState<
    Record<number, Token[]>
  >(`all-chains-warning-tokens`);

  const okxQuoteTokens = useOkxQuoteTokens();
  const rpcSetting = useRpcSettings();
  const initialized = useRef<{ [key: string]: boolean }>({});

  const checkInitialized = (key: string) => {
    if (!initialized.current[key]) {
      initialized.current[key] = true;
      return false;
    }
    return true;
  };

  const updateTokenMap = useEventCallback(
    async ({
      chainId,
      tokens,
    }: {
      chainId: ApertureSupportedChainId;
      tokens: Token[];
    }) => {
      setAllChainsWarningTokens((prev) => ({
        ...prev,
        [chainId]: [...(prev?.[chainId] ?? []), ...tokens],
      }));

      updateTokenMapAction(
        dispatch,
        chainId,
        keyBy(tokens, (token) => token.address)
      );
    }
  );

  const updateTokenPriceMap = useEventCallback(
    async ({
      chainId,
      tokens,
      isInitialization = false,
    }: {
      chainId: ApertureSupportedChainId;
      tokens?: Token[];
      isInitialization?: boolean;
    }) => {
      if (isInitialization && checkInitialized(`token-price-${chainId}`)) {
        return;
      }

      if (!tokens) {
        tokens = Object.values(state.rawAllTokenMap?.[chainId] ?? {});
      }
      updateTokenPriceMapAction(
        dispatch,
        chainId,
        tokens,
        okxQuoteTokens?.[chainId]
      );
    }
  );

  const updatePositionMap = useEventCallback(
    async ({
      chainId,
      amm,
      walletAddress,
      isInitialization = false,
    }: {
      chainId: ApertureSupportedChainId;
      amm: AmmEnum;
      walletAddress: Address;
      isInitialization?: boolean;
    }) => {
      if (
        isInitialization &&
        checkInitialized(`position-map-${walletAddress}-${amm}-${chainId}`)
      ) {
        return;
      }

      updatePositionMapAction(
        dispatch,
        chainId,
        amm,
        walletAddress,
        rpcSetting
      );
    }
  );

  useEffect(() => {
    // initialize for raw token map
    initAllTokenMapAction(dispatch);
  }, []);

  useOnLogout(() => {
    // clear position map
    dispatch({
      type: "clearPostionMap",
    });

    for (const key in initialized.current) {
      if (key.startsWith("position-map-")) {
        delete initialized.current[key];
      }
    }
  });

  return {
    state,
    updateTokenPriceMap,
    updatePositionMap,
    updateTokenMap,
    dispatch,
  };
};
