export const SwitchIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="116"
      height="116"
      viewBox="0 0 116 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M111.167 38.6657C111.167 36.1019 110.148 33.6431 108.335 31.8303C106.523 30.0174 104.064 28.999 101.5 28.999L37.8354 28.999L40.6677 26.1667C41.591 25.2749 42.3274 24.2083 42.8341 23.0289C43.3407 21.8495 43.6073 20.5811 43.6185 19.2975C43.6297 18.014 43.3851 16.7411 42.899 15.5531C42.413 14.3651 41.6952 13.2858 40.7876 12.3782C39.8799 11.4705 38.8006 10.7528 37.6126 10.2667C36.4246 9.78067 35.1517 9.53608 33.8682 9.54724C32.5847 9.55839 31.3162 9.82506 30.1368 10.3317C28.9575 10.8383 27.8908 11.5747 26.9991 12.498L7.66574 31.8313C6.31425 33.1832 5.39392 34.9055 5.0211 36.7803C4.64828 38.6552 4.8397 40.5986 5.57118 42.3646C6.30266 44.1307 7.54135 45.6403 9.13064 46.7025C10.7199 47.7648 12.5885 48.3319 14.5001 48.3323L101.5 48.3323C104.064 48.3323 106.523 47.3139 108.335 45.501C110.148 43.6882 111.167 41.2294 111.167 38.6657Z"
        fill={`url(#${props.id}_switch_paint0)`}
      />
      <path
        d="M89.0009 103.502L108.334 84.169C109.686 82.8171 110.606 81.0948 110.979 79.2199C111.352 77.3451 111.16 75.4017 110.429 73.6356C109.697 71.8695 108.459 70.36 106.869 69.2978C105.28 68.2355 103.412 67.6684 101.5 67.668L14.4999 67.668C11.9362 67.668 9.47742 68.6864 7.66457 70.4993C5.85172 72.3121 4.83327 74.7709 4.83327 77.3346C4.83327 79.8984 5.85172 82.3571 7.66457 84.17C9.47742 85.9828 11.9362 87.0013 14.4999 87.0013L78.1646 87.0013L75.3323 89.8336C73.5714 91.6568 72.5971 94.0986 72.6191 96.6332C72.6411 99.1677 73.6578 101.592 75.45 103.385C77.2423 105.177 79.6668 106.193 82.2014 106.215C84.736 106.238 87.1778 105.263 89.0009 103.502Z"
        fill={`url(#${props.id}_switch_paint1)`}
      />
      <defs>
        <linearGradient
          id={`${props.id}_switch_paint0`}
          x1="26.4027"
          y1="13.0229"
          x2="40.6062"
          y2="59.6366"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8627FF" />
          <stop offset="1" stopColor="#8094FF" />
        </linearGradient>
        <linearGradient
          id={`${props.id}_switch_paint1`}
          x1="26.4005"
          y1="71.1227"
          x2="40.4451"
          y2="117.499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8627FF" />
          <stop offset="1" stopColor="#8094FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
