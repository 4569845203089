import { useAllChainStateStore } from "@/components/GlobalStore";
import { useNetwork } from "@/components/NetworkContext/NetworkContext";
import { useOnFirstLoad } from "../useOnFirstLoad";

export const useCurrentChainState = (networkId?: number) => {
  const { networkId: currentNetworkId } = useNetwork();

  const {
    state: { rawAllTokenMap, rawAllTokenPriceMap },
    updateTokenPriceMap,
  } = useAllChainStateStore();

  const _network = networkId ?? currentNetworkId;
  const currentTokenMap = rawAllTokenMap?.[_network];
  const currentTokenPrice = rawAllTokenPriceMap?.[_network];

  useOnFirstLoad(currentTokenMap, () => {
    updateTokenPriceMap({
      chainId: _network,
      tokens: Object.values(currentTokenMap),
      isInitialization: true,
    });
  });

  return {
    currentTokenMap,
    currentTokenPrice,
  };
};
