import { config } from "@/config";
import { getCommonTokenMap } from "@/config/token/tokenMap";
import { IToken } from "@aperture/types";
import { AmmEnum, E_AMM, SupportedChainId } from "@aperture/uikit";
import {
  ApertureSupportedChainId,
  getChainInfo,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { FeeAmount } from "@aperture_finance/uniswap-v3-sdk";
import { Token } from "@uniswap/sdk-core";
import bigDecimal from "js-big-decimal";

export const isProdEnv = () => {
  return process.env.NEXT_PUBLIC_ENV === "prod";
};

export const MAX_TRIGGER = 3;
export const MIN_WITHDRAW_AMOUNT = 0.00000000000001;
export const MIN_DEPOSIT_AMOUNT = 0.0;
export const WARNING_SLIPPAGE = 0.0005;
export const DEFAULT_SLIPPAGE = 0.005;
export const DEFAULT_REBALANCE_SLIPPAGE = 0.01;
export const DEFAULT_REVENUE = 0.003;
export const DEFAULT_APERTURE_FEE = 0.001;
export const DEFAULT_APERTURE_FLAT_FEE = 0.15;
export const DEFAULT_APERTURE_REBALANCE_FEE = 0.0015;
export const DEFAULT_APERTURE_SWAP_FEE = 0.0025;
export const APERTURE_AIRDROP_SWAP_FEE = 0.01;
export const DEFAULT_APERTURE_ZAPIN_FEE = 0.0025;
export const DEFAULT_APERTURE_REINVEST_FEE = 0.03;
export const DEFAULT_FEE_TIER: Record<AmmEnum, FeeAmount> = {
  UNISWAP_V3: FeeAmount.MEDIUM,
  PANCAKESWAP_V3: FeeAmount.PCS_V3_MEDIUM,
  SLIPSTREAM: FeeAmount.MEDIUM,
};
export const DEFAULT_GAS_CEILING = 0.005;
const ETHEREUM_MIN_GAS_CEILING = 80;
const ARBITRUM_MIN_GAS_CEILING = 0.7;
const OPTIMISM_MIN_GAS_CEILING = 0.3;
const POLYGON_MIN_GAS_CEILING = 0.08;
const BASE_MIN_GAS_CEILING = 0.3;
const BNB_MIN_GAS_CEILING = 0.9;
const AVALANCHE_MIN_GAS_CEILING = 0.5;
const SCROLL_MIN_GAS_CEILING = 0.3;
export const MAX_UINT256 = BigInt(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
);
export const MAX_TIMESTAMP = 253402300799000; // Dec 31, Year 9999
export const DEFAULT_MULTICALL_CONF = {
  batchSize: 100,
  timeWindow: 50,
  verbose: !isProdEnv(),
};
export const DEFAULT_SWAP_WARNING_THRESHOLD = 15;

export const FEE_RECIPIENT: Record<
  E_AMM,
  Partial<Record<SupportedChainId, string>>
> = {
  [E_AMM.ApertureSwap]: {
    [SupportedChainId.MANTA]: "0x40d17E9138d4748B919E6f5255d75eC3112ef842",
  },
  [E_AMM.PancakeSwap]: {
    [SupportedChainId.ETHEREUM]: "0x5a846031d03Fe53bfcD63e0Dc75d2e6AEdA57198",
    [SupportedChainId.BNB]: "0xd113eA7cCF2fEf911acc4E7d960A2eA8f09816d2",
  },
  [E_AMM.Uniswap]: {
    [SupportedChainId.ETHEREUM]: "0x5a846031d03Fe53bfcD63e0Dc75d2e6AEdA57198",
    [SupportedChainId.ARBITRUM]: "0x145304a5cfEc1B616Cf035C43f084CE1233d9Ea7",
    [SupportedChainId.OPTIMISM]: "0x0EDD0A75fd824Ff414247188d483fbB3E4a8e37c",
    [SupportedChainId.POLYGON]: "0xD51f78EC33D10Ae32dDA96ef00318C637077a292",
    [SupportedChainId.BASE]: "0x3D37E9E5Ff12c3ebBdFace34f0a1FD7A42c856ED",
    [SupportedChainId.BNB]: "0xd113eA7cCF2fEf911acc4E7d960A2eA8f09816d2",
    [SupportedChainId.AVALANCHE]: "0x30590dBBB8127e657d9341FA91356A838D88CBA8",
    [SupportedChainId.SCROLL]: "0xaF327bb0AF7c282FD75ee72651f4E78D4349d517",
  },
  [E_AMM.Aerodrome]: {}, // TODO
  [E_AMM.Velodrome]: {}, // TODO
};

export const SLIPSTREAM_NETWORKS: ApertureSupportedChainId[] = [
  ApertureSupportedChainId.BASE_MAINNET_CHAIN_ID,
  ApertureSupportedChainId.OPTIMISM_MAINNET_CHAIN_ID,
];

export const getSlipstreamAmmByNetworkId = (
  networkId: ApertureSupportedChainId
) => {
  switch (networkId) {
    case ApertureSupportedChainId.BASE_MAINNET_CHAIN_ID:
      return "Aerodrome";
    case ApertureSupportedChainId.OPTIMISM_MAINNET_CHAIN_ID:
      return "Velodrome";
  }
};

export const getMinGasCeiling = (networkId: ApertureSupportedChainId) => {
  switch (networkId) {
    case ApertureSupportedChainId.ETHEREUM_MAINNET_CHAIN_ID:
      return ETHEREUM_MIN_GAS_CEILING;
    case ApertureSupportedChainId.ARBITRUM_MAINNET_CHAIN_ID:
      return ARBITRUM_MIN_GAS_CEILING;
    case ApertureSupportedChainId.OPTIMISM_MAINNET_CHAIN_ID:
      return OPTIMISM_MIN_GAS_CEILING;
    case ApertureSupportedChainId.POLYGON_MAINNET_CHAIN_ID:
      return POLYGON_MIN_GAS_CEILING;
    case ApertureSupportedChainId.BASE_MAINNET_CHAIN_ID:
      return BASE_MIN_GAS_CEILING;
    case ApertureSupportedChainId.BNB_MAINNET_CHAIN_ID:
      return BNB_MIN_GAS_CEILING;
    case ApertureSupportedChainId.AVALANCHE_MAINNET_CHAIN_ID:
      return AVALANCHE_MIN_GAS_CEILING;
    case ApertureSupportedChainId.SCROLL_MAINNET_CHAIN_ID:
      return SCROLL_MIN_GAS_CEILING;
  }
};

export const getGasCeiling = (
  networkId: ApertureSupportedChainId,
  amount: string
) => {
  if (amount === "-" || bigDecimal.compareTo(amount, 0) === 0) return 0;
  return parseFloat(
    bigDecimal.round(
      bigDecimal.divide(getMinGasCeiling(networkId), amount, 18),
      6,
      0
    )
  );
};

export const gasCeilingInputError = (
  networkId: ApertureSupportedChainId,
  percentage: number,
  amount: string
) => {
  amount = bigDecimal.multiply(percentage, amount !== "-" ? amount : "0");
  const min = getMinGasCeiling(networkId);
  const max = getChainInfo(networkId).maxGasCeiling;
  if (bigDecimal.compareTo(amount, min) < 0) {
    return `Your gas fee ceiling needs to be at least ${min}USD`;
  } else if (bigDecimal.compareTo(percentage, max) > 0) {
    return `Your gas fee ceiling exceeds ${max * 100}% of your position value.`;
  }
};

export const gasCeilingButtonError = (
  networkId: ApertureSupportedChainId,
  percentage: number,
  amount: string
) => {
  amount = bigDecimal.multiply(percentage, amount !== "-" ? amount : "0");
  const min = getMinGasCeiling(networkId);
  const max = getChainInfo(networkId).maxGasCeiling;
  if (bigDecimal.compareTo(amount, min) < 0) {
    return "Gas Fee Lower Than Threshold";
  } else if (bigDecimal.compareTo(percentage, max) > 0) {
    return "Gas Ceiling Exceeded";
  } else {
    return "";
  }
};

export const getAutomanAPI = () => config.automanAPI;

export function isWrappedTokenPair(a?: IToken, b?: IToken) {
  if (!a || !b) return false;
  if (!a.address || !b.address) return false;
  return a.address === b.address && a.native !== b.native;
}

export function isTokensEquivalent(a?: IToken, b?: IToken) {
  if (!a || !b) return false;
  if (!a.address || !b.address) return false;
  return a.address === b.address && a.native === b.native;
}

export function checkAddresses(
  a: IToken | Token | string | null | undefined,
  b: IToken | Token | string | null | undefined,
  func: "Equivalent" | "Sorted" = "Equivalent"
) {
  if (!a || !b) return false;
  const addressA = typeof a === "string" ? a : a.address;
  const addressB = typeof b === "string" ? b : b.address;
  switch (func) {
    case "Sorted":
      return addressA.toLowerCase() < addressB.toLowerCase();
    default:
      return addressA.toLowerCase() === addressB.toLowerCase();
  }
}

export function getTokenMap(networkId: ApertureSupportedChainId) {
  const tokenMap = getCommonTokenMap(networkId);
  const allWarningTokens = localStorage.getItem(`all-chains-warning-tokens`);
  const warningTokens = allWarningTokens
    ? JSON.parse(allWarningTokens)[networkId] || undefined
    : undefined;
  const warningTokenMap = warningTokens
    ? generateTokenMap(warningTokens)
    : new Map<string, Token>();
  return new Map([...tokenMap, ...warningTokenMap]);
}

export function generateTokenMap(tokens: Token[] = []) {
  const tokensMap = new Map<string, Token>();
  for (const token of tokens) {
    if (!tokensMap.has(token.address)) {
      tokensMap.set(
        token.address,
        new Token(
          token.chainId,
          token.address,
          token.decimals,
          token.symbol,
          token.name
        )
      );
    }
  }
  return tokensMap;
}

export function calculateGasMargin(value: bigint): bigint {
  return BigInt((value * 120n) / 100n);
}
