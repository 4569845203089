export const CopyFileIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={props.fill || "white"}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.79852 1.84302H4.89852V2.74302V4.89859H2.7429H1.8429V5.79859V6.87281V16.1827V17.2569V18.1569H2.7429H14.2012H15.1012V17.2569V16.1827V15.1014H17.2569H18.1569V14.2014V2.74302V1.84302H17.2569H5.79852ZM15.1012 13.3014H16.3569V3.64302H6.69852V4.89859H14.2012H15.1012V5.79859V6.87281V13.3014ZM3.6429 6.87281V6.69859H13.3012V6.87281V16.1827V16.3569H3.6429V16.1827V6.87281Z"
      />
    </svg>
  );
};
