export const WarningIcon2: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={props.fill || "white"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_warning2_clip0`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.99994 0V10.2159H8.99994V0H6.99994ZM9.80265 14.1972C9.80265 15.1929 8.99549 16.0001 7.99983 16.0001C7.00418 16.0001 6.19702 15.1929 6.19702 14.1972C6.19702 13.2015 7.00418 12.3943 7.99983 12.3943C8.99549 12.3943 9.80265 13.2015 9.80265 14.1972Z"
        />
      </g>
      <defs>
        <clipPath id={`${props.id}_warning2_clip0`}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
