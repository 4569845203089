export const DeleteIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.03716 1.6001H7.50416L7.24796 2.06748L6.13375 4.1001H4.16675H3.26675H1.66675V5.9001H3.26675V17.5001V18.4001H4.16675H15.8334H16.7334V17.5001V5.9001H18.3334V4.1001H16.7334H15.8334H13.8716L12.7834 2.07421L12.5287 1.6001H11.9905H8.03716ZM11.8284 4.1001L11.4524 3.4001H8.57016L8.18645 4.1001H11.8284ZM5.14705 5.9001L5.06675 5.9001V16.6001H14.9334V5.9001L14.8385 5.9001H13.3334H6.66675H5.14705ZM7.43343 14.1667V8.33341H9.23344V14.1667H7.43343ZM10.7667 8.33341V14.1667H12.5667V8.33341H10.7667Z"
        fill={props.fill || "white"}
      />
    </svg>
  );
};
