export const ExitIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={props.fill || "white"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_exit_clip0)`}>
        <path d="M4.02925 2.91992C2.90135 3.81671 1.99063 4.95673 1.3651 6.25485C0.73956 7.55296 0.415375 8.97562 0.416752 10.4166C0.416752 15.7091 4.70759 19.9999 10.0001 19.9999C15.2926 19.9999 19.5834 15.7091 19.5834 10.4166C19.5834 7.38575 18.1768 4.68409 15.9809 2.92826L15.0001 4.16659V4.27826C16.2673 5.31056 17.184 6.70979 17.6242 8.28387C18.0644 9.85794 18.0067 11.5297 17.4588 13.0696C16.911 14.6096 15.8999 15.9422 14.5644 16.8845C13.229 17.8269 11.6346 18.3328 10.0001 18.3328C8.36561 18.3328 6.77119 17.8269 5.43572 16.8845C4.10025 15.9422 3.0892 14.6096 2.54136 13.0696C1.99352 11.5297 1.93575 9.85794 2.37598 8.28387C2.81621 6.70979 3.73286 5.31056 5.00009 4.27826V4.16659L4.02925 2.91992Z" />
        <path d="M5.38493 3.57246C5.38495 3.68191 5.3634 3.79029 5.32153 3.89141C5.27966 3.99252 5.21828 4.08441 5.1409 4.1618C5.06351 4.2392 4.97164 4.30059 4.87053 4.34248C4.76941 4.38437 4.66104 4.40592 4.5516 4.40592C4.44215 4.40592 4.33378 4.38437 4.23266 4.34248C4.13155 4.30059 4.03968 4.2392 3.96229 4.1618C3.88491 4.08441 3.82353 3.99252 3.78166 3.89141C3.73979 3.79029 3.71824 3.68191 3.71826 3.57246C3.7183 3.35147 3.80611 3.13954 3.96238 2.98329C4.11866 2.82704 4.3306 2.73926 4.5516 2.73926C4.77259 2.73926 4.98453 2.82704 5.14081 2.98329C5.29708 3.13954 5.38489 3.35147 5.38493 3.57246ZM16.2816 3.57246C16.2816 3.68191 16.2601 3.79029 16.2182 3.89141C16.1763 3.99252 16.1149 4.08441 16.0376 4.1618C15.9602 4.2392 15.8683 4.30059 15.7672 4.34248C15.6661 4.38437 15.5577 4.40592 15.4483 4.40592C15.3388 4.40592 15.2304 4.38437 15.1293 4.34248C15.0282 4.30059 14.9363 4.2392 14.859 4.1618C14.7816 4.08441 14.7202 3.99252 14.6783 3.89141C14.6365 3.79029 14.6149 3.68191 14.6149 3.57246C14.615 3.35147 14.7028 3.13954 14.8591 2.98329C15.0153 2.82704 15.2273 2.73926 15.4483 2.73926C15.6693 2.73926 15.8812 2.82704 16.0375 2.98329C16.1937 3.13954 16.2816 3.35147 16.2816 3.57246Z" />
        <path d="M10.0001 0C10.2211 0 10.4331 0.0877974 10.5893 0.244078C10.7456 0.400358 10.8334 0.61232 10.8334 0.833333V6.66667C10.8334 6.88768 10.7456 7.09964 10.5893 7.25592C10.4331 7.4122 10.2211 7.5 10.0001 7.5C9.77907 7.5 9.56711 7.4122 9.41083 7.25592C9.25455 7.09964 9.16675 6.88768 9.16675 6.66667V0.833333C9.16675 0.61232 9.25455 0.400358 9.41083 0.244078C9.56711 0.0877974 9.77907 0 10.0001 0Z" />
      </g>
      <defs>
        <clipPath id={`${props.id}_exit_clip0`}>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};
