export const SolanaIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_solana_clip0)`}>
        <path
          d="M10.0137 20.0137C15.5365 20.0137 20.0137 15.5365 20.0137 10.0137C20.0137 4.49082 15.5365 0.0136719 10.0137 0.0136719C4.49082 0.0136719 0.0136719 4.49082 0.0136719 10.0137C0.0136719 15.5365 4.49082 20.0137 10.0137 20.0137Z"
          fill="black"
        />
        <path
          d="M6.18469 12.7702C6.22252 12.7287 6.26862 12.6956 6.32001 12.673C6.37122 12.6506 6.42649 12.6389 6.48239 12.6386L15.6464 12.6462C15.6855 12.6462 15.7237 12.6576 15.7564 12.6789C15.7891 12.7003 15.8149 12.7306 15.8306 12.7664C15.8464 12.8021 15.8514 12.8417 15.8451 12.8802C15.8387 12.9187 15.8213 12.9546 15.795 12.9834L13.8434 15.1323C13.8057 15.1737 13.7597 15.2069 13.7085 15.2295C13.6572 15.2522 13.6018 15.2638 13.5457 15.2638L4.38199 15.2562C4.34299 15.2561 4.30486 15.2447 4.27222 15.2233C4.23959 15.202 4.21385 15.1716 4.19812 15.1359C4.1824 15.1002 4.17736 15.0607 4.18362 15.0222C4.18989 14.9837 4.20718 14.9479 4.23341 14.919L6.18469 12.7702ZM15.7942 10.9815C15.8205 11.0104 15.8379 11.0462 15.8442 11.0848C15.8506 11.1233 15.8456 11.1628 15.8298 11.1986C15.8141 11.2343 15.7883 11.2647 15.7556 11.286C15.7228 11.3074 15.6846 11.3187 15.6456 11.3187L6.48185 11.3263C6.4258 11.3264 6.37037 11.3147 6.3191 11.292C6.26784 11.2694 6.22187 11.2363 6.18415 11.1948L4.23314 9.04486C4.2069 9.01594 4.18961 8.98003 4.18334 8.94148C4.177 8.90299 4.18199 8.86349 4.1977 8.82779C4.21341 8.79208 4.23917 8.76172 4.27184 8.7404C4.30454 8.71908 4.34268 8.70762 4.38172 8.70738L13.5457 8.70007C13.6018 8.69996 13.6572 8.71161 13.7085 8.73426C13.7598 8.75692 13.8058 8.79008 13.8434 8.8316L15.7942 10.9815ZM6.18469 4.8952C6.22236 4.85368 6.26831 4.82052 6.31959 4.79786C6.37087 4.77521 6.42633 4.76356 6.48239 4.76367L15.6464 4.77125C15.6854 4.77129 15.7236 4.78267 15.7563 4.80401C15.7889 4.82534 15.8147 4.85571 15.8304 4.89141C15.8462 4.92705 15.8512 4.96649 15.8449 5.00494C15.8386 5.04338 15.8212 5.07916 15.795 5.10792L13.8434 7.25677C13.8057 7.29823 13.7597 7.33135 13.7085 7.354C13.6572 7.37665 13.6018 7.38833 13.5457 7.3883L4.38199 7.38072C4.34292 7.38072 4.3047 7.36933 4.27202 7.34794C4.23933 7.32655 4.21359 7.29609 4.19795 7.26029C4.18236 7.22462 4.17742 7.18521 4.18371 7.1468C4.19 7.10839 4.20726 7.07261 4.23341 7.04378L6.18469 4.8952Z"
          fill={`url(#${props.id}_solana_paint0)`}
        />
      </g>
      <defs>
        <linearGradient
          id={`${props.id}_solana_paint0`}
          x1="4.5349"
          y1="15.4925"
          x2="15.4924"
          y2="4.53499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9945FF" />
          <stop offset="0.2" stopColor="#7962E7" />
          <stop offset="1" stopColor="#00D18C" />
        </linearGradient>
        <clipPath id={`${props.id}_solana_clip0`}>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
