export const DiscordIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24.2539C18.6274 24.2539 24 18.8813 24 12.2539C24 5.62649 18.6274 0.253906 12 0.253906C5.37258 0.253906 0 5.62649 0 12.2539C0 18.8813 5.37258 24.2539 12 24.2539ZM14.2088 6.46614L14.4218 6.25311C16.5403 6.29094 18.459 7.75752 18.459 7.75752C20.615 12.2583 20.4772 16.1445 20.4772 16.1445C18.7223 18.4136 16.1146 18.2505 16.1146 18.2505L15.2243 17.1222C16.7911 16.784 17.7817 15.3923 17.7817 15.3923C17.7817 15.3923 15.4248 16.997 12.0399 16.997C8.65501 16.997 6.29813 15.3923 6.29813 15.3923C6.29813 15.3923 7.28833 16.784 8.85555 17.1222L7.96561 18.2505C7.96561 18.2505 5.35787 18.4136 3.60261 16.1445C3.60261 16.1445 3.46451 12.2583 5.62085 7.75752C5.62085 7.75752 7.53919 6.29058 9.65807 6.25311L9.8711 6.46614C9.8711 6.46614 7.61412 7.13056 6.3231 8.28384C6.3231 8.28384 8.68035 6.84224 12.0399 6.84224C15.3995 6.84224 17.7564 8.28384 17.7564 8.28384C16.4654 7.13056 14.2088 6.46614 14.2088 6.46614ZM7.99022 13.1857C7.99022 14.0506 8.64215 14.7778 9.44468 14.7778C10.2593 14.7778 10.9113 14.0506 10.9113 13.1857C10.9241 12.3082 10.2597 11.5935 9.44468 11.5935C8.62967 11.5935 7.99022 12.3207 7.99022 13.1857ZM13.2185 13.1857C13.2185 14.0506 13.8701 14.7778 14.6726 14.7778C15.4873 14.7778 16.1392 14.0631 16.1392 13.1857C16.1392 12.3082 15.4873 11.5935 14.6726 11.5935C13.858 11.5935 13.2185 12.3207 13.2185 13.1857Z"
        fill="white"
      />
    </svg>
  );
};
