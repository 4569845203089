export const StarIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.99994 3.48627L11.5974 7.4642L11.7923 7.94969L12.3143 7.98508L16.5912 8.27508L13.3016 11.0236L12.9001 11.359L13.0277 11.8664L14.0735 16.0235L10.443 13.7443L9.99994 13.4661L9.55684 13.7443L5.92633 16.0235L6.97215 11.8664L7.09979 11.359L6.69831 11.0236L3.4087 8.27508L7.68557 7.98508L8.20755 7.94969L8.40251 7.4642L9.99994 3.48627Z"
        stroke={props.stroke || "white"}
        strokeOpacity={props.strokeOpacity || "1"}
        strokeWidth="1.66667"
        fill={props.fill}
      />
    </svg>
  );
};
