export const ArrowLeftIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="white"
        d="M12.9427 8.94287L13.8855 8.00006L11.9999 6.11444L11.0571 7.05725L3.05707 15.0573L2.11426 16.0001L3.05707 16.9429L11.0571 24.9429L11.9999 25.8857L13.8855 24.0001L12.9427 23.0573L7.21883 17.3334H27.9999H29.3332V14.6667H27.9999H7.21883L12.9427 8.94287Z"
      />
    </svg>
  );
};
