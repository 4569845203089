export const InRangeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill={props.fill || "white"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_in_range_clip0)`}>
        <path d="M5.16 7.08L3.855 5.775C3.745 5.665 3.6074 5.6124 3.4422 5.6172C3.277 5.622 3.1396 5.6796 3.03 5.79C2.92 5.9 2.865 6.04 2.865 6.21C2.865 6.38 2.92 6.52 3.03 6.63L4.74 8.34C4.85 8.45 4.99 8.505 5.16 8.505C5.33 8.505 5.47 8.45 5.58 8.34L8.985 4.935C9.095 4.825 9.1476 4.6874 9.1428 4.5222C9.138 4.357 9.0804 4.2196 8.97 4.11C8.86 4 8.72 3.945 8.55 3.945C8.38 3.945 8.24 4 8.13 4.11L5.16 7.08ZM6 12C5.17 12 4.39 11.8424 3.66 11.5272C2.93 11.212 2.295 10.7846 1.755 10.245C1.215 9.705 0.7876 9.07 0.4728 8.34C0.158 7.61 0.0004 6.83 0 6C0 5.17 0.1576 4.39 0.4728 3.66C0.788 2.93 1.2154 2.295 1.755 1.755C2.295 1.215 2.93 0.7876 3.66 0.4728C4.39 0.158 5.17 0.0004 6 0C6.83 0 7.61 0.1576 8.34 0.4728C9.07 0.788 9.705 1.2154 10.245 1.755C10.785 2.295 11.2126 2.93 11.5278 3.66C11.843 4.39 12.0004 5.17 12 6C12 6.83 11.8424 7.61 11.5272 8.34C11.212 9.07 10.7846 9.705 10.245 10.245C9.705 10.785 9.07 11.2126 8.34 11.5278C7.61 11.843 6.83 12.0004 6 12ZM6 10.8C7.33 10.8 8.4626 10.3326 9.3978 9.3978C10.333 8.463 10.8004 7.3304 10.8 6C10.8 4.67 10.3326 3.5374 9.3978 2.6022C8.463 1.667 7.3304 1.1996 6 1.2C4.67 1.2 3.5374 1.6674 2.6022 2.6022C1.667 3.537 1.1996 4.6696 1.2 6C1.2 7.33 1.6674 8.4626 2.6022 9.3978C3.537 10.333 4.6696 10.8004 6 10.8Z" />
      </g>
      <defs>
        <clipPath id={`${props.id}_in_range_clip`}>
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
