export const OutOfRangeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width={props.width || "20"}
      height={props.height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10" cy="10" r="10" fill={props.fill || "#ED9C00"} />
      <path
        d="M6 13.4998L13.4998 6"
        stroke={props.stroke || "#161616"}
        strokeWidth="2"
      />
      <path
        d="M5.99992 6L13.4998 13.4998"
        stroke={props.stroke || "#161616"}
        strokeWidth="2"
      />
    </svg>
  );
};
