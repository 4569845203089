export const AddIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.09963 10.9001V18.3334H10.8996V10.9001H18.3332V9.10006H10.8996V1.66675H9.09963V9.10006H1.6665V10.9001H9.09963Z"
        fill={props.fill || "white"}
      />
    </svg>
  );
};
