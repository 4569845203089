export const SendIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={props.fill || "white"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8659 4.04749L17.4834 2.2832L15.7191 2.90071L3.2193 7.27566L1.07666 8.02559L3.15109 8.94756L8.45969 11.307L10.8191 16.6156L11.741 18.69L12.491 16.5474L16.8659 4.04749ZM8.94235 9.5517L5.95657 8.22468L12.5916 5.90239L8.94235 9.5517ZM10.2151 10.8246L11.5419 13.8101L13.864 7.17563L10.2151 10.8246Z"
      />
    </svg>
  );
};
