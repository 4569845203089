export const UsdcIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_usdc_clip0)`}>
        <path
          d="M20 40C31.0834 40 40 31.0834 40 20C40 8.9166 31.0834 0 20 0C8.9166 0 0 8.9166 0 20C0 31.0834 8.9166 40 20 40Z"
          fill="#5BA6FC"
        />
        <path
          d="M25.4996 23.167C25.4996 20.2504 23.7496 19.2504 20.2496 18.8338C17.7496 18.5004 17.2496 17.8338 17.2496 16.667C17.2496 15.5002 18.083 14.7504 19.7496 14.7504C21.2496 14.7504 22.083 15.2504 22.4996 16.5004C22.583 16.7504 22.833 16.917 23.083 16.917H24.4162C24.7496 16.917 24.9996 16.667 24.9996 16.3338V16.2504C24.6662 14.417 23.1662 13.0004 21.2496 12.8338V10.8338C21.2496 10.5004 20.9996 10.2504 20.583 10.167H19.333C18.9996 10.167 18.7496 10.417 18.6662 10.8338V12.7504C16.1662 13.0838 14.583 14.7504 14.583 16.8338C14.583 19.5838 16.2496 20.667 19.7496 21.0838C22.083 21.5004 22.833 22.0004 22.833 23.3338C22.833 24.6672 21.6662 25.5838 20.083 25.5838C17.9162 25.5838 17.1662 24.667 16.9162 23.417C16.833 23.0838 16.583 22.917 16.333 22.917H14.9162C14.583 22.917 14.333 23.167 14.333 23.5004V23.5838C14.6662 25.667 15.9996 27.167 18.7496 27.5838V29.5838C18.7496 29.917 18.9996 30.167 19.4162 30.2504H20.6662C20.9996 30.2504 21.2496 30.0004 21.333 29.5838V27.5838C23.833 27.167 25.4996 25.417 25.4996 23.167V23.167Z"
          fill="white"
        />
        <path
          d="M15.7497 31.9162C9.24967 29.583 5.91627 22.333 8.33307 15.9162C9.58307 12.4162 12.3331 9.74961 15.7497 8.49961C16.0831 8.33301 16.2497 8.08301 16.2497 7.66621V6.49961C16.2497 6.16621 16.0831 5.91621 15.7497 5.83301C15.6663 5.83301 15.4997 5.83301 15.4163 5.91621C7.49967 8.41621 3.16627 16.833 5.66627 24.7496C7.16627 29.4162 10.7497 32.9996 15.4163 34.4996C15.7497 34.6662 16.0831 34.4996 16.1663 34.1662C16.2497 34.083 16.2497 33.9996 16.2497 33.833V32.6662C16.2497 32.4162 15.9997 32.083 15.7497 31.9162ZM24.5831 5.91621C24.2497 5.74961 23.9163 5.91621 23.8331 6.24961C23.7497 6.33301 23.7497 6.41621 23.7497 6.58301V7.74961C23.7497 8.08301 23.9997 8.41621 24.2497 8.58301C30.7497 10.9162 34.0831 18.1662 31.6663 24.583C30.4163 28.083 27.6663 30.7496 24.2497 31.9996C23.9163 32.1662 23.7497 32.4162 23.7497 32.833V33.9996C23.7497 34.333 23.9163 34.583 24.2497 34.6662C24.3331 34.6662 24.4997 34.6662 24.5831 34.583C32.4997 32.083 36.8331 23.6662 34.3331 15.7496C32.8331 10.9996 29.1663 7.41621 24.5831 5.91621V5.91621Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={`${props.id}_usdc_clip0`}>
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
