export const ZondiconsLink: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke={props.stroke || "white"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.91667 6.66675H5.83333C3.99238 6.66675 2.5 8.15913 2.5 10.0001C2.5 11.841 3.99238 13.3334 5.83333 13.3334H7.91667M12.0833 6.66675H14.1667C16.0076 6.66675 17.5 8.15913 17.5 10.0001C17.5 11.841 16.0076 13.3334 14.1667 13.3334H12.0833"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path d="M6.25 10H13.75" strokeWidth="1.25" strokeLinecap="round" />
    </svg>
  );
};
