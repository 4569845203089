export const AuthIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00016 13.5095H1.96683C1.9315 13.5095 1.8975 13.4949 1.87283 13.4702C1.8475 13.4449 1.8335 13.4115 1.8335 13.3762C1.8335 13.3755 1.8335 12.4095 1.8335 12.4095C1.8335 11.8555 2.22883 11.3675 2.83816 10.9529C3.92616 10.2102 5.68683 9.74219 7.66683 9.74219C7.9935 9.74219 8.31416 9.75552 8.6275 9.78019C8.90216 9.80219 9.1435 9.59685 9.1655 9.32152C9.18683 9.04619 8.9815 8.80552 8.70616 8.78352C8.3675 8.75619 8.02083 8.74219 7.66683 8.74219C5.45216 8.74219 3.4915 9.29619 2.27416 10.1262C1.3455 10.7595 0.833496 11.5642 0.833496 12.4089V13.3762C0.833496 13.6762 0.952829 13.9649 1.1655 14.1775C1.37816 14.3895 1.66616 14.5095 1.96683 14.5089C3.31083 14.5095 7.00016 14.5095 7.00016 14.5095C7.27616 14.5095 7.50016 14.2849 7.50016 14.0095C7.50016 13.7335 7.27616 13.5095 7.00016 13.5095Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.66667 0.833984C5.64267 0.833984 4 2.47665 4 4.50065C4 6.52465 5.64267 8.16732 7.66667 8.16732C9.69067 8.16732 11.3333 6.52465 11.3333 4.50065C11.3333 2.47665 9.69067 0.833984 7.66667 0.833984ZM7.66667 1.83398C9.13867 1.83398 10.3333 3.02865 10.3333 4.50065C10.3333 5.97265 9.13867 7.16732 7.66667 7.16732C6.19467 7.16732 5 5.97265 5 4.50065C5 3.02865 6.19467 1.83398 7.66667 1.83398Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1017 13.473C12.9097 13.5943 13.7637 13.3436 14.3857 12.7216C15.427 11.6803 15.427 9.98964 14.3857 8.94897C13.345 7.90764 11.6543 7.90764 10.613 8.94897C9.99099 9.57097 9.74033 10.425 9.86166 11.2336L8.31366 12.781C8.21966 12.875 8.16699 13.0023 8.16699 13.135V14.6676C8.16699 14.9436 8.39099 15.1676 8.66699 15.1676H10.1997C10.3323 15.1676 10.4597 15.115 10.5537 15.021L12.1017 13.473ZM12.067 12.4463C11.895 12.4003 11.711 12.4496 11.585 12.5756L9.99299 14.1676H9.16699V13.3416L10.759 11.7496C10.885 11.6236 10.9343 11.4396 10.8883 11.2676C10.7403 10.711 10.8837 10.0923 11.3203 9.65564C11.971 9.00497 13.0277 9.00497 13.679 9.65564C14.3297 10.307 14.3297 11.3636 13.679 12.0143C13.2423 12.451 12.6237 12.5943 12.067 12.4463Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9884 11.3468C11.7064 11.0648 11.7064 10.6068 11.9884 10.3248C12.2704 10.0428 12.7284 10.0428 13.0104 10.3248C13.2924 10.6068 13.2924 11.0648 13.0104 11.3468C12.7284 11.6288 12.2704 11.6288 11.9884 11.3468Z"
        fill="white"
      />
    </svg>
  );
};
