import { CheckedIcon, CrossIcon, WarningIcon } from "@aperture/assetkit";
import { IToken } from "@aperture/types";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ITokenSelectContentProps, useModal } from "..";
import { SearchInput } from "../../Input";
import { LinearLoader } from "../../Loader";
import { Headline3, Headline5 } from "../../Typography";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 372px;
  padding-bottom: 12px;
  gap: ${({ theme }) => theme.spacing.xl};
`;
const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TitleText = styled(Headline3)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Dunbar Tall";
  line-height: 32px;
  text-transform: capitalize;
`;
export const TitleClose = styled(CrossIcon)`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;
const Search = styled(SearchInput)`
  background: none;
  border-radius: ${({ theme }) => theme.radii.round};
  border-color: ${({ theme }) => theme.colors.global.line.Line2};
`;
const CommonTokenList = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  height: 248px;
`;
const CommonTokenItem = styled.div<{ disabled: boolean; selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  margin-top: 8px;
  box-sizing: border-box;
  transition: all ${({ theme }) => theme.transitions.default};
  border-radius: ${({ theme }) => theme.radii.md};
  background: ${({ selected, theme }) =>
    selected
      ? theme.colors.global.background.BG3
      : theme.colors.transparent.transparent};

  &:hover {
    background: ${({ theme }) => theme.colors.global.background.BG3};
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  }
`;
const CommonTokenItemInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.xl};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const CommonTokenItemIcon = styled.div`
  height: 32px;
  width: 32px;
`;
const CommonTokenItemText = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    * {
      stroke: ${({ theme }) => theme.colors.errorWarning.red};
    }
  }
`;
const CommonTokenItemTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const CommonTokenItemBalance = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const CommonTokenName = styled.div`
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
const CommonTokenTicker = styled.div`
  color: ${({ theme }) => theme.colors.global.text.T3};
  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
const NoResultText = styled(Headline5)`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  line-height: 16px;
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const isAddress = (address: string) => {
  return /^(0x){1}[0-9a-fA-F]{40}$/i.test(address);
};

export const TokenSelectContent: React.FC<ITokenSelectContentProps> = ({
  commonTokens,
  selectedToken = null,
  selectToken,
  selectUnknownToken,
  handleUnknownAddress,
  customClose = false,
  onClose,
}) => {
  const { hideModal } = useModal();
  const [list, setList] = useState(commonTokens || []);
  const [unknownToken, setUnknownToken] = useState<IToken | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSelect = (token: IToken, isUnknown: boolean) => {
    if (
      token.address !== selectedToken?.address ||
      token.native !== selectedToken?.native
    ) {
      hideModal();
      isUnknown ? selectUnknownToken(token) : selectToken(token);
    }
  };
  const handleChange = async (value: any) => {
    value = value.toLowerCase();
    const filtered = commonTokens.filter(
      (token) =>
        (token.ticker && token.ticker.toLowerCase().indexOf(value) > -1) ||
        (token.name && token.name.toLowerCase().indexOf(value) > -1) ||
        token.address.toLowerCase().indexOf(value) > -1
    );
    setList(filtered);
    if (filtered.length === 0 && isAddress(value)) {
      setLoading(true);
      const token = await handleUnknownAddress(value);
      setLoading(false);
      setUnknownToken(token);
    } else {
      setUnknownToken(null);
    }
  };

  useEffect(() => {
    setList(commonTokens || []);
  }, [commonTokens]);

  const getTokenDOM = (token: IToken, isUnknown: boolean) => (
    <CommonTokenItem
      key={`${token.address}-${token.native}`}
      disabled={
        token.address === selectedToken?.address &&
        token.native === selectedToken?.native
      }
      onClick={() => {
        handleSelect(token, isUnknown);
      }}
      selected={
        token.address === selectedToken?.address &&
        token.native === selectedToken?.native
      }
    >
      <CommonTokenItemInfo>
        <CommonTokenItemIcon>{token.Icon}</CommonTokenItemIcon>
        <CommonTokenItemText>
          <CommonTokenItemTitle>
            <CommonTokenName>{token.name}</CommonTokenName>
            {token.warning && <WarningIcon height={16} width={16} />}
          </CommonTokenItemTitle>
          <CommonTokenTicker>{token.ticker}</CommonTokenTicker>
        </CommonTokenItemText>
      </CommonTokenItemInfo>
      <CommonTokenItemBalance>
        {!!token.formatAmount && (
          <CommonTokenName>{token.formatAmount}</CommonTokenName>
        )}
        {token.address === selectedToken?.address &&
          token.native === selectedToken?.native && (
            <CheckedIcon height={20} width={20} fill="#FB118E" />
          )}
      </CommonTokenItemBalance>
    </CommonTokenItem>
  );
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <>
      <ContentWrapper>
        <Title>
          <TitleText>Select a token</TitleText>
          {customClose && <TitleClose onClick={handleClose} />}
        </Title>
        <Search
          onChange={handleChange}
          placeholder="Search name or paste address"
          disabled={commonTokens.length === 0}
        />
      </ContentWrapper>
      <CommonTokenList>
        {list.length > 0 ? (
          list.map((token) => getTokenDOM(token, false))
        ) : loading ? (
          <LinearLoader size="100%" height="32px" radius="8px" />
        ) : !!unknownToken ? (
          getTokenDOM(unknownToken, true)
        ) : (
          <NoResultText>No results found.</NoResultText>
        )}
      </CommonTokenList>
    </>
  );
};
