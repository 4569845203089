export const GridMoreIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.32 5.16013C7.32 6.35308 6.35293 7.32015 5.16 7.32015C3.96706 7.32015 3 6.35308 3 5.16013C3 3.96719 3.96706 3.00012 5.16 3.00012C6.35293 3.00012 7.32 3.96719 7.32 5.16013ZM7.32003 18.8403C7.32003 20.0333 6.35296 21.0003 5.16003 21.0003C3.96709 21.0003 3.00003 20.0333 3.00003 18.8403C3.00003 17.6474 3.96709 16.6803 5.16003 16.6803C6.35296 16.6803 7.32003 17.6474 7.32003 18.8403ZM5.16003 14.1604C6.35296 14.1604 7.32003 13.1933 7.32003 12.0004C7.32003 10.8075 6.35296 9.84039 5.16003 9.84039C3.96709 9.84039 3.00003 10.8075 3.00003 12.0004C3.00003 13.1933 3.96709 14.1604 5.16003 14.1604ZM21 5.16026C21 6.3532 20.033 7.32027 18.84 7.32027C17.6471 7.32027 16.68 6.3532 16.68 5.16026C16.68 3.96731 17.6471 3.00024 18.84 3.00024C20.033 3.00024 21 3.96731 21 5.16026ZM18.84 21.0003C20.033 21.0003 21 20.0333 21 18.8403C21 17.6474 20.033 16.6803 18.84 16.6803C17.6471 16.6803 16.68 17.6474 16.68 18.8403C16.68 20.0333 17.6471 21.0003 18.84 21.0003ZM21 12.0004C21 13.1933 20.033 14.1604 18.84 14.1604C17.6471 14.1604 16.68 13.1933 16.68 12.0004C16.68 10.8075 17.6471 9.84039 18.84 9.84039C20.033 9.84039 21 10.8075 21 12.0004ZM12 7.32027C13.1929 7.32027 14.16 6.3532 14.16 5.16026C14.16 3.96731 13.1929 3.00024 12 3.00024C10.8071 3.00024 9.84 3.96731 9.84 5.16026C9.84 6.3532 10.8071 7.32027 12 7.32027ZM14.16 18.8403C14.16 20.0333 13.1929 21.0003 12 21.0003C10.8071 21.0003 9.84 20.0333 9.84 18.8403C9.84 17.6474 10.8071 16.6803 12 16.6803C13.1929 16.6803 14.16 17.6474 14.16 18.8403ZM12 14.1604C13.1929 14.1604 14.16 13.1933 14.16 12.0004C14.16 10.8075 13.1929 9.84039 12 9.84039C10.8071 9.84039 9.84 10.8075 9.84 12.0004C9.84 13.1933 10.8071 14.1604 12 14.1604Z"
        fill={props.fill || "white"}
      />
    </svg>
  );
};
