export const LoadingIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 4.5C10.5109 4.5 8.60322 5.29018 7.1967 6.6967C5.79018 8.10322 5 10.0109 5 12C5 13.9891 5.79018 15.8968 7.1967 17.3033C8.60322 18.7098 10.5109 19.5 12.5 19.5C14.4891 19.5 16.3968 18.7098 17.8033 17.3033C19.2098 15.8968 20 13.9891 20 12C20 10.0109 19.2098 8.10322 17.8033 6.6967C16.3968 5.29018 14.4891 4.5 12.5 4.5ZM2 12C2 6.201 6.701 1.5 12.5 1.5C18.299 1.5 23 6.201 23 12C23 17.799 18.299 22.5 12.5 22.5C6.701 22.5 2 17.799 2 12Z"
        fill="#7B7BA4"
        fillOpacity="0.25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4998 4.50001C10.5661 4.49567 8.70635 5.24251 7.31276 6.58301C7.02404 6.84997 6.64203 6.99287 6.24899 6.98096C5.85594 6.96904 5.48329 6.80326 5.21126 6.51931C4.93924 6.23536 4.7896 5.85594 4.79455 5.46274C4.79951 5.06955 4.95867 4.69402 5.23776 4.41701C7.18968 2.54147 9.7928 1.49585 12.4998 1.50001C12.8976 1.50001 13.2791 1.65805 13.5604 1.93935C13.8417 2.22066 13.9998 2.60219 13.9998 3.00001C13.9998 3.39784 13.8417 3.77937 13.5604 4.06067C13.2791 4.34198 12.8976 4.50001 12.4998 4.50001Z"
        fill="#71678F"
      />
    </svg>
  );
};

export const LoadingCircle: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M3.15497 13.811C4.0973 13.811 4.86121 13.0471 4.86121 12.1047C4.86121 11.1624 4.0973 10.3985 3.15497 10.3985C2.21264 10.3985 1.44873 11.1624 1.44873 12.1047C1.44873 13.0471 2.21264 13.811 3.15497 13.811Z"
        fill="#AFAEE1"
      />
      <path
        d="M14.1329 12.2979C14.9507 12.2979 15.6137 11.6349 15.6137 10.817C15.6137 9.99914 14.9507 9.33612 14.1329 9.33612C13.315 9.33612 12.652 9.99914 12.652 10.817C12.652 11.6349 13.315 12.2979 14.1329 12.2979Z"
        fill="#D2D2FF"
      />
      <path
        d="M12.7807 4.82961C13.4563 4.82961 14.0041 4.28191 14.0041 3.60627C14.0041 2.93064 13.4563 2.38293 12.7807 2.38293C12.1051 2.38293 11.5574 2.93064 11.5574 3.60627C11.5574 4.28191 12.1051 4.82961 12.7807 4.82961Z"
        fill="#F7F7F7"
      />
      <path
        d="M1.81076 9.64654C2.81079 9.64654 3.62147 8.85562 3.62147 7.87997C3.62147 6.90432 2.81079 6.1134 1.81076 6.1134C0.810741 6.1134 6.10352e-05 6.90432 6.10352e-05 7.87997C6.10352e-05 8.85562 0.810741 9.64654 1.81076 9.64654Z"
        fill="#9C9AD9"
      />
      <path
        d="M7.01237 15.9998C7.92149 15.9998 8.65847 15.2816 8.65847 14.3958C8.65847 13.5099 7.92149 12.7917 7.01237 12.7917C6.10326 12.7917 5.36627 13.5099 5.36627 14.3958C5.36627 15.2816 6.10326 15.9998 7.01237 15.9998Z"
        fill="#BDBCE6"
      />
      <path
        d="M11.2429 15.2967C12.1065 15.2967 12.8066 14.615 12.8066 13.7741C12.8066 12.9332 12.1065 12.2515 11.2429 12.2515C10.3792 12.2515 9.67908 12.9332 9.67908 13.7741C9.67908 14.615 10.3792 15.2967 11.2429 15.2967Z"
        fill="#C5C5EA"
      />
      <path
        d="M3.76964 5.54348C4.81511 5.54348 5.66263 4.7162 5.66263 3.69569C5.66263 2.67518 4.81511 1.8479 3.76964 1.8479C2.72417 1.8479 1.87665 2.67518 1.87665 3.69569C1.87665 4.7162 2.72417 5.54348 3.76964 5.54348Z"
        fill="#8685C1"
      />
      <path
        d="M14.6007 8.34296C15.3735 8.34296 15.9999 7.72814 15.9999 6.96973C15.9999 6.21131 15.3735 5.5965 14.6007 5.5965C13.828 5.5965 13.2015 6.21131 13.2015 6.96973C13.2015 7.72814 13.828 8.34296 14.6007 8.34296Z"
        fill="#E2E2FF"
      />
      <path
        d="M8.46686 3.99207C9.56921 3.99207 10.4628 3.09844 10.4628 1.9961C10.4628 0.893751 9.56921 0.00012207 8.46686 0.00012207C7.36452 0.00012207 6.47089 0.893751 6.47089 1.9961C6.47089 3.09844 7.36452 3.99207 8.46686 3.99207Z"
        fill="#706FA3"
      />
    </svg>
  );
};
