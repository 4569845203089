export const PropellerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="16" height="16" rx="8" fill="#FFCC00" />
      <path
        d="M12.593 8.90292C11.4241 8.93005 10.3797 8.60593 9.36915 8.19C10.4302 7.75806 11.45 7.24045 12.2935 6.35378C12.3595 6.25825 12.3694 6.10111 12.3157 6.01056C12.2079 5.82938 12.15 5.73202 12.0421 5.55088C11.9886 5.46029 11.8869 5.46401 11.8209 5.55949C11.0156 6.40552 10.0513 6.91449 9.04316 7.33471C9.48683 6.28045 9.8405 5.19472 9.80969 3.97221C9.79059 3.86068 9.68707 3.73374 9.57987 3.71279C9.3685 3.65918 9.26869 3.63392 9.05735 3.58031C8.9575 3.55505 8.88605 3.62529 8.90205 3.74791C8.93163 4.91744 8.60751 5.96249 8.19036 6.97488C7.75781 5.9132 7.24084 4.89155 6.35352 4.04738C6.258 3.98145 6.10089 3.97159 6.0103 4.0252C5.82913 4.13303 5.7318 4.19095 5.55063 4.29878C5.46004 4.35239 5.46375 4.45406 5.55924 4.51999C6.40527 5.32535 6.91424 6.28967 7.33449 7.29774C6.28019 6.8541 5.19447 6.5004 3.97196 6.53124C3.86043 6.55034 3.7335 6.65386 3.71255 6.76106C3.65894 6.9724 3.63368 7.07224 3.58007 7.28358C3.5548 7.38339 3.62505 7.45488 3.74767 7.43885C4.91288 7.4093 5.95424 7.73093 6.96353 8.14625C5.90556 8.57757 4.88761 9.09515 4.04282 9.98125C3.97689 10.0767 3.96703 10.2339 4.02064 10.3245C4.12847 10.5056 4.1864 10.603 4.29423 10.7841C4.34784 10.8747 4.44951 10.871 4.51544 10.7755C5.32264 9.92702 6.2919 9.41682 7.30368 8.99595C6.85756 10.0546 6.49956 11.144 6.53037 12.3696C6.55379 12.4879 6.66038 12.6037 6.76019 12.629C6.97156 12.6826 7.07138 12.7079 7.28271 12.7615C7.38256 12.7867 7.45401 12.7165 7.43801 12.5939C7.4115 11.4293 7.73255 10.3885 8.14661 9.38108C8.57796 10.4378 9.09493 11.4552 9.98038 12.2987C10.0759 12.3646 10.233 12.3745 10.3236 12.3209C10.5048 12.2131 10.6021 12.1551 10.7833 12.0473C10.8739 11.9937 10.8702 11.892 10.7747 11.8261C9.92615 11.0189 9.41596 10.0496 8.99512 9.03786C10.0537 9.48399 11.1431 9.84198 12.3687 9.81117C12.4803 9.79207 12.6029 9.68116 12.6281 9.58135C12.6818 9.36998 12.707 9.27017 12.7606 9.05883C12.7859 8.95898 12.7156 8.88753 12.593 8.90353V8.90292Z"
        fill="#380A53"
      />
    </svg>
  );
};
