import { styled } from "@mui/material";

export const CaretIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { width, height, fill, className } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill={fill || "none"}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill || "white"}
        d="M6.66699 8.31095C6.66699 7.86301 7.03012 7.49988 7.47807 7.49988H12.5226C12.9705 7.49988 13.3337 7.86301 13.3337 8.31095C13.3337 8.50457 13.2644 8.69179 13.1384 8.83879L10.633 11.7617C10.3005 12.1497 9.70019 12.1497 9.36761 11.7617L6.86225 8.83879C6.73625 8.69179 6.66699 8.50457 6.66699 8.31095Z"
      />
    </svg>
  );
};

export const CaretRightIcon = styled(CaretIcon)`
  transform: rotate(-90deg);
`;
