export const TelegramIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.9209 4.25585L18.4091 19.3286C18.3764 19.5175 18.2699 19.6653 18.0947 19.7701C18.0036 19.8199 17.9005 19.8491 17.7906 19.8491C17.7184 19.8491 17.6394 19.8319 17.5552 19.7993L13.4868 18.1362C13.2634 18.0451 13.0074 18.1104 12.8545 18.296L10.7361 20.88C10.6176 21.0312 10.4561 21.105 10.2551 21.105C10.1709 21.105 10.0987 21.0913 10.0369 21.0655C9.91316 21.0191 9.81351 20.9453 9.73792 20.8353C9.66232 20.7271 9.62281 20.6085 9.62281 20.4762V17.4455C9.62281 17.1913 9.71043 16.9473 9.87021 16.7497L18.1015 6.65944L7.73979 15.6278C7.66076 15.6948 7.5508 15.7137 7.45631 15.6742L3.73667 14.1503C3.49442 14.0592 3.36213 13.8805 3.34495 13.6091C3.3312 13.3479 3.436 13.1538 3.65935 13.0284L19.988 3.60813C20.0876 3.548 20.1907 3.52051 20.3024 3.52051C20.4347 3.52051 20.5532 3.55831 20.658 3.63046C20.8762 3.78509 20.9604 3.99642 20.9209 4.25585Z"
        fill="white"
      />
    </svg>
  );
};
