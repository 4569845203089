export const PlusIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill={props.fill || "white"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 14.5098C6.71667 14.5098 6.479 14.4138 6.287 14.2218C6.095 14.0298 5.99934 13.7924 6 13.5098V8.50977H1C0.71667 8.50977 0.479004 8.41377 0.287004 8.22177C0.0950036 8.02977 -0.000663206 7.79244 3.4602e-06 7.50977C3.4602e-06 7.22644 0.0960036 6.98877 0.288004 6.79677C0.480004 6.60477 0.717337 6.5091 1 6.50977H6V1.50977C6 1.22644 6.096 0.988769 6.288 0.796769C6.48 0.604769 6.71734 0.509102 7 0.509769C7.28334 0.509769 7.521 0.605769 7.713 0.797769C7.905 0.989769 8.00067 1.2271 8 1.50977V6.50977H13C13.2833 6.50977 13.521 6.60577 13.713 6.79777C13.905 6.98977 14.0007 7.2271 14 7.50977C14 7.7931 13.904 8.03077 13.712 8.22277C13.52 8.41477 13.2827 8.51044 13 8.50977H8V13.5098C8 13.7931 7.904 14.0308 7.712 14.2228C7.52 14.4148 7.28267 14.5104 7 14.5098Z" />
    </svg>
  );
};
