export const MediumIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24.2539C18.6274 24.2539 24 18.8813 24 12.2539C24 5.62649 18.6274 0.253906 12 0.253906C5.37258 0.253906 0 5.62649 0 12.2539C0 18.8813 5.37258 24.2539 12 24.2539ZM8.21908 16.7547C10.6875 16.7547 12.6885 14.7399 12.6885 12.2546C12.6885 9.76935 10.6874 7.7547 8.21908 7.7547C5.75081 7.7547 3.74982 9.76875 3.74982 12.2546C3.74982 14.7405 5.75066 16.7547 8.21908 16.7547ZM17.5911 12.2553C17.5911 14.5947 16.5905 16.4913 15.3563 16.4913C14.1222 16.4913 13.1216 14.5947 13.1216 12.2553C13.1216 9.91578 14.1222 8.01926 15.3563 8.01926C16.5905 8.01926 17.5911 9.91578 17.5911 12.2553ZM19.5982 12.2555C19.5982 14.3516 19.2463 16.0508 18.8122 16.0508C18.3782 16.0508 18.0262 14.351 18.0262 12.2555C18.0262 10.1599 18.3782 8.4602 18.8124 8.4602C19.2466 8.4602 19.5982 10.1595 19.5982 12.2555Z"
        fill="white"
      />
    </svg>
  );
};
