export const HaloIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id={`${props.id}_halo_layer1`}
      x="0px"
      y="0px"
      viewBox="0 0 2500 2500"
      {...props}
    >
      <g id={`${props.id}_halo_g1`}>
        <g id={`${props.id}_halo_g2`}>
          <path
            d="M1014,1188c-34,34-34,90,0,124l175,175c34,34,90,34,124,0l175-175c34-34,34-90,0-124l-175-175    c-34-34-90-34-124,0L1014,1188z"
            fill="#08C284"
          ></path>
          <path
            d="M1910,325c35,54,65,112,90,172C1526-38,719-164,97,235C-1,297-30,429,33,527c62,98,194,127,292,64    c54-35,112-65,172-90C-38,975-164,1782,235,2404c62,98,194,127,292,64c98-62,127-194,64-292c-35-54-65-112-90-172    c474,535,1279,661,1899,266c60-37,100-104,100-180c0-117-95-210-211-210c-42,0-81,13-116,35c-54,34-110,64-170,87    c536-474,661-1281,263-1903c-62-98-194-127-292-64c-98,64-127,193-65,291l0,0L1910,325z M907,718c-294,188-379,580-191,875    s580,380,875,192s380-580,192-875l0,0C1595,614,1203,529,907,718L907,718L907,718z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#08C284"
          ></path>
        </g>
      </g>
    </svg>
  );
};
