export const BscIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_bsc_clip0)`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 0C31.0465 0 40 8.95353 40 20C40 31.0465 31.0465 40 20 40C8.95353 40 0 31.0465 0 20C0 8.95353 8.95353 0 20 0Z"
          fill="#F0B90B"
        />
        <path
          d="M10.992 19.9998L11.0064 25.2883L15.5 27.9325V31.0287L8.3766 26.8508V18.4534L10.992 19.9998ZM10.992 14.7114V17.7931L8.375 16.245V13.1633L10.992 11.6152L13.6218 13.1633L10.992 14.7114ZM17.3766 13.1633L19.9936 11.6152L22.6234 13.1633L19.9936 14.7114L17.3766 13.1633Z"
          fill="white"
        />
        <path
          d="M12.8828 24.1922V21.096L15.4998 22.6441V25.7258L12.8828 24.1922ZM17.3764 29.0415L19.9934 30.5896L22.6232 29.0415V32.1232L19.9934 33.6713L17.3764 32.1232V29.0415ZM26.3764 13.1633L28.9934 11.6152L31.6232 13.1633V16.245L28.9934 17.7931V14.7114L26.3764 13.1633ZM28.9934 25.2883L29.0078 19.9999L31.6248 18.4518V26.8492L24.5014 31.0271V27.9309L28.9934 25.2883Z"
          fill="white"
        />
        <path
          d="M27.117 24.1919L24.5 25.7255V22.6438L27.117 21.0957V24.1919Z"
          fill="white"
        />
        <path
          d="M27.1168 15.8073L27.1312 18.9034L22.6248 21.5477V26.849L20.0078 28.3826L17.3908 26.849V21.5477L12.8844 18.9034V15.8073L15.5126 14.2592L19.9918 16.9163L24.4982 14.2592L27.128 15.8073H27.1168ZM12.8828 10.5204L19.9934 6.32812L27.1168 10.5204L24.4998 12.0685L19.9934 9.41146L15.4998 12.0685L12.8828 10.5204Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={`${props.id}_bsc_clip0`}>
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
