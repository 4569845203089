export const ArrowRightIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <path
        d="M7.28025 1.71986L11.0303 5.46986C11.1 5.53945 11.1553 5.62211 11.193 5.71309C11.2307 5.80408 11.2502 5.90161 11.2502 6.00011C11.2502 6.09861 11.2307 6.19615 11.193 6.28713C11.1553 6.37812 11.1 6.46077 11.0303 6.53036L7.28025 10.2804C7.21062 10.35 7.12795 10.4052 7.03697 10.4429C6.94599 10.4806 6.84848 10.5 6.75 10.5C6.65153 10.5 6.55401 10.4806 6.46303 10.4429C6.37205 10.4052 6.28939 10.35 6.21975 10.2804C6.15012 10.2107 6.09488 10.1281 6.0572 10.0371C6.01951 9.9461 6.00011 9.84859 6.00011 9.75011C6.00011 9.65164 6.01951 9.55412 6.0572 9.46314C6.09488 9.37216 6.15012 9.2895 6.21975 9.21986L8.6895 6.75011L1.5 6.75011C1.30109 6.75011 1.11032 6.6711 0.969671 6.53044C0.829019 6.38979 0.750002 6.19903 0.750002 6.00011C0.750001 5.8012 0.829019 5.61044 0.969671 5.46978C1.11032 5.32913 1.30109 5.25011 1.5 5.25011L8.6895 5.25011L6.21975 2.78036C6.14986 2.71087 6.0944 2.62825 6.05655 2.53725C6.01871 2.44625 5.99922 2.34867 5.99922 2.25011C5.99922 2.15156 6.01871 2.05397 6.05655 1.96297C6.0944 1.87197 6.14986 1.78935 6.21975 1.71986C6.28934 1.65015 6.37199 1.59485 6.46298 1.55712C6.55397 1.51938 6.6515 1.49996 6.75 1.49996C6.8485 1.49996 6.94603 1.51938 7.03702 1.55712C7.12801 1.59485 7.21066 1.65015 7.28025 1.71986Z"
        fill="white"
      />
    </svg>
  );
};
