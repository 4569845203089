export const LinkIcon2: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke={props.stroke || "white"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 4.5L5 12M12 4H6M12.5 4V11"
        // stroke={props.stroke || "black"}
        strokeWidth="1.66667"
        strokeLinecap="square"
      />
    </svg>
  );
};
