export const DoubleArrowIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill={props.fill || "white"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7176 10.3181C18.0941 10.6947 18.0941 11.3053 17.7176 11.6819L15.1461 14.2533C14.7696 14.6298 14.159 14.6298 13.7824 14.2533C13.4059 13.8767 13.4059 13.2661 13.7824 12.8895L15.672 11L13.7824 9.11044C13.4059 8.73386 13.4059 8.12327 13.7824 7.74669C14.159 7.37016 14.7696 7.37016 15.1461 7.74669L17.7176 10.3181Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 11C18 11.5326 17.5683 11.9643 17.0357 11.9643L0.964287 11.9643C0.431745 11.9643 1.84192e-06 11.5326 1.8652e-06 11C1.88848e-06 10.4675 0.431745 10.0357 0.964288 10.0357L17.0357 10.0357C17.5683 10.0357 18 10.4675 18 11Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.21761 7.74669C4.59413 8.12327 4.59413 8.73386 4.21761 9.11044L2.32799 11L4.21761 12.8895C4.59413 13.2661 4.59413 13.8767 4.21761 14.2533C3.84102 14.6298 3.23044 14.6298 2.85385 14.2533L0.282424 11.6819C-0.0940971 11.3053 -0.094097 10.6947 0.282424 10.3181L2.85385 7.74669C3.23044 7.37016 3.84102 7.37016 4.21761 7.74669Z"
      />
    </svg>
  );
};
