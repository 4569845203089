export const OkxIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="25" height="25" rx="4" fill="black" />
      <path
        d="M14.7471 9.66687H9.93047C9.72585 9.66687 9.55994 9.83596 9.55994 10.0446V14.9557C9.55994 15.1644 9.72585 15.3335 9.93047 15.3335H14.7471C14.9517 15.3335 15.1176 15.1644 15.1176 14.9557V10.0446C15.1176 9.83596 14.9517 9.66687 14.7471 9.66687Z"
        fill="white"
      />
      <path
        d="M9.18715 4H4.37054C4.16592 4 4 4.16909 4 4.3778V9.28886C4 9.49749 4.16592 9.66666 4.37054 9.66666H9.18715C9.39185 9.66666 9.55769 9.49749 9.55769 9.28886V4.3778C9.55769 4.16909 9.39185 4 9.18715 4Z"
        fill="white"
      />
      <path
        d="M20.3026 4H15.4859C15.2813 4 15.1154 4.16909 15.1154 4.3778V9.28886C15.1154 9.49749 15.2813 9.66666 15.4859 9.66666H20.3026C20.5072 9.66666 20.673 9.49749 20.673 9.28886V4.3778C20.673 4.16909 20.5072 4 20.3026 4Z"
        fill="white"
      />
      <path
        d="M9.18715 15.3334H4.37054C4.16592 15.3334 4 15.5025 4 15.7111V20.6222C4 20.8309 4.16592 21 4.37054 21H9.18715C9.39185 21 9.55769 20.8309 9.55769 20.6222V15.7111C9.55769 15.5025 9.39185 15.3334 9.18715 15.3334Z"
        fill="white"
      />
      <path
        d="M20.3026 15.3334H15.4859C15.2813 15.3334 15.1154 15.5025 15.1154 15.7111V20.6222C15.1154 20.8309 15.2813 21 15.4859 21H20.3026C20.5072 21 20.673 20.8309 20.673 20.6222V15.7111C20.673 15.5025 20.5072 15.3334 20.3026 15.3334Z"
        fill="white"
      />
    </svg>
  );
};
