export const CoinbaseIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="96px"
      height="96px"
      viewBox="0 0 96 96"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id={`${props.id}_coinbase_linearGradient1`}
        >
          <stop stopColor="#2D66F5" offset="0%"></stop>
          <stop stopColor="#144ADF" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id={`${props.id}_coinbase`} fillRule="nonzero">
          <path
            d="M96,48 C96,21.4903 74.5097,0 48,0 C21.4903,0 0,21.4903 0,48 C0,74.5097 21.4903,96 48,96 C74.5097,96 96,74.5097 96,48 Z"
            id={`${props.id}_coinbase_svg1`}
            fill={`url(#${props.id}_coinbase_linearGradient1)`}
          ></path>
          <rect
            id={`${props.id}_coinbase_rectangle`}
            fill="#FFFFFF"
            x="18"
            y="18"
            width="60"
            height="60"
            rx="30"
          ></rect>
          <rect
            id={`${props.id}_coinbase_rectangle`}
            fill="#2D66F5"
            x="37"
            y="37"
            width="22"
            height="22"
            rx="2"
          ></rect>
        </g>
      </g>
    </svg>
  );
};
